import axios from 'axios'
var baseURL = process.env.VUE_APP_APP_HOST
var appid = process.env.VUE_APP_APPID
axios.defaults.baseURL=baseURL
//axios.defaults.baseURL='http://apigw.deveka.com/'
//axios.defaults.baseURL='http://localhost:8080/'
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'
//axios.defaults.headers.post['Content-Type'] = 'application/json'
axios.defaults.async=true
axios.defaults.crossDomain=true
axios.defaults.withCredentials = true
axios.defaults.timeout = 60000

import czParms from './utilSign.js'

axios.interceptors.request.use(config => {
    var userToken = sessionStorage.getItem('userToken');
    //console.log("token",userToken)
    if(userToken){
        config.headers.common={
            'DRUG-USER-SESSION-KEY':userToken
        }
    }
    config.params={
        appId:appid,
        timestamp:Math.round((new Date().getTime())/1000),
        ...config.params
    }

    var sign=''
    var header = config.headers["Content-Type"]
    if(header=='application/json'){
        sign= czParms(Object.assign({"jsonBody":config.data},config.params));
    }else{
        //console.log("config.data",config.data,"config.params",config.params)
        var obj={}
        var array1 = []   //key数组
        var array2 = []   //val数组
        if(config.data && typeof config.data == 'string'){
            //console.log("config.data",config.data)
            var paramStr = config.data
            paramStr = paramStr.split('&')
            paramStr.forEach(item => {
                array1 = array1.concat(item.split("=")[0])
                array2 = array2.concat(item.split("=")[1])
            })
            array1.forEach((item,index) => {
                obj[item]=array2[index]
            })
            sign= czParms(Object.assign(obj,config.params));
        }else{
            console.log(config.url)
            var obj2 = Object.assign({},config.data)
            sign= czParms(Object.assign(obj2?obj2:{},config.params));
        }

    }


    if(config.method=='post'){

    }else if(config.method=='get'){

    }
    config.params={
        sign:sign,
        ...config.params
    }
    console.log(config)
return config;
},function (error) {
    return Promise.reject(error)
})

export default axios
