<template>
    <div class="container">
        <el-tabs v-model="activeName" style="width: 100%">
            <el-tab-pane label="药品销售数据" name="drugSalesData">
                <drugSalesData></drugSalesData>
            </el-tab-pane>
            <el-tab-pane label="每日订单数统计" name="dailyOrderStatistics">
                <dailyOrderStatistics></dailyOrderStatistics>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
    import dailyOrderStatistics from './dailyOrderStatistics.vue'
    import drugSalesData from './drugSalesData.vue'
    export default {
        name: "dataStatisticsIndex",
        data() {
            return {
                activeName:"drugSalesData"
            };
        },
        components:{
            dailyOrderStatistics,
            drugSalesData
        }
    }
</script>

<style scoped lang="scss">
    .container{
        width: 100%;
        height: 100%;
        padding: 0.3rem;
        overflow-y: auto;
        background: #FBFBFB;
    }
    :v-deep  .el-tabs__nav{
        width: 100%!important;
        .el-tabs__item{
            //width: 50%!important;
            text-align: center;
            font-size: 0.16rem;
        }
        .el-tabs__active-bar{
           // width: 50%!important;
           // height: 0.04rem;
        }
    }
    :v-deep  .el-tabs__nav-wrap::after{
       // height: 0.04rem!important;
    }
</style>