<template>
    <div class="container1">
        <div class="search-top">
            <el-button :class="{ btnActive: switchClass == 1 }" @click="getSearchData(1)" size="medium">近一周</el-button>
            <el-button :class="{ btnActive: switchClass == 2 }" @click="getSearchData(2)" size="medium">近一个月</el-button>
            <el-date-picker size="medium" @change="changeDate" v-model="searchData.startTime" type="date" value-format="yyyy-MM-dd" placeholder="请选择开始日期" :picker-options="startTime">
            </el-date-picker>
            -
            <el-date-picker size="medium" @change="changeDate" v-model="searchData.endTime" type="date" value-format="yyyy-MM-dd" placeholder="请选择结束日期" :picker-options="endTime">
            </el-date-picker>
            <!-- 属性分类：<el-select v-model="searchData.drugType" size="medium">
                <el-option v-for="item of drugTypeList" :value="item.value" :label="item.label" :key="item.value"></el-option>
            </el-select> -->
            <el-button type="primary" @click="getSearchData" size="medium" style="margin-left: 0.2rem;margin-right: 0.1rem">确定</el-button>
            <el-link :underline="false" @click.prevent="clearSearchData">清空筛选条件</el-link>
            <!-- <el-button @click="onExportData" size="medium" type="success" style="margin-left: 0.2rem;">导出</el-button> -->
        </div>
        <div style="margin-top: 0.2rem">
            <el-table v-loading="loading" element-loading-text="拼命加载中" :cell-style="cellStyle" :data="tableData" :header-cell-style="headerCellStyle" border style="width: 100%" v-watermark>
                <el-table-column type="index" label="序号" align="center" width="50" :show-overflow-tooltip="showOverflow">
                </el-table-column>
                <el-table-column prop="drugNo" label="商品编码" align="center" :show-overflow-tooltip="showOverflow">
                </el-table-column>
                <el-table-column prop="genericName" align="center" :show-overflow-tooltip="showOverflow" label="通用名">
                </el-table-column>
                <el-table-column prop="tradeName" align="center" :show-overflow-tooltip="showOverflow" label="商品名">
                </el-table-column>
                <el-table-column prop="drugSpecifications" align="center" :show-overflow-tooltip="showOverflow" label="规格">
                </el-table-column>
                <el-table-column prop="drugType" align="center" :show-overflow-tooltip="showOverflow" label="属性分类">
                    <template slot-scope="scope">
                        {{ scope.row.drugType | formateDrugType }}
                    </template>
                </el-table-column>
                <el-table-column prop="manufacturer" align="center" :show-overflow-tooltip="showOverflow" label="厂商">
                </el-table-column>
                <el-table-column prop="prescriptionTotal" align="center" :show-overflow-tooltip="showOverflow" label="总处方数">
                </el-table-column>
                <el-table-column prop="drugUseTotal" align="center" :show-overflow-tooltip="showOverflow" label="使用次数">
                </el-table-column>
                <el-table-column prop="drugUseRate" align="center" :show-overflow-tooltip="showOverflow" label="处方使用比">
                    <template #default="scope">{{ scope.row.drugUseRate }}%</template>
                </el-table-column>
                <el-table-column prop="saleNum" align="center" :show-overflow-tooltip="showOverflow" label="销售数量">
                </el-table-column>
            </el-table>
            <div class="pagination" v-if="total > 0">
                <el-pagination @prev-click="prevClick" @next-click="nextClick" @current-change="currentChange" @size-change="sizeChange" :current-page="searchData.pageNum" :page-sizes="[10, 20, 30, 40]" :page-size="searchData.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>

import moment from 'moment'
import { downloadExport } from '@/common/downLoadFile.js';
export default {
    name: "inquiryOrder",
    data() {
        return {
            inquiryOrderDetails: '',
            showDialog: false,
            switchClass: 0,
            loading: true,
            total: 0,
            showOverflow: true,
            tableData: [],
            isClickYes: true,
            searchParam: {},
            basicParam: {
                pageNum: 1,
                pageSize: 10,
            },
            searchData: {
                startTime: '',
                endTime: '',
                drugType: '',
                keyword: ''
            },
            //药品销售数据下的属性分类
            drugTypeList: [],

            startTime: {
                disabledDate: time => {
                    if (this.searchData.endTime) {
                        return (
                            time.getTime() > new Date(this.searchData.endTime).getTime()
                        )
                    } else {
                        return time.getTime() > Date.now()
                    }
                }
            },
            endTime: {
                disabledDate: time => {
                    if (this.searchData.startTime) {
                        return (
                            time.getTime() > Date.now() ||
                            time.getTime() < new Date(this.searchData.startTime).getTime() - 86400000
                        )
                    } else {
                        return time.getTime() > Date.now()
                    }
                }
            }
        }
    },
    components: {

    },
    methods: {
        onExportData() {
            let send = JSON.parse(JSON.stringify(this.searchData));
            let time = [];
            if (send.startTime) {
                time.push(moment(send.startTime).format("YYYYMMDD"))
            }
            if (send.endTime) {
                time.push(moment(send.endTime).format("YYYYMMDD"))
            }
            downloadExport({
                url: '/order-service/drugmg/order/exportSalesData',
                data: send,
                name: '药品销售数据' + time.join('-'),
                fullName: true
            });
        },
        getDrugSalesDataList() {
            this.loading = true;
            let _this = this;
            this.searchParam = {}
            this.searchParam = Object.assign({}, this.basicParam)
            if (_this.isClickYes) {
                this.searchParam = Object.assign(this.searchParam, this.searchData)
            }
            this.$axios.get(this.$APIURL.DRUGINFOS_ITEMSALECOUNT, { params: this.searchParam }).then((response) => {
                if (response.data.code == 0) {
                    if (response.data.data != null) {
                        _this.tableData = response.data.data.list
                        this.total = response.data.data.total
                    } else {
                        this.total = 0
                        _this.tableData = []
                    }
                }
            }).catch((error) => {
                _this.$respone.error(_this.$respone.catchMsg)
            }).finally(() => {
                this.loading = false;
            })
        },

        prevClick(currentPage) {
            this.basicParam.pageNum = currentPage;
            this.getDrugSalesDataList()
        },
        nextClick(currentPage) {
            this.basicParam.pageNum = currentPage;
            this.getDrugSalesDataList()
        },
        currentChange(currentPage) {
            this.basicParam.pageNum = currentPage;
            this.getDrugSalesDataList()
        },
        sizeChange(size) {
            this.basicParam.pageSize = size;
            this.getDrugSalesDataList()
        },
        cellStyle({ row, column, rowIndex, columnIdex }) {
            let style = { height: '0.4rem' }
            return style;
        },
        headerCellStyle({ row, column, rowIndex, columnIdex }) {
            let style = { background: '#f1f4fa', height: '0.6rem' }
            return style;
        },
        getSearchData(num) {
            this.isClickYes = true
            if (num == 1) {
                this.searchData.startTime = moment().subtract(6, 'day').format('YYYY-MM-DD') + ' 00:00:00'
                this.searchData.endTime = moment().format('YYYY-MM-DD') + " 23:59:59"
            } else if (num == 2) {
                this.searchData.startTime = moment().subtract(1, 'months').format('YYYY-MM-DD') + ' 00:00:00'
                this.searchData.endTime = moment().format('YYYY-MM-DD') + " 23:59:59"
            }
            this.switchClass = num
            this.basicParam.pageNum = 1
            this.getDrugSalesDataList();
        },
        changeDate() {
            this.switchClass = 0
        },
        clearSearchData() {
            this.isClickYes = false
            this.basicParam.pageNum = 1
            this.switchClass = 0
            this.searchData.startTime = this.searchData.endTime = this.searchData.drugType = ''
            this.getDrugSalesDataList()
        },
        closeDialog(data) {
            this.showDialog = data;
        },

    },
    created() {
        this.searchData.startTime = moment().subtract(1, 'months').format('YYYY-MM-DD') + ' 00:00:00'
        this.searchData.endTime = moment().format('YYYY-MM-DD') + " 23:59:59"
        this.switchClass = 2
        this.getDrugSalesDataList()
        this.drugTypeList = this.$common.drugType
    }

}
</script>

<style scoped lang="scss">
.container1 {
    padding: 0;
}
:v-deep .search-top {
    font-size: 0.14rem;
    margin-top: 0.2rem;
    .el-input {
        width: 2rem;
    }
}
.container {
    font-size: 0.14rem;
}
.pagination {
    margin-top: 0.2rem;
    text-align: center;
}
.btnActive {
    color: white;
    background-color: rgb(64, 158, 255);
}
</style>
