<template>
    <el-table
            :data="invoiceInfo"
            border
            :header-cell-style="headerCellStyle"
            style="width: 100%">
        <el-table-column
                prop="type"
                label="发票类型"
                align="center"
                :show-overflow-tooltip="showOverflow"
        >
            <template slot-scope="scope">
                {{scope.row.type | formateInvoiceType}}
            </template>
        </el-table-column>
        <el-table-column
                prop="type"
                label="抬头类型"
                align="center"
                :show-overflow-tooltip="showOverflow"
        >
            <template slot-scope="scope">
                <span>{{scope.row.taxNumber!=null && scope.row.taxNumber!=''?"公司":"个人"}}</span>
            </template>
        </el-table-column>
        <el-table-column
                prop="title"
                align="center"
                :show-overflow-tooltip="showOverflow"
                label="抬头	">

        </el-table-column>
        <el-table-column
                prop="taxNumber"
                align="center"
                :show-overflow-tooltip="showOverflow"
                label="纳税人识别号">

        </el-table-column>
        <el-table-column
                prop="email"
                align="center"
                :show-overflow-tooltip="showOverflow"
                label="邮箱	">
        </el-table-column>
        <el-table-column
                prop="phone"
                align="center"
                :show-overflow-tooltip="showOverflow"
                label="电话	">
        </el-table-column>

    </el-table>
</template>

<script>
    export default {
        name: "invoiceTable",
        data(){
            return {
                showOverflow:true
            }
        },
        props:['invoiceInfo'],
        props: {
            invoiceInfo:{
                default:3
            }
        },
        methods:{
            headerCellStyle({row,column,rowIndex,columnIdex}){
                let style = {background: '#f1f4fa',height:'0.45rem'}
                return style;
            },
        }
    }
</script>

<style scoped>

</style>