<template>
  <div class="container">
    <div class="prescrioption-parent">
      <div class="search-top">
        <el-form :inline="true">
          <el-form-item label="开方时间：">
            <el-date-picker size="medium" v-model="searchData.startTime" type="date" value-format="yyyy-MM-dd"
              placeholder="请选择开始日期" :picker-options="startTime" style="width: 1.6rem">
            </el-date-picker>
            -
            <el-date-picker size="medium" v-model="searchData.endTime" type="date" value-format="yyyy-MM-dd"
              placeholder="请选择结束日期" :picker-options="endTime" style="width: 1.6rem">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="审方日期：" v-if="searchData.status == 6">
            <el-date-picker size="medium" v-model="searchData.auditStartTime" type="date" value-format="yyyy-MM-dd"
              placeholder="请选择开始日期" :picker-options="auditStartTime" style="width: 1.6rem">
            </el-date-picker>
            -
            <el-date-picker size="medium" v-model="searchData.auditEndTime" type="date" value-format="yyyy-MM-dd"
              placeholder="请选择结束日期" :picker-options="auditEndTime" style="width: 1.6rem">
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="getSearchData" size="medium" class="margin-left-right-10px">确定</el-button>
            <el-button type="primary" @click="clearSearchData" size="medium"
              class="margin-left-right-10px">清空筛选条件</el-button>
            <el-button type="success" @click="batchAudit" size="medium" :disabled="searchData.status == 6"
              class="margin-left-right-10px">批量审核处方</el-button>
            <el-form-item>
              <div style="margin-left: 10px;">
                <prescription-print :print-list="selectData" />
              </div>
            </el-form-item>
            <el-button type="warning" @click="onExport" size="medium">导出</el-button>
          </el-form-item>
        </el-form>
        <el-form :inline="true">
          <el-form-item label="状态：">
            <el-select v-model="searchData.status" size="medium" style="width: 1.1rem;" @change="getSearchData">
              <el-option v-for="item of statusList" :key="item.value" :value="item.value"
                :label="item.label"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="用药周期：">
            <el-select v-model="searchData.medicationPeriod" size="medium" style="width: 1.1rem;"
              @change="getSearchData">
              <el-option v-for="item of cycle.options" :key="item.key" :value="item.key"
                :label="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-input @keyup.enter.native="getSearchData" clearable
              :placeholder="keywordTypeList[0].options[searchData.keywordType - 1].placeholder"
              v-model="searchData.keyword" style="width: 3rem;">
              <el-select style="width: 110px;" slot="prepend" v-model="searchData.keywordType">
                <el-option-group :key="group.label" :label="group.label" v-for="group in keywordTypeList">
                  <el-option :key="item.value" :label="item.label" :value="item.value" v-for="item in group.options">
                  </el-option>
                </el-option-group>
              </el-select>
            </el-input>
          </el-form-item>
          <el-form-item v-if="searchData.status != 6">
            <el-checkbox v-model="searchData.overTimeFlag" :true-label="1" :false-label="0" border
              @change="getSearchData">超时未审核</el-checkbox>
          </el-form-item>
        </el-form>
      </div>
      <div>
        <el-table v-loading="loading" element-loading-text="拼命加载中" :cell-style="cellStyle" :data="tableData"
          :header-cell-style="headerCellStyle" border style="width: 100%; font-size: 0.14rem; border-radius: 8px"
          @selection-change="handleSelectionChange" ref="multipleTable" @sort-change="onSortChange"
          :default-sort="defaultSort" v-watermark>
          <el-table-column type="selection" fixed="left" label="全选" align="center" width="45">
          </el-table-column>
          <!-- <el-table-column
            prop="askNo"
            label="问诊编号"
            align="center"
            :show-overflow-tooltip="showOverflow"
            fixed="left"
            width="140"
          >
          </el-table-column>
          <el-table-column
            prop="prescriptionNoList"
            align="center"
            :show-overflow-tooltip="showOverflow"
            label="处方编号"
            width="140"
          >
          <template slot-scope="scope">
              {{ scope.row.prescriptionNoList.join(',') }}
            </template>
</el-table-column>
<el-table-column prop="userNo" align="center" :show-overflow-tooltip="showOverflow" label="用户编号" width="100">
</el-table-column>
<el-table-column prop="userName" align="center" width="100" :show-overflow-tooltip="showOverflow" label="就诊人">
</el-table-column>
<el-table-column :formatter="formatterSex" prop="sex" align="center" :show-overflow-tooltip="showOverflow" label="性别"
  width="55">
</el-table-column>
<el-table-column prop="age" align="center" :show-overflow-tooltip="showOverflow" label="年龄" width="55">
</el-table-column>
<el-table-column prop="diagnose" align="center" :show-overflow-tooltip="showOverflow" label="临床诊断">
</el-table-column> -->
          <!-- <el-table-column
            prop="totalFee"
            align="center"
            :show-overflow-tooltip="showOverflow"
            label="处方金额"
          > 
          </el-table-column> -->
          <!-- <el-table-column
            prop="createTime"
            align="center"
            :show-overflow-tooltip="showOverflow"
            label="开方时间"
            width="180"
          >
            <template slot-scope="scope">
              {{ scope.row.createTime | dateFormate }}
            </template>
          </el-table-column> -->
          <el-table-column label="就诊人信息" align="center" :width="360">
            <template #default="scope">
              <div class="info">
                <div class="info-box">
                  <div class="head">
                    <div class="left">
                      <div class="itm name">{{ scope.row.userName }}</div>
                      <div class="itm sex">{{ scope.row.sex | sexFormate }}</div>
                      <div class="itm age">{{ scope.row.age }}岁</div>
                      <!-- <div class="itm time">{{moment(scope.row.createTime).format('YYYY-MM-DD')}}</div> -->
                    </div>
                    <div class="more" @click="onPatientShow(scope.row)">查看更多</div>
                  </div>
                  <div class="body">
                    <div class="itm">
                      <div class="label">接诊医生：</div>
                      <div class="value">{{ scope.row.doctorName }}</div>
                    </div>
                    <div class="itm">
                      <div class="label">服务医助：</div>
                      <div class="value">{{ scope.row.doctorAssistantName || '无' }}</div>
                    </div>
                    <div class="itm diagnosis">
                      <span class="label">临床诊断：</span>
                      <span class="value">{{ scope.row.diagnose }}</span>
                    </div>
                    <div class="itm advice">
                      <div class="label">医嘱：</div>
                      <div class="value">
                        <el-tooltip class="item" effect="dark" :content="scope.row.advice" placement="top-start"
                          :disabled="!scope.row.advice">
                          <div class="overflow">{{ scope.row.advice || '无' }}</div>
                        </el-tooltip>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="处方" align="center" min-width="500px">
            <template #default="scope">
              <div class="prescription">
                <div class="prescription-box" >
                  <!-- 1西药 2中药 -->
                  <div v-if="scope.row.type==2" >
                    <div style="display: flex;flex-wrap: wrap">
                      <div class="itm" v-for="(item, index) in scope.row.doctorPrescriptionDetails" :key="index"
                      style="margin-right: 20px;">
                        <span>{{ item.drugName }}
                          <span v-if="item.decoction">({{ decoctionName(item.decoction) }})</span>
                          {{ askDrugPackCount(item).name }}</span>
                      </div>
                    </div>                   
                    <div style="text-align: left;">{{ scope.row.prescriptionUsage }}</div>
                    <div style="text-align: left;">共{{ scope.row.totalDosage||'-' }}剂</div>
                  </div>
                  <div v-else>
                    <div class="itm" v-for="(item, index) in scope.row.doctorPrescriptionDetails" :key="index">
                      <div class="itm-box">
                        <div class="itm-cell">{{ item.drugName }}</div>
                        <div class="itm-cell">{{ item.drugSpecifications }}</div>
                        <div class="itm-cell">{{ askDrugPackCount(item).name }}</div>
                        <div class="itm-cell"
                          :class="{ red: drug.list[item.drugNo] && drug.list[item.drugNo].dosage != item.dosage }">
                          {{ item.dosage }}{{ item.dosageUnitName }}</div>
                        <div class="itm-cell"
                          :class="{ red: drug.list[item.drugNo] && drug.list[item.drugNo].frequency != item.frequency }">
                          {{ item.frequencyName }}</div>
                        <div class="itm-cell"
                          :class="{ red: drug.list[item.drugNo] && drug.list[item.drugNo].drugUsage != item.drugUsage }">
                          {{ item.drugUsageName }}</div>
                      </div>
                    </div>
                </div>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="createTime" align="center" :show-overflow-tooltip="showOverflow" label="开方时间"
            width="160" sortable="custom">
            <template slot-scope="scope">
              {{ moment(scope.row.createTime).format('YYYY-MM-DD HH:mm') }}
            </template>
          </el-table-column>
          <el-table-column align="center" :show-overflow-tooltip="showOverflow" label="审核日期" width="100"
            v-if="searchData.status == 6">
            <template slot-scope="scope">
              {{ scope.row.pharmaceutistAuditTime ? moment(scope.row.pharmaceutistAuditTime).format('YYYY-MM-DD') : ''
              }}
            </template>
          </el-table-column>
          <el-table-column align="center" label="用药周期" prop="medicationPeriod" :width="110" sortable="custom">
            <!-- <template #header>
              <el-dropdown @command="onCycleChange" trigger="click">
                  <div style="display: flex; align-items: center; white-space: nowrap; line-height: 1.3; font-size: 12px; cursor: pointer;">
                    <span>用药周期</span>
                    <span class="el-icon-arrow-down"></span>
                  </div>
                  <template #dropdown>
                    <el-dropdown-menu>
                      <el-dropdown-item v-for="(itm, idx) in cycle.options" :key="idx" :command="itm.key" :style="cycle.active == itm.key ? 'color: #589cfd;' : ''">{{itm.value}}</el-dropdown-item>
                    </el-dropdown-menu>
                  </template>
                </el-dropdown>
            </template> -->
            <template #default="scope">{{ onCycleFilter(scope.row) }}</template>
          </el-table-column>
          <el-table-column align="center" label="备注" width="100">
            <template #default="scope">
              <el-button type="text" @click="onRemarksShow(scope.row)">查看</el-button>
            </template>
          </el-table-column>
          <el-table-column prop="status" align="center" :show-overflow-tooltip="showOverflow" label="处方状态" width="100"
            fixed="right">
            <template slot-scope="scope">
              <span v-show="scope.row.status == 1" class="to-be-accepted">待下单</span>
              <span v-show="scope.row.status == 2" class="err">待审核</span>
              <span v-show="scope.row.status == 3" class="to-be-accepted">已失效</span>
              <span v-show="scope.row.status == 4" class="failed">已作废</span>
              <span v-show="scope.row.status == 5" class="failed">审核不通过</span>
              <span v-show="scope.row.status == 6" class="success">审核通过</span>
              <span v-show="scope.row.status == 7" class="to-be-accepted">待支付</span>
              <p style="color: #f6524e; margin-top: 5px"
                v-if="scope.row.status == 2 && scope.row.otAuditTime && new Date(scope.row.otAuditTime).getTime() < new Date().getTime()">
                (已超时)</p>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" width="100" fixed="right">
            <template slot-scope="scope">
              <!--v-if="hasPerm('prescription:look')"-->
              <!--<el-button-->
              <!--size="mini"-->
              <!--type="primary"-->
              <!--@click="lookPrescription(scope.$index, scope.row)">查看处方</el-button>-->
              <!--v-if="hasPerm('prescription:look')"-->
              <el-button size="mini" :type="scope.row.status == 6 ? 'primary' : 'success'"
                @click="lookPrescription(scope.$index, scope.row)"
                :disabled="scope.row.status != 2 && scope.row.status != 6" style="margin: 3px;">{{ scope.row.status ==
          6 ? '查看' : '审核' }}</el-button>
              <!-- <el-button size="mini" type="danger" v-if="scope.row.status == 6" style="margin: 3px;" @click="onExamineCancel(scope.row)">撤回审核</el-button> -->
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination">
          <el-pagination @prev-click="prevClick" @next-click="nextClick" @current-change="currentChange"
            @size-change="sizeChange" :current-page="basicParam.pageNum" :page-sizes="[10, 20, 30, 40, 100]"
            :page-size="basicParam.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total">
          </el-pagination>
        </div>
      </div>
      <!-- <prescriptionDialog
        v-if="showDialog"
        v-on:closeDialog="closeDialog"
        :prescriptionData="prescriptionDetails"
        :prescriptionNo="prescriptionNo"
        :pdfUrl="pdfUrl"
        @examineClick="examineClick"
      ></prescriptionDialog> -->
      <batchPrescriptionAuditDialog v-if="showBatchAuditDialog" v-on:closeDialog="closeBatchAuditDialog"
        :presList="auditPresList" @examineClick="examineClick"></batchPrescriptionAuditDialog>
    </div>
    <!-- 底部审核按钮 -->
    <div class="examine_footer" v-if="viewShow">
      <div class="page prev" :class="{ disabled: prescriptionNoIndex == 0 }" @click="onLookPrescriptionNext(true)">上一个
      </div>
      <div class="center">
        <el-button type="primary" round @click="examineClick" :disabled="status != 2">{{ status == 6 ? '审核已通过' :
          '处方批量审核通过' }}</el-button>
        <span>（{{ viewIndex + 1 }}/{{ imgList.length }}）</span>
      </div>
      <div class="page next" :class="{ disabled: prescriptionNoIndex == tableData.length - 1 }"
        @click="onLookPrescriptionNext(false)">下一个</div>
    </div>
    <!-- 签名 -->
    <autographDialog @handleSign="handleSign" @closeDialog="closeDialog" v-if="autographDialogFlag"></autographDialog>
    <el-dialog title="是否使用当前签名？" :visible.sync="dialogVisibleSign" :close-on-click-modal="false">
      <!-- @closed="closeDialogSign" -->
      <img :src="doctorSign" class="doctorSign">
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handleConfirm">是</el-button>
        <el-button type="danger" @click="handleCancel">否</el-button>
      </span>
    </el-dialog>

    <el-dialog title="查看备注" :visible.sync="remarks.show" width="500px" custom-class="custom-dialog">
      <div class="remarks-dialog" v-watermark>
        <remarks-index ref="remarks" />
      </div>
    </el-dialog>

    <el-dialog title="就诊人信息" :visible.sync="patient.show" width="533px" custom-class="custom-dialog no-header">
      <div class="patient-dialog" v-watermark>
        <div class="title">就诊人信息</div>
        <div class="form">
          <div class="head">
            <div class="itm name">{{ patient.detail.userName }}</div>
            <div class="itm sex">{{ patient.detail.sex | sexFormate }}</div>
            <div class="itm age">{{ patient.detail.age }}岁</div>
          </div>
          <div class="item">
            <div class="label">接诊医生：</div>
            <div class="value">{{ patient.detail.doctorName || '无' }}</div>
          </div>
          <div class="item">
            <div class="label">服务医助：</div>
            <div class="value">{{ patient.detail.doctorAssistantName || '无' }}</div>
          </div>
          <div class="item">
            <div class="label">开方时间：</div>
            <div class="value">{{ patient.detail.createTime }}</div>
          </div>
          <div class="item">
            <div class="label">问诊编号：</div>
            <div class="value">{{ patient.detail.askNo }}</div>
          </div>
          <div class="item">
            <div class="label">处方编号：</div>
            <div class="value">{{ patient.detail.prescriptionNos }}</div>
          </div>
          <div class="item">
            <div class="label">用户编号：</div>
            <div class="value">{{ patient.detail.userNo }}</div>
          </div>
          <div class="item">
            <div class="label">临床诊断：</div>
            <div class="value">{{ patient.detail.diagnose }}</div>
          </div>
          <div class="item">
            <div class="label">医嘱：</div>
            <div class="value">{{ patient.detail.advice || '无' }}</div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { askDrugPackCount } from '@/utils/utils'
import prescriptionDialog from "./dialog/prescriptionDialog.vue";
import batchPrescriptionAuditDialog from "./dialog/batchPrescriptionAuditDialog.vue";
import autographDialog from './dialog/autographDialog.vue'
import moment from "moment";
import remarksIndex from '../remarks/index.vue'
import { downloadExport } from '@/common/downLoadFile.js';
import prescriptionPrint from './dialog/print.vue'
import { watermarkImageCreate } from '@/directives/watermark'
export default {
  name: "myPrescriptionIndex",
  data() {
    return {
      pdfUrl: "",
      statusList: [
        // { value: "", label: "全部" },
        //{ value: 1, label: "待下单" },
        { value: 2, label: "待审核" },
        //{ value: 3, label: "已失效" },
        //{ value: 4, label: "已作废" },
        //{ value: 5, label: "审核不通过" },
        { value: 6, label: "审核通过" },
        //{ value: 7, label: "待支付" },
      ],
      keywordTypeList: [{
        options: [{
          value: '1',
          label: '就诊人',
          placeholder: '请输入就诊人'
        }, {
          value: '2',
          label: '问诊编号',
          placeholder: '请输入问诊编号'
        }]
      }],
      prescriptionNo: "",
      prescriptionDetails: "",
      showDialog: false,
      loading: true,
      total: 0,
      showOverflow: true,
      tableData: [],
      isClickYes: false,
      searchParam: {},
      basicParam: {
        pageNum: 1,
        pageSize: 10,
        examinePrescriptionNo: "",
        status: 2
      },
      searchData: {
        startTime: "",
        endTime: "",
        status: 2,
        keyword: "",
        keywordType: "1",
        medicationPeriod: '',
        auditStartTime: '',
        auditEndTime: '',
        sort: '',
        overTimeFlag: 0
      },

      startTime: {
        disabledDate: time => {
          if (this.searchData.endTime) {
            return (
              time.getTime() > new Date(this.searchData.endTime).getTime()
            )
          } else {
            return time.getTime() > Date.now()
          }
        }
      },
      endTime: {
        disabledDate: time => {
          if (this.searchData.startTime) {
            return (
              time.getTime() > Date.now() ||
              time.getTime() < new Date(this.searchData.startTime).getTime() - 86400000
            )
          } else {
            return time.getTime() > Date.now()
          }
        }
      },
      auditStartTime: {
        disabledDate: time => {
          if (this.searchData.auditEndTime) {
            return (
              time.getTime() > new Date(this.searchData.auditEndTime).getTime()
            )
          } else {
            return time.getTime() > Date.now()
          }
        }
      },
      auditEndTime: {
        disabledDate: time => {
          if (this.searchData.auditStartTime) {
            return (
              time.getTime() > Date.now() ||
              time.getTime() < new Date(this.searchData.auditStartTime).getTime() - 86400000
            )
          } else {
            return time.getTime() > Date.now()
          }
        }
      },
      selectData: [],
      auditPresList: [],
      showBatchAuditDialog: false,

      // 处方单
      viewIndex: 0,
      viewNum: 0,
      viewShow: false,
      status: '',
      imgList: [],
      // 签名
      autographDialogFlag: false,
      dialogVisibleSign: false,
      doctorSign: '',
      prescriptionNoList: [],
      prescriptionNoIndex: -1,
      viewer: null,
      remarks: {
        show: false
      },
      cycle: { // 用药周期
        options: []
      },
      patient: { // 就诊人信息
        show: false,
        detail: {}
      },
      drug: {
        list: []
      },
      useSign: true, // 是否使用签名
      defaultSort: {},
      prescription: { // 处方打印
        show: false,
        list: []
      }
    }
  },
  components: {
    prescriptionDialog,
    batchPrescriptionAuditDialog,
    autographDialog,
    remarksIndex,
    prescriptionPrint
  },
  watch: {
    '$store.state.messageWebSocket.ysPrescriptionRefresh'(newVal, oldVal) {
      if (newVal) {
        this.getMyPrescriptionList();
        let messageWebSocket = this.$store.state.messageWebSocket
        messageWebSocket.ysPrescriptionRefresh = oldVal
        this.$store.commit('setMessageWebSocket', messageWebSocket)
      }
    }
  },
  methods: {
    moment,
    askDrugPackCount,
    onExport() {
      this.$confirm('是否导出电子处方?', '', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        closeOnClickModal: false
      }).then(() => {
        downloadExport({
          url: '/doctor-service/doctormg/prescription/exportDoctorMgList',
          data: this.onDataParamGet(),
          name: '电子处方',
          fullName: true
        });
      }).catch(() => {

      });
    },
    onSortChange(row) {
      if (row.order) {
        if (row.prop == 'createTime') {
          if (row.order == 'ascending') {
            this.searchData.sort = 'prescription_time_asc'
          } else {
            this.searchData.sort = 'prescription_time_desc'
          }
        } else if (row.prop == 'medicationPeriod') {
          if (row.order == 'ascending') {
            this.searchData.sort = 'medication_period_asc'
          } else {
            this.searchData.sort = 'medication_period_desc'
          }
        }
      } else {
        this.searchData.sort = '';
      }
      this.getMyPrescriptionList();
    },
    onExamineCancel(row) { // 撤销审核
      this.$confirm('是否确认撤回审核？', '', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning',
        closeOnClickModal: false
      }).then(() => {
        let send = row.prescriptionNos.split(',');
        this.$axios.put('/doctor-service/doctormg/prescription/recallAuditPrescription', send).then(({ data }) => {
          if (data.code == 0) {
            this.$message.success('撤销成功');
            this.getMyPrescriptionList();
          } else {
            this.$message.error(data.message);
          }
        })
      }).catch(() => {

      });
    },
    onCycleGet() { //用药周期获取
      return new Promise((resolve) => {
        this.$axios.get('/misc-service/typeInfo/detail', {
          params: {
            typeCode: 'TM_PERIOD_TYPE'
          }
        }).then(({ data }) => {
          if (data.code == 0) {
            this.cycle.options = [{ value: '全部', key: '' }].concat(data.data.list || []);
            resolve(true);
          }
        })
      })
    },
    onCycleChange(command) { // 用药周期切换
      this.searchData.medicationPeriod = command;
      this.basicParam.pageNum = 1;
      this.getMyPrescriptionList();
    },
    onCycleFilter(row) {
      if (row.medicationPeriod == 5) {
        return row.mpValue ? `${row.mpValue}天` : '';
      } else {
        let list = this.cycle.options;
        if (list.length) {
          for (let i = 0; i < list.length; i++) {
            if (list[i].key == row.medicationPeriod) {
              return `${list[i].remark}天`;
            }
          }
        }
      }
      return ''
    },
    onDrugAllGet() { // 获取所有药品
      return new Promise((resolve) => {
        this.$axios.get('/drug-service/drugInfos/allDrugs', {
          params: {}
        }).then(({ data }) => {
          if (data.code == 0) {
            let res = {};
            (data.data || []).forEach((item) => {
              res[item.drugNo] = item;
            })
            this.drug.list = res;
            resolve(true);
          }
        })
      })
    },
    onDataParamGet() {
      let send = JSON.parse(JSON.stringify(this.searchData))
      if (send.startTime) send.startTime += ' 00:00:00';
      else delete send.startTime;
      if (send.endTime) send.endTime += ' 23:59:59';
      else delete send.endTime;
      if (send.auditStartTime && send.status == 6) send.auditStartTime += ' 00:00:00';
      else delete send.auditStartTime;
      if (send.auditEndTime && send.status == 6) send.auditEndTime += ' 23:59:59';
      else delete send.auditEndTime;
      if (send.overTimeFlag != 1 || this.searchData.status == 6) delete send.overTimeFlag;
      return send;
    },
    getMyPrescriptionList() {
      this.loading = true;
      let _this = this;
      this.searchParam = {};
      this.searchParam = Object.assign({}, this.basicParam);
      let send = this.onDataParamGet();
      // if (_this.isClickYes) {
      //   this.searchParam = Object.assign(this.searchParam, send);
      // }
      this.searchParam = Object.assign(this.searchParam, send);
      this.$axios
        .get(this.$APIURL.MY_PRESCRIPTION_LIST, { params: this.searchParam })
        .then((response) => {
          this.loading = false;
          if (response.data.code == 0) {
            if (response.data.data != null) {
              _this.tableData = response.data.data.list;
              this.total = response.data.data.total;
            } else {
              this.total = 0;
              _this.tableData = [];
            }
          }
        })
        .catch((error) => {
          _this.loading = false;
          _this.$respone.error("发生异常！");
        });
    },
    formatterDate(row, column) {
      return moment(row.postTime).format("YYYY-MM-DD HH:mm");
    },
    formatterSex(row, column) {
      return row.sex == 1 ? "男" : row.sex == 0 ? "女" : "-";
    },
    formatterUserType(row, column) {
      return row.userType == 1
        ? "一键看病"
        : row.userType == 2
          ? "报到患者"
          : row.userType == 3
            ? "签约患者"
            : "-";
    },
    formatterAskStatus(row, column) {
      return row.askStatus == 0
        ? "待分配"
        : row.askStatus == 1
          ? "服务中"
          : row.askStatus == 2
            ? "已完成"
            : "-";
    },
    prevClick(currentPage) {
      this.basicParam.pageNum = currentPage;
      this.getMyPrescriptionList();
    },
    nextClick(currentPage) {
      this.basicParam.pageNum = currentPage;
      this.getMyPrescriptionList();
    },
    currentChange(currentPage) {
      this.basicParam.pageNum = currentPage;
      this.getMyPrescriptionList();
    },
    sizeChange(size) {
      this.basicParam.pageSize = size;
      this.getMyPrescriptionList();
    },
    cellStyle({ row, column, rowIndex, columnIdex }) {
      let style = { height: "0.4rem" };
      return style;
    },
    headerCellStyle({ row, column, rowIndex, columnIdex }) {
      let style = { background: "#FBFBFB", height: "0.5rem" };
      return style;
    },
    getSearchData() {
      this.isClickYes = true;
      this.basicParam.pageNum = 1;
      this.getMyPrescriptionList();
    },
    clearSearchData() {
      this.isClickYes = false;
      this.searchData.startTime =
        this.searchData.endTime =
        this.searchData.keyword = "";
      this.searchData.keywordType = "1";
      this.basicParam.pageNum = 1;
      this.searchData.status = 2
      this.getMyPrescriptionList();
    },
    closeDialog(data) {
      this.showDialog = data;
    },
    closeBatchAuditDialog(data) {
      this.showBatchAuditDialog = data;
    },
    // examineClick(data) {
    //   this.getMyPrescriptionList();
    //   this.showDialog = data;
    //   this.showBatchAuditDialog = data;
    // },
    onLookPrescriptionNext(isPrev) {
      if ((isPrev && this.prescriptionNoIndex == 0) || (!isPrev && this.prescriptionNoIndex == this.tableData.length - 1)) return false;
      let index = isPrev ? this.prescriptionNoIndex - 1 : this.prescriptionNoIndex + 1;
      this.viewShow = false;
      this.viewer && this.viewer.destroy();
      this.lookPrescription(index, this.tableData[index])
    },
    onUrlHandle(url) {
      if (url.indexOf('/') === 0) {
        return url
      } else {
        return `/${url}`
      }
    },
    lookPrescription(index, row) {
      // this.prescriptionNo = row.prescriptionNo;
      // this.$refs.multipleTable.clearSelection();
      this.status = row.status
      this.prescriptionNoIndex = index;
      if (row.status == 2) {
        this.prescriptionNoList = row.prescriptionNos.split(',')
      }
      var _this = this;
      let prescriptionNoList = row.prescriptionNos
      this.$axios.get(this.$APIURL.PRESCRIPTION_DETAILBYNO_PLUS, {
        params: {
          prescriptionNoList: prescriptionNoList
        }
      }).then(async (res) => {
        /*this.$axios.get(this.$APIURL.PRESCRIPTION_DETAILBYNO,{params:{prescriptionNo:row.prescriptionNo}}).then(res => {*/
        if (res.data.code == 0) {
          // let pdfUrl = res.data.data.pdfUrl;
          // this.pdfUrl = pdfUrl.slice(13,pdfUrl.length);
          // this.pdfUrl = pdfUrl
          // res.data.data.title = "查看处方";
          // _this.prescriptionDetails = res.data.data;
          // _this.showDialog = true;
          let imgList = []
          if (res.data.data.length > 0) {
            for (let i = 0; i < res.data.data.length; i++) {
              const item = res.data.data[i];
              const { url } = await watermarkImageCreate(this.$APIURL.PIC_SHOW + this.onUrlHandle(item.url));
              imgList.push(url)
            }
            // res.data.data.forEach(item => {
            //   imgList.push(this.$APIURL.PIC_SHOW + item.url)
            // })
            this.imgList = imgList
          } else {
            return false
          }
          this.viewer = this.$viewerApi({
            images: imgList,
            options: {
              zIndex: 2000,
              // backdrop: 'static',
              show: function (e) {
                console.log(e.type, '图片显示开始');
                _this.viewShow = true
              },
              hide: function (e) {
                console.log(e.type, '图片隐藏完成');
                _this.viewShow = false
                _this.getMyPrescriptionList();
                _this.$refs.multipleTable.clearSelection();
              },
              view: function (e) {
                console.log(e.detail.index, '图片索引');
                _this.viewIndex = e.detail.index
              }
            }
          })
        } else {
          _this.$respone.error("查看失败！");
        }
      }).catch((err) => {
        _this.$respone.error("发生异常，请稍后重试！");
      });
    },
    handleSelectionChange(value) {
      this.selectData = value;
      this.auditPresList = [];
      for (var i = 0; i < value.length; i++) {
        if (value[i].status == 2) {
          this.auditPresList.push(value[i]);
        }
      }
    },
    batchAudit() {
      if (this.auditPresList.length == 0) {
        this.$message({
          dangerouslyUseHTMLString: true,
          message: '请先勾选 <span class="err">待审核</span> 的处方，再点击批量审核',
          type: 'warning'
        });
        return;
      }
      this.showBatchAuditDialog = true;
    },
    handleSign(img) {
      this.autographDialogFlag = false
      this.doctorSign = img
      this.curmClick()
    },
    closeDialog(flag) {
      this.autographDialogFlag = flag
    },
    examineClick() {
      this.doctorSign = ''
      this.$axios.get(this.$APIURL.DOCTOR_FINDBYDNO, { params: { pharmacistNo: this.$store.state.userInfo.refUserNo } }).then(res => {
        if (res.data.code == 0) {
          // this.viewer && this.viewer.hide()
          this.doctorSign = res.data.data.autographUrl
          if (this.useSign) {
            if (this.doctorSign) {
              this.dialogVisibleSign = true
            } else {
              this.autographDialogFlag = true
            }
          } else {
            this.handleConfirm();
          }
        } else {
          this.$message.error(res.data.message || '查询药师签名失败')
        }
      })
    },
    curmClick() {
      this.autographDialogFlag = false
      this.showBatchAuditDialog = false
      // this.viewShow = false
      // this.viewer && this.viewer.hide()
      const loading = this.$loading({
        lock: true,
        text: '拼命审核中',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      if (this.auditPresList.length > 0) {
        this.prescriptionNoList = []
        this.auditPresList.forEach(item => {
          this.prescriptionNoList = this.prescriptionNoList.concat(item.prescriptionNos.split(','))
        })
      }
      let datar = {
        pharmacistAutographUrl: this.doctorSign.replace(/^https?:\/\/.*?\/+/, '/'),
        prescriptionNoList: this.prescriptionNoList,
        prescriptionStatus: 6
      };
      this.$axios({
        method: "POST",
        url: this.$APIURL.DRUGINFOS_AUDITPRESCRIPTION,
        data: datar
      })
        .then((res) => {
          loading.close()
          if (res.data.code == 0) {
            // this.getMyPrescriptionList()
            // this.$refs.multipleTable.clearSelection();
            this.$respone.success("操作成功，审核通过");
            // this.viewer && this.viewer.hide()
            this.status = 6;
            this.$set(this.tableData[this.prescriptionNoIndex], 'status', 6);
          } else {
            this.$respone.error(res.data.message);
          }
        })
        .catch((err) => {
          loading.close()
          _this.$respone.error("发生异常");
        });
    },
    handleConfirm() {
      let imgUrl = process.env.VUE_APP_PIC_SHOW
      this.doctorSign = this.doctorSign.replace(imgUrl, '')
      this.dialogVisibleSign = false
      this.curmClick()
    },
    handleCancel() {
      this.dialogVisibleSign = false
      this.autographDialogFlag = true
    },
    onRemarksShow(row) {
      this.remarks.show = true;
      setTimeout(() => {
        this.$refs.remarks.init(row);
      })
    },
    onPatientShow(row) {
      this.patient.detail = JSON.parse(JSON.stringify(row));
      this.patient.show = true;
    },
    getDecoctionsTypeList(){
        this.$axios.get('/misc-service/typeInfo/detail', {
          params: {
            typeCode: 'DECOCTION_TYPE'
          }
        }).then(({ data }) => {
          console.log(data)
          if (data.code == 0) {
            this.decoctionList=data.data.list
          }
        })
    },
    decoctionName(id){
      for(let i in this.decoctionList){
        if(id==this.decoctionList[i].key){
          return this.decoctionList[i].value
        }
      }
    }
  },
 
  mounted() {
    if (this.$route.query.type) { // 药师首页跳转处理
      if (this.$route.query.type == 'audit') this.searchData.status = 6;
      else this.searchData.status = 2;
      if (this.$route.query.type == 'timeout') this.searchData.overTimeFlag = 1;
      this.searchData.startTime = this.$route.query.startTime || '';
      this.searchData.endTime = this.$route.query.endTime || '';
      this.defaultSort = {
        prop: 'createTime',
        order: 'descending'
      }
      this.searchData.sort = 'prescription_time_desc';
    }
    this.onCycleGet().then(() => {
      return this.onDrugAllGet();
    }).then(() => {
      let userInfo = JSON.parse(localStorage.getItem("userInfo"));
      // this.basicParam.pharmaceutist = this.$store.state.doctorLoginInfo.userNo
      // this.basicParam.examinePrescriptionNo = userInfo.username;
      this.getDecoctionsTypeList()
      this.basicParam.examinePrescriptionNo = userInfo.refUserNo;
      this.getMyPrescriptionList()
    });
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/css/base';

// .container ::v-deep .el-dialog__wrapper {
//   z-index: 100001!important;
// }

.prescrioption-parent {
  width: 100%;
  height: 100%;
  padding: 0.32rem;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  overflow: auto;
}

.container {
  width: 100%;
  height: 100%;
  display: block;
  padding: 0.2rem;
  overflow: hidden;
}

.pagination {
  margin-top: 0.2rem;
  text-align: center;
}

.btnActive {
  color: white;
  background-color: rgb(64, 158, 255);
}

.examine_footer {
  position: fixed;
  height: 52px;
  background: #fff;
  bottom: 0;
  right: 0;
  width: 100%;
  z-index: 100000;
  font-size: 24px;
  @include flex(center, space-between);

  .page {
    flex: none;
    font-size: 16px;
    padding: 0 20px;
    cursor: pointer;
    height: 100%;
    @include flex(center, center);

    &:hover {
      background: #f4f4f4;
    }

    &.disabled {
      cursor: not-allowed;
      background: #f4f4f4;
      color: #ccc;
    }
  }

  .center {
    flex: 1;
    @include flex(center, center);
  }
}

.doctorSign {
  width: 100%;
  height: auto;
  margin: 0 auto;
}

.info {
  &-box {
    border: 1px solid #e6e6e6;
    border-radius: 4px;
    text-align: left;
    padding: 10px;

    .head {
      @include flex(center, space-between);

      .left {
        @include flex(center);

        .itm {
          margin-left: 10px;

          &:first-child {
            margin-left: 0;
          }
        }
      }

      .more {
        font-size: 12px;
        color: #6ca9fd;
        cursor: pointer;
      }
    }

    .body {
      .itm {
        margin-top: 5px;
        @include flex();

        .label {
          flex: none;
        }

        .value {
          width: 100%;
          flex: 1;
          overflow: hidden;

          .overflow {
            @include text-overflow();
          }
        }
      }
    }
  }
}

.prescription {
  &-box {
    margin-top: -8px;
    font-size: 13px;

    // padding-left: 20px;
    .itm {
      margin-top: 8px;

      &-box {
        @include flex();
        padding-left: 5%;

        .itm-cell {
          flex: none;
          margin-left: 2%;
          text-align: left;
          word-break: break-all;

          &.red {
            color: #f54b47;
          }

          &:first-child {
            width: 20%;
          }

          &:nth-child(2) {
            width: 12%;
          }

          &:nth-child(3) {
            width: 8%;
          }

          &:nth-child(4) {
            width: 6%;
          }

          &:nth-child(5) {
            width: 15%;
          }

          &:nth-child(6) {
            flex: 1;
          }
        }
      }
    }
  }
}

.patient-dialog {
  padding: 0 20px;

  .title {
    @include flex(center, center);
    font-size: 18px;
    font-weight: bold;
    color: #333;

    &::before {
      content: "";
      width: 4px;
      height: 14px;
      background: #589cfd;
      margin-right: 10px;
      border-radius: 10px;
    }
  }

  .form {
    .head {
      @include flex(center);

      .itm {
        margin-right: 10px;
      }
    }

    .item {
      @include flex();
      margin-top: 15px;

      .label {
        flex: none;
        min-width: 5em;
        text-align: right;
      }
    }
  }
}
</style>