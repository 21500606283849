<template>
  <div>
    <el-upload
      :class="['upload', fileList.length >= limit ? 'show-uploadCart' : '']"
      action="#"
      list-type="picture-card"
      :fileList="fileList"
      :multiple="limit !== 1"
      :limit="limit"
      :http-request="handleHttpUpload"
      :before-upload="beforeUpload"
      :on-exceed="handleExceed"
      :on-success="uploadSuccess"
      :on-error="uploadError"
      :on-remove="handleRemove"
      :accept="uploadFileType.join(',')"
    >
      <i class="el-icon-plus"></i>
    </el-upload>
  </div>
</template>

<script>
const imageType = ["png", "jpg", "jpeg", "bmp", "gif"];
const videoType = ["mp4", "m2v", "mkv", "mov", "3gp"];

export default {
  name: "UploadImgs",
  props: {
    // 上传的文件
    fileStr: {
      type: String,
      default: "",
    },
    // 拖拽
    drag: {
      type: Boolean,
      default: true,
    },
    // 上传
    limit: {
      type: Number,
      default: 20,
    },
    // 上传文件格式限制
    fileType: {
      type: Array,
      default: () => ["image/jpeg", "image/jpg", "image/png", "image/gif"],
    },
    // 文件大小 M
    fileSize: {
      type: Number,
      default: 100,
    },
    // 是否可以上传视频
    isUploadVideo: {
      type: Boolean,
      default: false,
    },
    // 上传 height
    height: {
      type: String,
      default: "90px",
    },
    // 上传 width
    width: {
      type: String,
      default: "90px",
    },
    // 上传 borderRadius
    borderRadius: {
      type: String,
      default: "8px",
    },
  },
  data() {
    return {
      fileList: [],
    };
  },
  methods: {
    async handleHttpUpload(options) {
      options.file.status = "";
      let formData = new FormData();
      formData.append("file", options.file);
      formData.append("module", "doctor");
      formData.append("category", "offline-store");
      formData.append("async", "false"); // 正式环境 接口返回慢了
      try {
        const {
          data: { data },
        } = await this.$axios({
          method: "post",
          url: '/file-service/file/uploadOther',
          data: formData,
        });
        options.onSuccess(data);
      } catch (error) {
        options.onError(error);
      }
    },
    beforeUpload(rawFile) {
      const imgSize = rawFile.size / 1024 / 1024 < this.fileSize;
      if (!this.uploadFileType.includes(rawFile.type))
        this.$notify({
          title: "温馨提示",
          message: "上传图片不符合所需的格式！",
          type: "warning",
        });
      if (!imgSize)
        this.$notify({
          title: "温馨提示",
          message: `上传图片大小不能超过 ${this.fileSize}M！`,
          type: "warning",
        });
      return this.uploadFileType.includes(rawFile.type) && imgSize;
    },
    handleExceed() {
      this.$notify({
        title: "温馨提示",
        message: `当前最多只能上传 ${this.limit} 张图片，请移除后上传！`,
        type: "warning",
      });
    },
    uploadSuccess(response, uploadFile, fileList) {
      if (!response) return;
      uploadFile.url = response.absoluteUrl;
      uploadFile.status = "success";
      this.onUpdateFileList(fileList);
    },
    uploadError() {
      this.$notify({
        title: "温馨提示",
        message: "图片上传失败，请您重新上传！",
        type: "error",
      });
    },
    validFileType(url = "") {
      const urlName = url.split(".").pop()?.toLocaleLowerCase() || "";
      if (imageType.includes(urlName)) return "image";
      if (videoType.includes(urlName)) return "video";
      else return "";
    },
    // 更新父组件值
    onUpdateFileList(fileList) {
      this.$emit("update:fileStr", fileList.map((item) => item.url).join(","));
    },
    handleRemove(file) {
      this.$emit(
        "update:fileStr",
        this.fileStr
          .split(",")
          .filter((item) => item !== file.url)
          .join(",")
      );
    },
  },
  computed: {
    bindStyle() {
      const px2vw = (px) => `${(px / 462) * 100}vw`;
      return {
        "--width": px2vw(this.width),
        "--height": px2vw(this.height),
        "--borderRadius": px2vw(this.borderRadius),
        "--margin": px2vw(this.margin),
      };
    },
    uploadFileType() {
      return this.isUploadVideo
        ? this.fileType.concat([
            "video/mp4",
            "video/m2v",
            "video/mkv",
            "video/mov",
            "video/3gp",
            "video/quicktime",
          ])
        : this.fileType;
    },
    imgType() {
      const videoType = [
        "video/mp4",
        "video/m2v",
        "video/mkv",
        "video/mov",
        "video/3gp",
        "video/quicktime",
      ];
      return this.isUploadVideo
        ? this.fileType.concat(videoType)
        : this.fileType;
    },
  },
  watch: {
    fileStr: {
      handler(value) {
        const list = (value ? value.split(",") : []).map((item) => ({
          url: item,
          status: "success",
          message: "上传成功",
        }));
        this.fileList = list;
      },
      immediate: true,
    },
  },
  created() {},
  activated() {},
};
</script>
<style scoped lang="scss">
.show-uploadCart {
  ::v-deep .el-upload--picture-card {
    display: none;
  }
}
</style>
