import czParms from './utilSign.js'
var appId = process.env.VUE_APP_APPID
var baseURL = process.env.VUE_APP_APP_HOST
import axios from './axiosConfig.js'
import moment from 'moment'

/**
 * 通过服务器下载文件，解决跨域的问题
 * @param {} params 
 * @param {*} url 
 */
export function downLoadFileUrl(params, url) {
    // var url = 'http://1.1.1.102:8044/admin/userservice/userThreeConditionQuestion/exportQuestions'
    let userToken = sessionStorage.getItem('userToken');
    console.log(userToken)
    let timestamp = Math.round((new Date().getTime()) / 1000)
    let data = {
        'appId': appId,
        'timestamp': timestamp,
        'DRUG-USER-SESSION-KEY': encodeURIComponent(userToken),
        ...params
    }
    let sign = czParms(Object.assign({ "jsonBody": '' }, data));
    url += `?sign=${sign}`
    Object.keys(data).forEach(key => {
        url += `&${key}=${data[key]}`
    })
    url = baseURL + url
    console.log(url)
    const elink = document.createElement("a");
    elink.setAttribute('download', '')
    elink.style.display = "none";
    elink.href = url
    document.body.appendChild(elink);
    elink.click();
    document.body.removeChild(elink);
}

/**
 * 通过平台打包批量下载
 * @param {} params 
 * @param {*} url 
 */
export function batchDownloadFileUrl(params, url) {
    let userToken = getToken()
    let timestamp = Math.round((new Date().getTime()) / 1000)
    let pathParams = {
        'appId': appId,
        'timestamp': timestamp,
        'DRUG-USER-SESSION-KEY': encodeURIComponent(userToken),
    }
    let signData = {
        ...pathParams,
        ...params
    }
    let sign = czParms(Object.assign({ "jsonBody": '' }, signData));
    url += `?sign=${sign}`
    Object.keys(pathParams).forEach(key => {
        url += `&${key}=${pathParams[key]}`
    })
    url = baseURL + url
    var xhr = new XMLHttpRequest();
    xhr.open('post', url, true);
    xhr.responseType = "blob";
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.onload = function () {
        if (this.status === 200) {
            var blob = this.response;
            var reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onload = function (e) {
                const elink = document.createElement("a");
                elink.setAttribute('download', params.fileName + '.zip')
                elink.style.display = "none";
                elink.href = e.target.result
                document.body.appendChild(elink);
                elink.click();
                document.body.removeChild(elink);
            }
        }
    };
    xhr.send(JSON.stringify(params));
}

// 导出下载
export function downloadExport({ url, name, method = 'post', fullName = false, ext = 'xlsx',...args }) {
    console.log(args)
    axios({
        url: url,
        method: method,
        ...args,
        responseType: "blob",
        config: {
            custom: true
        }
    }).then((res) => {
        if (res.status == 200) {
            const content = res.data;
            const blob = new Blob([content]);
            const fileName = (fullName ? name : name + moment().format("MMDD")) + `.${ext}`;
            if ("download" in document.createElement("a")) {
                // 非IE下载
                const elink = document.createElement("a");
                elink.download = fileName;
                elink.style.display = "none";
                elink.href = URL.createObjectURL(blob);
                document.body.appendChild(elink);
                elink.click();
                URL.revokeObjectURL(elink.href); // 释放URL 对象
                document.body.removeChild(elink);
            } else {
                // IE10+下载
                navigator.msSaveBlob(blob, fileName);
            }
        }

    }).catch((err) => {
        console.log(err)
        //   this.$respone.error(this.$respone.catchMsg);
    });
}