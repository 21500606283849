<template>
    <div class="container" :class="{ gray: userInfo.userType != 2 }">
        <template v-if="userInfo.userType == 2">
            <el-row class="row">
                <el-col :lg="5" :md="5" :sm="12">
                    <div class="top-count" v-watermark>
                        <div>
                            <div>
                                <countTo :startVal='0' :endVal='topCount.todayOrderCount' :duration='3000'></countTo>
                            </div>
                            <div>今日订单总数</div>
                        </div>
                    </div>
                </el-col>
                <el-col :lg="5" :md="5" :sm="12">
                    <div class="top-count" v-watermark>
                        <div>
                            <div>
                                <countTo :startVal='0' :endVal='topCount.waitSendCount' :duration='3000'></countTo>
                            </div>
                            <div>待发货订单数</div>
                        </div>
                    </div>
                </el-col>
                <el-col :lg="5" :md="5" :sm="12">
                    <div class="top-count" v-watermark>
                        <div>
                            <div>
                                <countTo :startVal='0' :endVal='topCount.waitSignCount' :duration='3000'></countTo>
                            </div>
                            <div>待签收订单数</div>
                        </div>
                    </div>
                </el-col>
                <el-col :lg="5" :md="5" :sm="12">
                    <div class="top-count" v-watermark>
                        <div>
                            <div>
                                <countTo :startVal='0' :endVal='topCount.finshCount' :duration='3000'></countTo>
                            </div>
                            <div>已完成订单总数</div>
                        </div>
                    </div>
                </el-col>
            </el-row>
            <el-row class="row" style="margin-top: 0.3rem;padding: 0 0.1rem">
                <el-col :span="24" style="" class="canvas-content" v-watermark>
                    <div class="canvas-title">
                        <div>订单统计</div>
                        <div>
                            <el-link :underline="false" @click="weekAgo">最近一周</el-link>
                            <el-date-picker
                                size="medium"
                                v-model="orderSearchData.date"
                                @change="change"
                                type="daterange"
                                range-separator="-"
                                value-format="yyyy-MM-dd"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期">
                            </el-date-picker>
                        </div>
                    </div>
                    <div id="myChart" :style="{ width: '100%', height: '350px' }"></div>
                </el-col>
            </el-row>
            <el-row class="row" style="padding: 0 0.1rem">
                <el-col style="background: white;border-radius: 8px;box-shadow:0px 0px 16px 0px rgba(0,0,0,0.04);margin-top: 0.3rem;" :lg="11" :sm="24">
                    <div class="canvas-title">
                        <div>商品数据统计</div>
                    </div>
                    <div v-watermark>
                        <div id="commodityDataStatisticsChart" :style="{ width: '100%', height: '350px' }"></div>
                    </div>
                </el-col>
                <el-col style="background: white;border-radius: 8px;box-shadow:0px 0px 16px 0px rgba(0,0,0,0.04);margin-top: 0.3rem;" :lg="12" :sm="24">
                    <div style="display: flex;justify-content: space-between;align-items: center" class="canvas-title">
                        <div>前十名商品销量统计</div>
                        <div>
                            <el-link :underline="false" @click="salesWeekAgo">最近一周</el-link>
                            <el-date-picker
                                size="medium"
                                v-model="salesSearchData.date"
                                @change="salesChange"
                                type="daterange"
                                range-separator="-"
                                value-format="yyyy-MM-dd"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期">
                            </el-date-picker>
                        </div>
                    </div>
                    <div v-watermark>
                        <div id="commoditySalesStatisticsChart" :style="{ width: '100%', height: '350px' }"></div>
                    </div>
                </el-col>
            </el-row>
        </template>
        <template v-else>
            <div class="pharmacist">
                <div class="pharmacist-head">
                    <span>开方时间：</span>
                    <el-date-picker
                        size="medium"
                        v-model="pharmacist.search.startTime"
                        type="date"
                        value-format="yyyy-MM-dd"
                        placeholder="请选择开始日期"
                        :picker-options="pharmacist.options.startTime"
                        :disabled="pharmacist.loading"
                        :clearable="false"
                        style="width: 160px" @change="onPharmacistDataGet">
                    </el-date-picker>
                    <span style="margin: 0 10px;">-</span>
                    <el-date-picker
                        size="medium"
                        v-model="pharmacist.search.endTime"
                        type="date"
                        value-format="yyyy-MM-dd"
                        placeholder="请选择结束日期"
                        :picker-options="pharmacist.options.endTime"
                        :disabled="pharmacist.loading"
                        :clearable="false"
                        style="width: 160px" @change="onPharmacistDataGet">
                    </el-date-picker>
                    <el-button size="medium" style="margin-left: 15px;" @click="onPharmacistDateTimeReset" :disabled="pharmacist.search.startTime == pharmacist.yesterday && pharmacist.search.endTime == pharmacist.yesterday">重置</el-button>
                </div>
                <div class="pharmacist-body">
                    <div class="pharmacist-card" :class="`pharmacist-card-${item}`" v-for="(item, index) in ['audit', 'timeout']" :key="index">
                        <div class="pharmacist-content" v-watermark>
                            <div class="icon"></div>
                            <div class="time">
                                <span>{{ moment(pharmacist.search.startTime).format('YYYY.MM.DD') }}</span>
                                <span v-if="pharmacist.search.startTime && pharmacist.search.endTime" style="margin: 0 3px;">-</span>
                                <span>{{ moment(pharmacist.search.endTime).format('YYYY.MM.DD') }}</span>
                            </div>
                            <template v-if="item == 'timeout'">
                                <div class="num">
                                    <div class="cell now" @click="onPharmacistToOrder('timeout')">{{ topCountForPharmacist.overTimeCount }}</div>
                                </div>
                                <div class="desc">
                                    <div class="cell">超时未审核</div>
                                </div>
                            </template>
                            <template v-else>
                                <div class="num">
                                    <div class="cell now" @click="onPharmacistToOrder('audit')">{{ topCountForPharmacist.auditCount }}</div>
                                    <div class="cell line">/</div>
                                    <div class="cell" @click="onPharmacistToOrder('unAudit')">{{ topCountForPharmacist.unAuditCount }}</div>
                                </div>
                                <div class="desc">
                                    <div class="cell">已审处方</div>
                                    <div class="cell line">/</div>
                                    <div class="cell">待审处方</div>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import moment from 'moment'
import countTo from 'vue-count-to'
export default {
    name: "home",
    data() {
        return {
            topCount: {
                todayOrderCount: 0,
                waitSendCount: 0,
                waitSignCount: 0,
                finshCount: 0,
            },
            topCountForPharmacist: { // 药师的统计数据
                auditCount: 0,
                overTimeCount: 0,
                unAuditCount: 0
            },
            orgOptions: {},
            chart: "",
            commodityDataStatisticsChart: '',
            commoditySalesStatisticsChart: "",
            orderSearchData: {
                date: []
            },
            salesSearchData: {
                date: []
            },
            orderStatistics: {
                xAxis: {
                    data: ['周一', '周二', '周三', '周四', '周五', '周六', '周日'],
                },
                series: {
                    data: [120, 132, 101, 134, 90, 230, 210]
                }
            },
            commodityDataStatistics: {
                xAxis: {
                    data: ['总药品数', '已上架数', '已下架数', '库存不足数', '缺货数'],
                },
                series: {
                    data: [10, 52, 200, 334, 390],
                }
            },
            commoditySalesStatistics: {
                xAxis: {
                    data: ['药品1', '药品2', '药品3', '药品6', '药品5'],
                },
                series: {
                    data: [10, 52, 200, 334, 390],
                }
            },
            pharmacist: {
                loading: false,
                yesterday: '',
                search: {
                    startTime: '',
                    endTime: ''
                },
                options: {
                    startTime: {
                        disabledDate: time => {
                            if (this.pharmacist.search.endTime) {
                                return (
                                    time.getTime() > new Date(this.pharmacist.search.endTime).getTime()
                                )
                            } else {
                                return time.getTime() > Date.now()
                            }
                        }
                    },
                    endTime: {
                        disabledDate: time => {
                            if (this.pharmacist.search.startTime) {
                                return (
                                    time.getTime() > Date.now() ||
                                    time.getTime() < new Date(this.pharmacist.search.startTime).getTime() - 86400000
                                )
                            } else {
                                return time.getTime() > Date.now()
                            }
                        }
                    }
                }
            }
        }
    },
    computed: {
        userInfo() {
            return this.$store.state.userInfo;
        }
    },
    created() {
        if (this.userInfo.userType == 2) {
            this.getTopCount()
            this.orderSearchData.date = []
            this.orderSearchData.date.push(moment().subtract('days', 6).format('YYYY-MM-DD'))
            this.orderSearchData.date.push(moment().format('YYYY-MM-DD'))
            this.salesSearchData.date.push(moment().subtract('days', 6).format('YYYY-MM-DD'))
            this.salesSearchData.date.push(moment().format('YYYY-MM-DD'))
            this.getOrderStatistics(this.orderSearchData.date[0], this.orderSearchData.date[1])
            this.getCommoditySalesStatistics(this.salesSearchData.date[0], this.salesSearchData.date[1])
            this.getCommodityDataStatistics()
        } else {
            this.pharmacist.yesterday = this.onGetYesterday();
            this.pharmacist.search.startTime = this.pharmacist.yesterday;
            this.pharmacist.search.endTime = this.pharmacist.yesterday;
            this.onPharmacistDataGet();
        }
    },
    mounted() {
        var _this = this;
        window.addEventListener("resize", () => {
            _this.chart.resize();
            _this.commodityDataStatisticsChart.resize()
            _this.commoditySalesStatisticsChart.resize()
        });
    },
    methods: {
        moment,
        onGetYesterday() { // 获取昨天
            return moment(moment(moment().format('YYYY-MM-DD')).unix() * 1000 - 24 * 60 * 60 * 1000).format('YYYY-MM-DD')
        },
        onPharmacistDataGet() { // 药师统计数据获取
            let send = {};
            if (this.pharmacist.search.startTime || this.pharmacist.search.endTime) {
                if (this.pharmacist.search.startTime) {
                    send.startTime = this.pharmacist.search.startTime + ' 00:00:00';
                }
                if (this.pharmacist.search.endTime) {
                    send.endTime = this.pharmacist.search.endTime + ' 23:59:59';
                }
            } else {
                send.startTime = this.pharmacist.yesterday + ' 00:00:00';
                send.endTime = this.pharmacist.yesterday + ' 23:59:59';
            }
            this.pharmacist.loading = true;
            this.$axios.get('/doctor-service/doctormg/prescription/auditPrescriptionHome', {params: send}).then((res) => {
                if (res.data.code == 0) {
                    this.topCountForPharmacist = res.data.data;
                }
                this.pharmacist.loading = false;
            }).catch((err) => {
                this.pharmacist.loading = false;
                this.$respone.error(this.$respone.catchMsg)
            })
        },
        onPharmacistDateTimeReset() { // 药师统计数据选择时间重置
            this.pharmacist.search.startTime = this.pharmacist.yesterday;
            this.pharmacist.search.endTime = this.pharmacist.yesterday;
            this.onPharmacistDataGet();
        },
        onPharmacistToOrder(type) { // 药师统计数据跳转到电子处方也
            this.$router.push(`/index/myPrescriptionIndex?type=${type}&startTime=${this.pharmacist.search.startTime || this.pharmacist.search.endTime ? this.pharmacist.search.startTime || '' : this.pharmacist.yesterday}&endTime=${this.pharmacist.search.startTime || this.pharmacist.search.endTime ? this.pharmacist.search.endTime || '' : this.pharmacist.yesterday}`)
        },
        getTopCount() {
            this.$axios.get(this.$APIURL.ORDER_TOP_COUNT).then(res => {
                if (res.data.code == 0) {
                    this.topCount = res.data.data
                }
            }).catch(err => {
                this.$respone.error(this.$respone.catchMsg)
            })
        },
        getOrderStatistics(startTime, endTime) {
            var _this = this
            this.$axios.get(this.$APIURL.ORDER_COUNTORDERBYDAY, { params: { startTime: startTime, endTime: endTime, pageSize: 999999999 } }).then(res => {
                if (res.data.code == 0) {
                    this.orderStatistics.xAxis.data = []
                    this.orderStatistics.series.data = []
                    var dayItem = ''
                    if (!res.data.data) return false
                    res.data.data.list.forEach((item, index) => {
                        dayItem = moment(item.dday).format('MM-DD') + "周" + "日一二三四五六".charAt(new Date(moment(item.dday).format('YYYY-MM-DD')).getDay())
                        _this.orderStatistics.xAxis.data.unshift(dayItem)
                        _this.orderStatistics.series.data.unshift(item.totalNum)
                        //_this.orderStatistics.series.data.unshift(index)
                    })
                    this.drawingOrderStatistics()
                } else {
                    this.$respone.error("查询数据失败，请稍后再试！");
                }
            }).catch(err => {
                this.$respone.error(this.$respone.catchMsg);
            })
        },
        change() {
            this.getOrderStatistics(this.orderSearchData.date[0], this.orderSearchData.date[1])
        },
        salesChange() {
            this.getCommoditySalesStatistics(this.salesSearchData.date[0], this.salesSearchData.date[1])
        },
        getDateSection(startDate, endDate) {
            var start = new Date(startDate)
            var end = new Date(endDate)
            var days = (end.getTime() - start.getTime()) / (1000 * 60 * 60 * 24)
            if (days <= 0) {
                days = 1
            }
            //var startTime = new Date(startDate)
            var startStr = ''
            var weekStr = ''
            this.xAxis.data = []
            for (var i = 0; i <= days; i++) {
                weekStr = "周" + "日一二三四五六".charAt(start.getDay())
                this.xAxis.data.push(moment(start).format('MM-DD') + weekStr)
                startStr = moment(start).add(1, 'days').format('YYYY-MM-DD')
                start = new Date(startStr)
            }
        },
        weekAgo() {
            this.orderSearchData.date = []
            this.orderSearchData.date.push(moment().subtract('days', 6).format('YYYY-MM-DD'))
            this.orderSearchData.date.push(moment().format('YYYY-MM-DD'))
            this.getOrderStatistics(this.orderSearchData.date[0], this.orderSearchData.date[1])
        },
        salesWeekAgo() {
            this.salesSearchData.date = []
            this.salesSearchData.date.push(moment().subtract('days', 6).format('YYYY-MM-DD'))
            this.salesSearchData.date.push(moment().format('YYYY-MM-DD'))
            this.getCommoditySalesStatistics(this.salesSearchData.date[0], this.salesSearchData.date[1])
        },
        drawingOrderStatistics() {
            // 基于准备好的dom，初始化echarts实例
            let myChart = this.$echarts.init(document.getElementById('myChart'))
            // 绘制图表
            myChart.setOption({
                /*title: {
                    text: '订单统计',
                },*/
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        label: {
                            backgroundColor: '#008ACD'
                        }
                    }
                },
                legend: {
                    data: ['全部订单']
                },
                toolbox: {
                    feature: {
                        saveAsImage: {}
                    }
                },
                grid: {
                    left: '2%',
                    right: '2%',
                    bottom: '3%',
                    containLabel: true,
                },
                xAxis: [
                    {
                        type: 'category',
                        boundaryGap: false,
                        data: this.orderStatistics.xAxis.data,
                        //坐标轴颜色
                        axisLine: {
                            lineStyle: {
                                color: '#333333'
                            }
                        },
                        axisTick: { show: false },
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        axisLine: {
                            lineStyle: {
                                color: '#333333'
                            }
                        },
                        splitLine: {
                            show: true,
                            lineStyle: {
                                color: ['#ededed'],
                                width: 1,
                                type: 'solid',
                                backgroundColor: 'red',
                            }
                        },
                        axisTick: { show: false },
                        splitArea: {           // 分隔区域
                            show: true,       // 默认不显示，属性show控制显示与否
                            // onGap: null,
                            areaStyle: {       // 属性areaStyle（详见areaStyle）控制区域样式
                                color: ['rgb(249,249,249)', 'rgb(254,254,254)']
                            }
                        },
                        boundaryGap: [0, 0.1],

                    }
                ],
                series: [
                    {
                        name: '全部订单',
                        type: 'line',
                        /*stack: '总量',*/
                        areaStyle: {
                            color: '#F2F7FF',
                        },
                        data: this.orderStatistics.series.data,
                        smooth: true,
                        itemStyle: {
                            normal: {
                                color: '#52A0FE',
                                lineStyle: {
                                    color: '#3888FA',
                                }
                            }
                        }
                    },

                ],
                toolbox: {
                    show: false
                }
            });
            this.chart = myChart
        },
        drawingCommodityDataStatistics() {
            // 基于准备好的dom，初始化echarts实例
            let commodityDataStatisticsChart = this.$echarts.init(document.getElementById('commodityDataStatisticsChart'))
            // 绘制图表
            commodityDataStatisticsChart.setOption({
                color: ['#5AB1EF'],
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                        type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
                    },
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: [
                    {
                        type: 'category',
                        data: this.commodityDataStatistics.xAxis.data,
                        axisTick: {
                            alignWithLabel: true
                        },
                        axisTick: { show: false },
                        //坐标轴颜色
                        axisLine: {
                            lineStyle: {
                                color: '#333333'
                            }
                        },
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        axisTick: { show: false },
                        //坐标轴颜色
                        axisLine: {
                            lineStyle: {
                                color: '#333333'
                            }
                        },
                        splitArea: {           // 分隔区域
                            show: true,       // 默认不显示，属性show控制显示与否
                            // onGap: null,
                            areaStyle: {       // 属性areaStyle（详见areaStyle）控制区域样式
                                color: ['rgb(254,254,254)', 'rgb(249,249,249)']
                            }
                        },
                        boundaryGap: [0, 0.1],
                    }
                ],
                series: [
                    {
                        name: '',
                        type: 'bar',
                        barWidth: '50%',
                        data: this.commodityDataStatistics.series.data,
                    }
                ]
            });
            this.commodityDataStatisticsChart = commodityDataStatisticsChart
        },
        getCommodityDataStatistics() {
            this.$axios.get(this.$APIURL.DRUGINFOS_ITEMCOUNTINDEX).then(res => {
                if (res.data.code == 0) {
                    this.commodityDataStatistics.series.data = []
                    this.commodityDataStatistics.series.data.push(res.data.data.totalNum)
                    this.commodityDataStatistics.series.data.push(res.data.data.upNum)
                    this.commodityDataStatistics.series.data.push(res.data.data.downNum)
                    this.commodityDataStatistics.series.data.push(res.data.data.lessNum)
                    this.commodityDataStatistics.series.data.push(res.data.data.outofNum)
                    this.drawingCommodityDataStatistics()
                } else {
                    this.$respone.error("商品数据统计获取失败！")
                }
            }).catch(err => {
                this.$respone.error(this.$respone.catchMsg)
            })
        },
        drawingCommoditySalesStatistics() {
            // 基于准备好的dom，初始化echarts实例
            let commoditySalesStatisticsChart = this.$echarts.init(document.getElementById('commoditySalesStatisticsChart'))
            commoditySalesStatisticsChart.setOption({
                color: "#5BB1EF",
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                legend: {
                    //data: ['2011年', '2012年']
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: {
                    type: 'value',
                    boundaryGap: [0, 0.01],
                    axisTick: { show: false },
                    splitArea: {           // 分隔区域
                        show: true,       // 默认不显示，属性show控制显示与否
                        // onGap: null,
                        areaStyle: {       // 属性areaStyle（详见areaStyle）控制区域样式
                            color: ['rgb(254,254,254)', 'rgb(249,249,249)']
                        }
                    },
                    boundaryGap: [0, 0.1],

                },
                yAxis: {
                    type: 'category',
                    data: this.commoditySalesStatistics.xAxis.data,
                    axisTick: { show: false },
                },
                series: [
                    {
                        name: '',
                        type: 'bar',
                        data: this.commoditySalesStatistics.series.data
                    }
                ]
            })
            this.commoditySalesStatisticsChart = commoditySalesStatisticsChart
        },
        getCommoditySalesStatistics(startTime, endTime) {
            this.$axios.get(this.$APIURL.DRUGINFOS_ITEMSALECOUNT, { params: { startTime: startTime, endTime: endTime, pageSize: 10 } }).then(res => {
                if (res.data.code == 0 && res.data.data != null) {
                    console.log(res.data.data.list)
                    this.commoditySalesStatistics.xAxis.data = []
                    this.commoditySalesStatistics.series.data = []
                    res.data.data.list.forEach(item => {
                        this.commoditySalesStatistics.xAxis.data.unshift(item.genericName)
                        this.commoditySalesStatistics.series.data.unshift(item.saleNum)
                    })
                    this.drawingCommoditySalesStatistics()
                } else if (res.data.code == 0 && res.data.data == null) {

                } else {
                    this.$respone.error("获取前10名商品销售统计数据失败！")
                }
            }).catch(err => {
                this.$respone.error(this.$respone.catchMsg)
            })
        }
    },
    components: {
        countTo
    }
}
</script>

<style scoped lang="scss">
.el-date-editor--daterange.el-input__inner {
    width: 250px;
}

.canvas-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #E9E9E9;
    height: 56px;
    line-height: 56px;
    padding: 0 0.1rem;

    &>div:first-child {
        font-size: 18px;
        font-family: PingFangSC-Regular;
        font-weight: 400;
        color: #222222;
    }

}

.canvas-content {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.04);
}

.el-progress-bar__inner::after,
.el-row::after,
.el-row::before,
.el-slider::after,
.el-slider::before,
.el-slider__button-wrapper::after,
.el-upload-cover::after {
    content: none;
}

.row {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap
}

.top-count {
    cursor: pointer;
    background-color: #FFF;
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.04);
    border-radius: 8px;
    color: #303133;
    display: flex;
    overflow: hidden;
    transition: .3s;
    align-items: center;
    justify-content: space-between;
    height: 1.36rem;
    margin: 0.1rem;

    &>div {
        width: 100%;
        text-align: center;

        div:first-child {
            color: #1890FF;
            font-size: 0.4rem;
        }

        div:nth-child(2) {
            color: #919191;
            font-size: 0.2rem;
        }
    }
}

.container {
    width: 100%;
    height: 100%;
    padding: 0.3rem;
    background: #fbfbfb;
    overflow-y: auto;

    &.gray {
        background: #f1f2f3;
    }
}

.pharmacist {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    min-height: calc(100vh - 120px);

    &-body {
        display: flex;
        margin-left: -20px;
        margin-top: 0;
    }

    &-card {
        width: 300px;
        border-radius: 4px;
        padding: 30px;
        box-shadow: 0 5px 10px rgba(230, 240, 255, .5);
        background: linear-gradient(180deg, #dcecfe, #fff 70%, #fff);
        min-height: 200px;
        margin-left: 20px;
        margin-top: 20px;

        .icon {
            width: 60px;
            height: 60px;
            background: #589cfd;
            border-radius: 4px;
            background: url(@/assets/images/home/examine-icon.png) center top / 100% 100% no-repeat;
        }

        .time {
            margin-top: 10px;
            color: #707070;
            height: 30px;
            line-height: 30px;
        }

        .num {
            display: flex;
            align-items: center;
            font-size: 26px;

            .now {
                color: #589cfd;
                font-size: 40px;
                font-weight: bold;
            }

            .cell {
                cursor: pointer;
            }

            .line {
                margin: 0 5px 0 10px;
                cursor: default;
            }
        }

        .desc {
            display: flex;
            align-items: center;
            font-size: 14px;

            .line {
                margin: 0 5px;
            }
        }

        &-timeout {
            background: linear-gradient(180deg, #fbe2df, #fff 70%, #fff);
            box-shadow: 0 5px 10px rgba(253, 234, 232, .5);

            .icon {
                background-image: url(@/assets/images/home/examine-timeout-icon.png);
            }

            .num {
                .now {
                    color: #f27065;
                }
            }
        }
    }
}
</style>
