<template>
    <div class="container">
        <div class="header">
            <patientHeader></patientHeader>
        </div>
        <div class="inside-container">
            <div>
                <leftMenu></leftMenu>
            </div>
            <div>
                <router-view></router-view>
            </div>
        </div>
    </div>
</template>

<script>
    import leftMenu from './leftMenu.vue'
    import patientHeader from './Header.vue'
    export default {
        name: "index",
        components:{
            leftMenu,
            patientHeader
        }
    }
</script>

<style scoped lang="scss">
    .container{
        height: 100%;
        width: 100%;
        position: relative;
        .header{
            position: absolute;
            width: 100%;
            top: 0;
            left: 0;
            height: 72px;
            box-shadow:0px 4px 8px 0px rgba(0,0,0,0.04);
            z-index: 2;
        }
        &>.inside-container{
            position: absolute;
            top: 72px;
            left: 0;
            bottom: 0;
            width: 100%;
            display: flex;
            flex-direction: row;
            &>div:nth-child(1){
                position: absolute;
                top: 0rem;
                left: 0;
                bottom: 0;
                width: 1.8rem;
                box-shadow:4px 0px 8px 0px rgba(0,0,0,0.04);
                z-index: 10;
                min-width: 120px;
            }
            &>div:nth-child(2){
                position: absolute;
                top: 0;
                left: 1.8rem;
                right: 0;
                bottom: 0;
                width: calc(100vw - 1.8rem);
                overflow: hidden;
            }
        }
    }
</style>
