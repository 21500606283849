//入口文件
import Vue from 'vue'
//1.1导入路由的包
import VueRouter from 'vue-router'
//1.2安装路由
Vue.use(VueRouter)

import element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(element);

import vueEsign from 'vue-esign'
Vue.use(vueEsign)

import $ from 'jquery'
//
import Vuex from 'vuex'
Vue.use(Vuex)
import store from './common/vuex.js'

//全局登录过期计时器
Vue.prototype.$loginTimer = null;

//导入请求路径
import APIURL from './common/APIConstants.js'
Vue.prototype.$APIURL=APIURL
//导入返回弹窗
import respone from './common/responMsg.js'
Vue.prototype.$respone=respone

//导入全局过滤器
import './common/globleFilter.js'


//导入vue-axios
import axios from './common/axiosConfig.js'
import qs from 'qs'

Vue.config.productionTip = false;
Vue.prototype.$axios=axios
Vue.prototype.$qs=qs


import iView from 'iview'
import 'iview/dist/styles/iview.css';
Vue.use(iView);

//引用公共js
import common from './common/common.js'
Vue.prototype.$common=common

//引用公共css
import '../lib/css/common.css'

//图片查看器
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
Vue.use(Viewer)
Viewer.setDefaults({
    Options: { 'inline': true, 'button': true, 'navbar': true, 'title': true, 'toolbar': true, 'tooltip': true, 'movable': true, 'zoomable': true, 'rotatable': true, 'scalable': true, 'transition': true, 'fullscreen': true, 'keyboard': true, 'url': 'data-source' }
})
//引入echart

import echarts from 'echarts'
Vue.prototype.$echarts = echarts
//导入公共css
import './common/public.css'

//引入封装好的websock
import { initWebSocket, sendWebsocket, onMessageWebsocket, closeWebsocket } from './common/socket'
Vue.prototype.$initWebSocket=initWebSocket
Vue.prototype.$sendWebsocket=sendWebsocket
Vue.prototype.$onMessageWebsocket=onMessageWebsocket
Vue.prototype.$closeWebsocket=closeWebsocket

//1.3导入自己的router.js路由模块
import router from './router.js'

//导入app根组件
import App from './App.vue'

// 水印指令
import { watermarkDirective } from './directives/watermark'
Vue.directive('watermark', watermarkDirective);

var vm = new Vue({
    el:'#app',
    render:c=>c(App),
    router,//1.4挂载路由对象到VM实例上
    store,

})
