export function askDrugPackCount(row) {
    // 包装计算
    const quantity = row.quantity
    let whole = '' // 整
    let remainder = '' // 零
    let lack = false // 缺
    let total = quantity // 总量
    let name = '' // 显示值
    if (quantity) {
      if (row.packingScale && row.packingScale != 1) {
        // 计算包装
        if (row.isPackingSale) {
          // 整装
          const int = Math.ceil(quantity / row.packingScale)
          whole = `${int}${row.unit}`
          if (int * row.packingScale > quantity) {
            lack = true
          }
          total = int * row.packingScale
        } else {
          // 部分包装
          const int = Math.floor(quantity / row.packingScale)
          if (int > 0) whole = `${int}${row.unit}`
          const float = quantity % row.packingScale
          if (float > 0) {
            // 余数
            remainder = `${float}${row.dosageUnitName}`
          }
        }
      } else {
        // 散装
        remainder = `${quantity}${row.dosageUnitName}`
      }
      name = [whole, remainder].join('')
    }
    return {
      whole,
      remainder,
      lack,
      total,
      name
    }
  }
  