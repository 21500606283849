<template>
    <div class="container1">
        <div class="search-top">
           <el-button :class="{btnActive:switchClass==1}" @click="getSearchData(1)" size="medium">近一周</el-button>
           <el-button :class="{btnActive:switchClass==2}" @click="getSearchData(2)" size="medium">近一个月</el-button>
            <el-date-picker
                    size="medium"
                    @change="changeDate"
                    v-model="searchData.startTime"
                    type="date"
                    value-format="yyyy-MM-dd"
                    placeholder="请选择开始日期"
                    :picker-options="startTime">
            </el-date-picker>
            -
            <el-date-picker
                    size="medium"
                    @change="changeDate"
                    v-model="searchData.endTime"
                    type="date"
                    value-format="yyyy-MM-dd"
                    placeholder="请选择结束日期"
                    :picker-options="endTime">
            </el-date-picker>
            <el-button type="primary" @click="getSearchData" size="medium" style="margin-left: 0.2rem;margin-right: 0.1rem">确定</el-button>
            <el-link :underline="false" @click.prevent="clearSearchData">清空筛选条件</el-link>
        </div>
        <div style="margin-top: 0.2rem">
            <el-table
                    v-loading="loading"
                    element-loading-text="拼命加载中"
                    :cell-style="cellStyle"
                    :data="tableData"
                    :header-cell-style="headerCellStyle"
                    border
                    style="width: 100%" v-watermark>
                <el-table-column
                        prop="index"
                        label="序号"
                        align="center"
                        width="80"
                        :show-overflow-tooltip="showOverflow"
                >
                </el-table-column>
                <el-table-column
                        prop="dday"
                        label="日期"
                        align="center"
                        :show-overflow-tooltip="showOverflow"
                >
                    <template slot-scope="scope">
                        <span>{{scope.row.dday | dataFormate}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="totalNum"
                        align="center"
                        :show-overflow-tooltip="showOverflow"
                        label="订单数">
                </el-table-column>
                <el-table-column
                        prop="totalFee"
                        align="center"
                        :show-overflow-tooltip="showOverflow"
                        label="¥商品金额">
                </el-table-column>
                <el-table-column
                        prop="discount"
                        align="center"
                        :show-overflow-tooltip="showOverflow"
                        label="¥优惠金额">
                </el-table-column>
                <el-table-column
                        prop="shippingFee"
                        align="center"
                        :show-overflow-tooltip="showOverflow"
                        label="¥运费">
                </el-table-column>
                <el-table-column
                        prop="payAmount"
                        align="center"
                        :show-overflow-tooltip="showOverflow"
                        label="¥订单总金额">
                </el-table-column>
            </el-table>
            <div class="pagination" v-if="total>0">
                <el-pagination
                        @prev-click="prevClick"
                        @next-click="nextClick"
                        @current-change="currentChange"
                        @size-change="sizeChange"
                        :current-page="searchData.pageNum"
                        :page-sizes="[10, 20, 30, 40]"
                        :page-size="searchData.pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="total">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>

    import moment from 'moment'
    export default {
        name: "inquiryOrder",
        data(){
            return {
                inquiryOrderDetails:'',
                showDialog:false,
                switchClass:0,
                loading:true,
                total:0,
                showOverflow:true,
                tableData:[],
                isClickYes:true,
                searchParam:{},
                basicParam:{
                    pageNum:1,
                    pageSize:10,
                },
                searchData: {
                    startTime:'',
                    endTime:''
                },

                startTime: {
                    disabledDate: time => {
                    if(this.searchData.endTime){
                        return (
                        time.getTime() > new Date(this.searchData.endTime).getTime()
                        )
                    }else {
                        return time.getTime() > Date.now()
                    }
                    }
                },
                endTime: {
                    disabledDate: time => {
                    if(this.searchData.startTime) {
                        return (
                        time.getTime() > Date.now() ||
                        time.getTime() < new Date(this.searchData.startTime).getTime() - 86400000
                        )
                    }else {
                        return time.getTime() > Date.now()
                    }
                    }
                }
            }
        },
        components:{

        },
        methods:{
            getDailyOrderStatisticsList(){
                this.loading=true;
                let _this=this;
                this.searchParam = {}
                this.searchParam = Object.assign({},this.basicParam)
                let send = JSON.parse(JSON.stringify(this.searchData))
                if(send.startTime) send.startTime = send.startTime + ' 00:00:00'
                if(send.endTime) send.endTime = send.endTime + ' 23:59:59'
                if(_this.isClickYes){
                    this.searchParam = Object.assign(this.searchParam,send)
                }
                this.$axios.get(this.$APIURL.ORDER_COUNTORDERBYDAY, { params: this.searchParam}).then((response) => {
                    this.loading=false;
                    if(response.data.code==0){
                        if(response.data.data!=null){
                            _this.tableData=response.data.data.list
                            _this.tableData.forEach((item,index) => {
                                item.index=++index
                            })
                            this.total=response.data.data.total
                        }else{
                            this.total=0
                            _this.tableData=[]
                        }
                        this.getTotalData()
                    }
                }).catch((error) => {
                    _this.loading=false;
                    _this.$respone.error(_this.$respone.catchMsg)
                })
            },
            prevClick(currentPage){
                this.basicParam.pageNum=currentPage;
                this.getDailyOrderStatisticsList()
            },
            nextClick(currentPage){
                this.basicParam.pageNum=currentPage;
                this.getDailyOrderStatisticsList()
            },
            currentChange(currentPage){
                this.basicParam.pageNum=currentPage;
                this.getDailyOrderStatisticsList()
            },
            sizeChange(size){
                this.basicParam.pageSize=size;
                this.getDailyOrderStatisticsList()
            },
            cellStyle({row,column,rowIndex,columnIdex}){
                let style = {height: '0.4rem'}
                return style;
            },
            headerCellStyle({row,column,rowIndex,columnIdex}){
                let style = {background: '#f1f4fa',height:'0.6rem'}
                return style;
            },
            getSearchData(num){
                this.isClickYes=true
                if(num==1){
                    this.searchData.startTime=moment().subtract(6, 'day').format('YYYY-MM-DD')
                    this.searchData.endTime=moment().format('YYYY-MM-DD')+" 23:59:59"
                }else if(num==2){
                    this.searchData.startTime=moment().subtract(1, 'months').format('YYYY-MM-DD')
                    this.searchData.endTime=moment().format('YYYY-MM-DD')+" 23:59:59"
                }
                this.switchClass=num
                this.basicParam.pageNum=1
                this.getDailyOrderStatisticsList();
            },
            changeDate(){
                this.switchClass=0
            },
            clearSearchData(){
                this.isClickYes=false
                this.basicParam.pageNum=1
                this.switchClass=0
                this.searchData.startTime=this.searchData.endTime=''
                this.getDailyOrderStatisticsList();
            },
            closeDialog(data){
                this.showDialog=data;
            },
            getTotalData(){
                this.$axios.get(this.$APIURL.ORDER_COUNTORDERTOTAL,{params:this.searchData}).then(res => {
                    if(res.data.code==0){
                        console.log(res.data)
                        this.tableData.unshift({index:'合计',dday:null,totalNum:res.data.data.totalNum,
                            totalFee:res.data.data.totalFee,
                            discount:res.data.data.discount,
                            shippingFee:res.data.data.shippingFee,
                            payAmount:res.data.data.payAmount,
                        })
                    }else{
                        this.$respone.error("每日订单订单数合计数据获取失败！")
                    }
                }).catch(err => {
                    this.$respone.error(this.$respone.catchMsg)
                })
            }
        },
        created(){
            this.searchData.startTime=moment().subtract(1, 'months').format('YYYY-MM-DD')
            this.searchData.endTime=moment().format('YYYY-MM-DD')+" 23:59:59"
            this.switchClass=2
          this.getDailyOrderStatisticsList()
        }

    }
</script>

<style scoped lang="scss">
.container1{
    padding: 0;
}
.search-top{
    margin-top: 0.2rem;
}
.pagination{
    margin-top: 0.2rem;
    text-align: center;
}
.btnActive{
    color: white;
    background-color: rgb(64,158,255);
}
</style>
