<template>
  <el-dialog
    custom-class="dialogClass"
    title="物流信息"
    :visible.sync="dialogVisible"
    @closed="closed"
    :modal-append-to-body="false"
  >
    <span class="dialog-span">
      <div style="display: flex; align-items: center; margin-bottom: 20px">
        <img
          :src="require('../../../../lib/img/logistics_sf.png')"
          width="80"
          v-if="logisticsInfo.com.includes('shunfeng')"
        />
        <img
          :src="require('../../../../lib/img/logistics_zt.png')"
          width="80"
          v-else-if="logisticsInfo.com.includes('zhongtong')"
        />
        <img
          :src="require('../../../../lib/img/logistics_yt.png')"
          width="80"
          v-else-if="logisticsInfo.com.includes('yuantong')"
        />
        <img
          :src="require('../../../../lib/img/logistics_yd.png')"
          width="80"
          v-else-if="logisticsInfo.com.includes('yunda')"
        />
        <img
          :src="require('../../../../lib/img/logistics_tt.png')"
          width="80"
          v-else-if="logisticsInfo.com.includes('tiantian')"
        />
        <img
          :src="require('../../../../lib/img/logistics_ems.png')"
          width="80"
          v-else-if="logisticsInfo.com.includes('ems')"
        />
        <img
          :src="require('../../../../lib/img/logistics_zjs.png')"
          width="80"
          v-else-if="logisticsInfo.com.includes('zhaijisong')"
        />
        <img
          :src="require('../../../../lib/img/logistics_bs.png')"
          width="80"
          v-else-if="logisticsInfo.com.includes('huitongkuaidi')"
        />
        <img :src="require('../../../../lib/img/logistics_defalt.png')" width="80" v-else />
        <div style="margin-left: 30px; color: #333333; font-size: 18px">
          <p>物流公司：{{ getLogisticsName(logisticsInfo.com) }}</p>
          <p style="margin-top: 5px">物流单号：{{ logisticsInfo.nu }}</p>
        </div>
      </div>
      <div
        v-for="(item, index) of logisticsInfo.data"
        style="display: flex; height: 90px"
        :key="index"
      >
        <div style="padding-right: 17px; color: #666666; text-align: right">
          <div style="font-size: 16px; white-space: nowrap">
            {{ item.ftime | dataFormate("MM-DD") }}
          </div>
          <div style="font-size: 12px">{{ item.ftime | dataFormate("HH:mm") }}</div>
        </div>
        <div style="padding: 0 17px; position: relative">
          <div
            style="
              position: absolute;
              width: 7px;
              height: 7px;
              background: #cccccc;
              border-radius: 50%;
              top: 8px;
              left: -3px;
              z-index: 2;
            "
          ></div>
          <div
            style="
              width: 1px;
              border-left: 1px solid #e6e6e6;
              height: 85px;
              position: absolute;
              left: 0;
              top: 13px;
            "
          ></div>
          <div style="color: #333333">
            <div style="font-size: 16px">{{ item.status }}</div>
            <div style="font-size: 14px">{{ item.context }}</div>
          </div>
        </div>
      </div>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: "lookLogisticsDialog",
  data() {
    return {
      dialogVisible: true,
    };
  },
  methods: {
    closed() {
      this.$emit("closeDialog", false);
    },
    getLogisticsName(val) {
      switch (val) {
        case (val = "shunfeng"):
          return "顺丰快递";
          break;
        case (val = "zhongtong"):
          return "中通快递";
          break;
        case (val = "yuantong"):
          return "圆通快递";
          break;
        case (val = "yunda"):
          return "韵达快递";
          break;
        case (val = "tiantian"):
          return "天天快递";
          break;
        case (val = "ems"):
          return "ems快递";
          break;
        case (val = "zhaijisong"):
          return "宅急送快递";
          break;
        case (val = "huitongkuaidi"):
          return "百世快递";
          break;
      }
    },
  },
  prop: ["logisticsInfo"],
  props: {
    logisticsInfo: {
      default: 3,
    },
  },
};
</script>

<style scoped lang="scss">
:v-deep .dialogClass {
  border-radius: 4px;
}
:v-deep .el-dialog__header {
  border-bottom: 1px solid #cccccc;
  padding: 20px;
}
.dialog-span {
  & > div:last-child {
    & > div:nth-child(2) {
      & > div:nth-child(2) {
        display: none;
      }
    }
  }
}
.ivu-divider,
.ivu-divider-vertical {
  margin: 0;
}
.ivu-divider-horizontal {
  margin-bottom: 0.1rem;
}
:v-deep .el-row {
  margin-bottom: 0.1rem !important;
}
:v-deep .dialogClass {
  margin-top: 3vh !important;
  width: 800px;
  min-width: 800px;
}
.el-dialog__wrapper {
  top: 0;
}
.el-row {
  margin-bottom: 0.2rem;
  .el-col:nth-child(odd) {
    text-align: right;
  }
}
</style>
