<template>
  <div class="header">
    <div class="one">
      <!-- <i></i> -->
      <img style="width:48px;height:48px" src="../../static/logo.png" />
      <span v-if="userInfo.userType == 2">润生医疗-药房端</span>
      <span v-else-if="userInfo.userType == 5">润生医疗-药师端</span>
    </div>
    <div class="two">
      <el-avatar
        size="large"
        :src="
          userInfo.avatar
            ? userInfo.avatar
            : 'https://ss2.bdstatic.com/70cFvnSh_Q1YnxGkpoWK1HF6hhy/it/u=1225714138,1466146804&fm=26&gp=0.jpg'
        "
      ></el-avatar>
      <span>{{ userInfo.username }}</span>
      <!--<el-link @click="loginOut" :underline="false" style="display: flex;align-items: center"><i class="el-icon-switch-button el-icon&#45;&#45;right exit"></i> <span>退出</span></el-link>-->
      <span
        @click="loginOut"
        style="cursor: pointer; display: flex; align-items: center"
        class="exit"
      >
        <i class="el-icon-switch-button el-icon--right"></i>
        <span style="margin-left: 5px; font-size: 16px">退出</span>
      </span>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "Header",
  data() {
    return {
      headerDate: {
        date: "",
        week: "",
      },
    };
  },
  created() {
    this.headerDate.date = moment(new Date()).format("YYYY年MM月DD日");
    this.headerDate.week = "星期" + "日一二三四五六".charAt(new Date().getDay());
  },
  computed: {
    userInfo() {
      return this.$store.state.userInfo;
    },
  },
  methods: {
    loginOut() {
      this.$store.commit("setUserInfo", {});
      this.$closeWebsocket(), //关闭websocket连接
        this.$router.push("/login");
      this.$store.commit("setLeftMenuActiveName", "/index/home");
    },
  },
};
</script>

<style scoped lang="scss">
.header {
  height: 100%;
  background: white;
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  .one {
    display: flex;
    align-items: center;
    i {
      display: inline-block;
      width: 32px;
      height: 32px;
      background: url("../../lib/img/top_logo.png") no-repeat center;
      background-size: cover;
      margin-left: 24px;
    }
    span {
      color: #222222;
      font-size: 20px;
      margin-left: 12px;
      font-family: PingFangSC-Regular;
      font-weight: 400;
    }
  }
  .two {
    color: #404040;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 0.5rem;
    & > span {
      margin: 0 0.1rem;
    }
    .exit {
      color: #333333;
      font-size: 28px;
    }
    .exit:hover {
      color: red;
    }
  }
}
</style>
