<template>
    <div class="prescription-list">
        <el-dialog
            title="原电子处方列表"
            :visible.sync="dialogVisible"
            width="800px"
            @closed="closeInitPrescriptionListDialog">
            <span>
                <el-table
                        :data="initPrescriptionList"
                        border
                        :header-cell-style="headerCellStyle"
                        style="width: 100%">
                    <el-table-column
                            prop="initPrescriptionNo"
                            label="原处方编号"
                            align="center"
                            :show-overflow-tooltip="showOverflow"
                            width="250px"
                    >
                    </el-table-column>
                    <el-table-column
                            label="操作"
                            align="center"
                            :show-overflow-tooltip="showOverflow"
                    >
                        <template slot-scope="scope">
                            <el-button size="small" type="primary" v-show="scope.row.initPrescriptionNo" @click="lookInitPrescription(scope.row.url)" :disabled="!scope.row.url">查看原电子处方</el-button>
                            &nbsp;
                            <el-button  class="el-icon-download" size="small" :disabled="false" v-if="!scope.row.url">原处方（png）</el-button>
                            <a title="按住alt键点击按钮" v-show="scope.row.initPrescriptionNo" target="_blank" :href=picShow+scope.row.url v-else>
                                <el-button  class="el-icon-download" size="small" >原处方（png）</el-button>
                            </a>&nbsp;
                            <el-button class="el-icon-download" size="small" :disabled="false" v-if="!scope.row.pdfUrl">原处方（pdf）</el-button>
                            <a title="按住alt键点击按钮" v-show="scope.row.initPrescriptionNo" target="_blank" :href=picShow+scope.row.pdfUrl v-else>
                                <el-button class="el-icon-download" size="small" >原处方（pdf）</el-button>
                            </a>
                        </template>
                    </el-table-column>
                </el-table>
            </span>
        </el-dialog>
    </div>
</template>
<script>
    import { watermarkImageCreate } from '@/directives/watermark'
    export default {
        name: 'initPrescriptionList',
        data() {
            return {
                dialogVisible: true,
                showOverflow: true,
                picShow: this.$APIURL.PIC_SHOW,
            }
        },
        props: ['initPrescriptionList'],
        methods:{
            headerCellStyle({row,column,rowIndex,columnIdex}){
                let style = {background: '#f1f4fa',height:'0.45rem'}
                return style;
            },
            // 关闭对话框
            closeInitPrescriptionListDialog() {
                this.$emit('closeInitPrescriptionListDialog', false)
            },
            // 查看电子处方
            async lookInitPrescription(url){
                try {
                    const img = await watermarkImageCreate(this.$APIURL.PIC_SHOW + url)
                    let imgList = [img.url]
                    this.$viewerApi({
                        images: imgList,
                        options: {
                            zIndex: 2222
                        }
                    })
                } catch (error) {
                    
                }
            }
        }
    }
</script>