<template>
  <el-table
    :data="commodityInformation.prescriptionDetailList"
    border
    :header-cell-style="headerCellStyle"
    style="width: 100%"
  >
    <el-table-column
      prop="drugPic"
      label="商品图片"
      align="center"
      :show-overflow-tooltip="showOverflow"
    >
      <template slot-scope="scope">
        <img :src="scope.row.drugPic" width="50" />
      </template>
    </el-table-column>
    <el-table-column
      prop="id"
      label="药房商品编码"
      align="center"
      :show-overflow-tooltip="showOverflow"
    ></el-table-column>
    <el-table-column
      prop="drugNo"
      align="center"
      :show-overflow-tooltip="showOverflow"
      label="商品编码"
    ></el-table-column>
    <el-table-column
      prop="drugName"
      align="center"
      :show-overflow-tooltip="showOverflow"
      label="商品名称"
    ></el-table-column>
    <el-table-column
      prop="drugSpecifications"
      align="center"
      :show-overflow-tooltip="showOverflow"
      label="规格"
    ></el-table-column>
    <el-table-column
      prop="quantity"
      align="center"
      :show-overflow-tooltip="showOverflow"
      label="数量"
    >
      <template slot-scope="{ row }">
        {{ `${row.quantity}${row.dosageUnitName}(${askDrugPackCount(row).name})` }}
      </template>
    </el-table-column>
    <el-table-column
      prop="price"
      align="center"
      :show-overflow-tooltip="showOverflow"
      label="单价(元)"
    ></el-table-column>
    <template #append>
      <div class="append-item">
        <div>总金额</div>
        <div>￥{{ Number(commodityInformation.totalFee || 0).toFixed(2) }}</div>
      </div>
      <div class="append-item">
        <div>优惠券</div>
        <div style="color: #ffa74a">
          ￥{{ Number(commodityInformation.discount || 0).toFixed(2) }}
        </div>
      </div>
      <div class="append-item">
        <div>减免</div>
        <div>￥{{ Number(commodityInformation.reductionFee || 0).toFixed(2) }}</div>
      </div>
      <div class="append-item">
        <div>折扣</div>
        <div style="color: #ffa74a">
          ￥{{ Number(commodityInformation.rebateFee || 0).toFixed(2) }}
        </div>
      </div>
      <div class="append-item">
        <div>实付</div>
        <div style="color: red">
          {{
            commodityInformation.payAmount == null
              ? "￥0.00"
              : commodityInformation.status === null ||
                commodityInformation.status == 0 ||
                commodityInformation.status == 3
              ? ""
              : `￥${Number(commodityInformation.payAmount || 0).toFixed(2)}`
          }}
        </div>
      </div>
    </template>
  </el-table>
</template>

<script>
import { askDrugPackCount } from "@/utils/utils";
export default {
  name: "commodityInformationTable.vue",
  data() {
    return {
      showOverflow: true,
    };
  },
  props: ["commodityInformation"],
  props: {
    commodityInformation: {
      default: 3,
    },
  },
  methods: {
    askDrugPackCount,
    headerCellStyle({ row, column, rowIndex, columnIdex }) {
      let style = { background: "#f1f4fa", height: "0.45rem" };
      return style;
    },
  },
};
</script>

<style scoped lang="scss">
.append-item {
  display: flex;

  div {
    text-align: center;
    flex: 1;
    padding: 8px 10px;
    border-bottom: 1px solid #ebeef5;
    border-right: 1px solid #ebeef5;
    text-align: right;
    box-sizing: border-box;

    & + div {
      flex: 0 1 auto;
      width: 180px;
      border: none;
      border-bottom: 1px solid #ebeef5;
      text-align: center;
    }
  }
}
</style>
