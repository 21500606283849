<template>
  <el-dialog :visible.sync="advices.show" :modal-append-to-body="false" width="1280px">
    <template #title>
      <div class="header">
        <div class="title">打印用药指导</div>
        <div class="lang">
          <el-radio-group class="order-print-advice" v-model="langs.active" size="small">
            <el-radio-button
              :label="item.value"
              :value="item.value"
              v-for="(item, index) in langs.list"
              :key="index"
            >
              {{ item.label }}
            </el-radio-button>
          </el-radio-group>
        </div>
      </div>
    </template>
    <div class="advices" v-loading="advices.loading">
      <el-scrollbar>
        <div class="scrollbar">
          <div class="prints">
            <template v-for="item in advices.list">
              <div
                class="advice"
                :class="{ langs: langs.active !== 'zh_CN', bo: langs.active === 'bo_CN' }"
                ref="PrintRef"
              >
                <div class="titlebar">
                  <div class="title">
                    <span>{{ item.userMemberName }}</span>
                    <span>-专属用药指导</span>
                  </div>
                  <div class="desc" v-if="langs.active === 'zh_CN'">（{{ item.time }}处方）</div>
                  <div class="desc tint" v-else-if="langs.values[langs.active]">
                    {{ langs.values[langs.active].title }}
                  </div>
                </div>
                <div class="contents">
                  <div class="card">
                    <div class="drugs">
                      <div class="table">
                        <div class="tr thead">
                          <div class="td">
                            <div class="p">最佳用药时段</div>
                            <div
                              class="p trans"
                              v-if="langs.values[langs.active] && langs.values[langs.active].thead"
                            >
                              {{ langs.values[langs.active].thead.timeName }}
                            </div>
                          </div>
                          <div class="td">
                            <div class="p">服用药品</div>
                            <div
                              class="p trans"
                              v-if="langs.values[langs.active] && langs.values[langs.active].thead"
                            >
                              {{ langs.values[langs.active].thead.medicationName }}
                            </div>
                          </div>
                        </div>
                        <div
                          class="tr"
                          v-for="(group, groupIndex) in item.groups"
                          :key="groupIndex"
                        >
                          <div class="td">
                            <div class="p">{{ group.label }}</div>
                            <div
                              class="p trans"
                              v-if="
                                langs.values[langs.active] &&
                                langs.values[langs.active].thead[group.type]
                              "
                            >
                              {{ langs.values[langs.active].thead[group.type] }}
                            </div>
                            <div class="p time">{{ group.time }}</div>
                          </div>
                          <div class="td">
                            <div class="cells">
                              <div
                                class="cell"
                                v-for="(drug, drugIndex) in group.drugs"
                                :key="drugIndex"
                              >
                                <span>{{ drug.drugName }}</span>
                                <span>&nbsp;&nbsp;</span>
                                <span>口服</span>
                                <span>&nbsp;&nbsp;</span>
                                <span class="red">{{ drug.dosage }}</span>
                                <span class="red">
                                  {{ drug.dosageUnitName }}
                                </span>
                                <template v-if="!drug.frequencyName.includes('每日')">
                                  <span>&nbsp;</span>
                                  <span class="orange">({{ drug.frequencyName }})</span>
                                </template>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="tips">
                      <div class="head">
                        <div class="text">
                          <span>润生医疗医生温馨提示</span>
                          <span v-if="langs.values[langs.active]">
                            {{ langs.values[langs.active].prompts.title }}
                          </span>
                          <span>：</span>
                        </div>
                      </div>
                      <div class="prompts">
                        <div class="item" v-for="(itm, idx) in advices.prompts" :key="idx">
                          <div class="label">{{ idx + 1 }}.</div>
                          <div class="value">
                            <template v-if="langs.active === 'zh_CN'">
                              <span class="zh">{{ itm }}</span>
                              <span>{{ idx >= advices.prompts.length - 1 ? "。" : "；" }}</span>
                            </template>
                            <template v-else>
                              <div class="zh">{{ itm }}</div>
                              <div class="trans" v-if="langs.values[langs.active]">
                                {{ langs.values[langs.active].prompts[`p${idx + 1}`] }}
                              </div>
                            </template>
                          </div>
                        </div>
                      </div>
                      <div class="list">
                        <div class="item" v-for="(itm, idx) in advices.tips" :key="idx">
                          <div class="label">
                            <div class="label-box">
                              <div class="zh">{{ itm.label }}</div>
                              <div class="trans" v-if="langs.values[langs.active]">
                                {{ langs.values[langs.active].advices[itm.type].title }}
                              </div>
                            </div>
                          </div>
                          <div class="content">
                            <div class="content-box">
                              <div class="zh" v-if="langs.active !== 'en_CN'">
                                {{ itm.content }}
                              </div>
                              <div class="trans" v-if="langs.values[langs.active]">
                                {{ langs.values[langs.active].advices[itm.type].content }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="concats">
                      <div class="info">
                        <div class="item">
                          <div class="text zh">联系热线：400-154-1313</div>
                        </div>
                        <!-- <div class="item">
                          <div class="text zh">
                            医院地址：武汉市江夏区关南园一路当代光谷梦工场3栋2楼
                          </div>
                        </div>
                        <div class="foot">
                          <el-image :src="LogoImg" />
                        </div> -->
                      </div>
                      <!-- <div class="qrcodes">
                        <div class="img">
                          <el-image :src="QrcodeImg" />
                        </div>
                        <div class="text">
                          <div class="p zh">扫码咨询</div>
                        </div>
                      </div> -->
                      <!-- <div class="logo" v-else>
												<div class="p">
													<el-image :src="LogoBlackImg" />
												</div>
												<div class="p zh">身心兼治·病愈得悟·乐享健康</div>
											</div> -->
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </el-scrollbar>
      <div class="footer">
        <el-button type="primary" :loading="prints.loading" @click="onPrint">打印</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { Message as ElMessage } from "element-ui";
import QrcodeImg from "@/assets/images/advice/print/qrcode.png";
import LogoImg from "@/assets/images/advice/print/logo.png";
import LogoBlackImg from "@/assets/images/advice/print/logo-black.png";
import HeaderImg from "@/assets/images/advice/print/header.png";
import DrugImg from "@/assets/images/advice/print/drug.png";
import DrugLightImg from "@/assets/images/advice/print/drug-light.png";
import TimeImg from "@/assets/images/advice/print/time.png";
import TimeOImg from "@/assets/images/advice/print/time-o.png";
import PrintJS from "print-js";
import Html2canvas from "html2canvas";
import moment from "moment";
export default {
  data() {
    return {
      QrcodeImg,
      LogoImg,
      LogoBlackImg,
      HeaderImg,
      DrugImg,
      DrugLightImg,
      TimeImg,
      TimeOImg,
      advices: {
        show: false,
        loading: false,
        tips: [
          {
            label: "饮食",
            content:
              "在服药期间适当注意饮食起居的配合，如饮食清淡营养，戒烟限酒，忌辛辣煎炸、刺激性大、油腻及胶原蛋白含量高的食物，不要喝碳酸饮料，少吃酸性水果，多吃蔬菜等。",
            type: "diet",
          },
          {
            label: "起居",
            content:
              "按时休息、保证充足睡眠，晚上尽量在23点以前 入睡、避免受寒、受凉、过度劳累、适当按摩四肢增加血液循环。",
            type: "rest",
          },
          {
            label: "情志",
            content:
              "避免情绪激动,可以听轻音乐、冥想、品茶、工作之余也可以适当的运动20-30分钟，散散步,跟朋友聊聊天。",
            type: "emotional",
          },
        ],
        prompts: [
          "服药时间为饭前半小时",
          "可根据个人作息时间可以适当做调整，如有胃肠不适可以改为饭后半小时服用",
          "药丸请嚼碎或者捣碎服用",
        ],
        list: [],
      },
      prints: {
        loading: false,
      },
      times: [
        {
          type: "early",
          label: "早饭前",
          time: "(07:00 - 08:00)",
          value: ["早", "饭前"],
          drugs: [],
        },
        {
          type: "earlyAfter",
          label: "早饭后",
          time: "(08:00 - 09:00)",
          value: ["早", "饭后"],
          drugs: [],
        },
        {
          type: "noon",
          label: "午饭前",
          time: "(11:30 - 12:30)",
          value: ["午", "饭前"],
          drugs: [],
        },
        {
          type: "noonAfter",
          label: "午饭后",
          time: "(12:30 - 13:30)",
          value: ["午", "饭后"],
          drugs: [],
        },
        {
          type: "night",
          label: "晚饭前",
          time: "(17:30 - 18:30)",
          value: ["晚", "饭前"],
          drugs: [],
        },
        {
          type: "nightAfter",
          label: "晚饭后",
          time: "(18:30 - 19:30)",
          value: ["晚", "饭后"],
          drugs: [],
        },
        {
          type: "bedtime",
          label: "睡前",
          time: "(21:00 - 22:00)",
          value: ["睡"],
          drugs: [],
        },
      ],
      langs: {
        active: "zh_CN",
        list: [
          {
            label: "中文版",
            value: "zh_CN",
          },
          {
            label: "中藏文版",
            value: "bo_CN",
          },
          {
            label: "中英文版",
            value: "en_CN",
          },
        ],
        values: {
          bo_CN: {
            title: "ཆེད་གཏོགས་སྨན་སྤྱོད་མཛུབ་སྟོན།",
            thead: {
              timeName: "སྨན་བསྟེན་ཚོད།",
              medicationName: "སྨན་སྣ།",
              early: "ཞོགས་ཟས་སྔོན།",
              noon: "ཉིན་གུང་ཟས་སྔོན།",
              night: "དགོང་ཇ་སྔོན།",
              earlyAfter: "ཞོགས་ཟས་རྗེས།",
              noonAfter: "ཉིན་གུང་ཟས་རྗེས།",
              nightAfter: "དགོང་ཇ་ཟས་རྗེས།",
              bedtime: "མལ་ཁར།",
            },
            prompts: {
              title: "",
              p1: "སྨན་བསྟེན་པའི་དུས་ཚོད་ནི་ཟ་མ་མ་ཟོས་སྔོན་གྱི་དུས་ཚོད་ཕྱེད་ཀ་ཡིན།",
              p2: "མི་སྒེར་གྱི་ངལ་གསོའི་དུས་ཚོད་ལ་གཞིགས་ནས་ལེགས་སྒྲིག་འོས་འཚམ་བྱས་ཆོག ཟས་སྔོན་སྨན་བསྟེན་རྗེས་མི་འཕྲོད་པ་ཡོད་ན་ཟས་རྗེས་བསྟེན་ཆོག ་",
              p3: "སྨན་རིལ་ལྡད་པའམ་ཡང་ན་ཁྲོལ་རྡུངས་བྱས་ནས་བསྟེན་དགོས། །",
            },
            advices: {
              diet: {
                title: "ཟས་རིགས།",
                content:
                  "སྨན་བསྟེན་སྐབས་ཟས་སྤྱོད་ལ་དོ་སྣང་བྱེད་དགོས། དཔེར་ན་ཟས་མ་ཚོས་ཚིག་དང་ཁེངས་བོ་རྗེན་པ་དང་རུལ་སྐྱུར་སོགས་མི་འཕྲོད་པའི་ཟས་རིགས་སྤང་སྟེ་ཟས་འཇུ་སླ་བའི་རིགས་བསྟེན་དགོས།ཐ་མག་དང་ཆང་ལ་ཚོད་འཛིན་བྱེད་དགོས། ",
              },
              rest: {
                title: "འགྲོ་འདུག",
                content:
                  "དགོང་མོར་དུས་ལྟར་ངལ་གསོ་བྱ་བ་དང་།རྩོལ་བཅག་རན་པར་བསྟེན་པ། ཡན་ལག་དང་གསང་དམིག་ལ་འཕུར་མཉེད་བྱེད་པ་སོགས།",
              },
              emotional: {
                title: "སེམས་ཁམས།",
                content:
                  "སེམས་ཁམས་སྙོམས་སྒྲིག་བྱ་བ་སྟེ།དཔེར་ན་གླུ་དབྱངས་ལ་ཉན་པ་དང་བསམ་བློ་གཏོང་བ།ཇ་འཐུང་བ།ངལ་གསོ་འོས་འཚམ་བྱ་བ།རྩོལ་བཅག་རན་པར་བསྟེན་པ། ཡིད་དུ་འོང་བའི་གྲོགས་དང་བསྟེན་པ་སོགས། ",
              },
            },
          },
          en_CN: {
            title: "Exclusive Medication Guidance",
            thead: {
              timeName: "Best time to take",
              medicationName: "Medication",
              early: "Before breakfast",
              noon: "Before lunch",
              night: "Before dinner",
              earlyAfter: "After breakfast",
              noonAfter: "After lunch",
              nightAfter: "After dinner",
              bedtime: "Before bedtime",
            },
            prompts: {
              title: ``,
              p1: "Take medication half an hour before meals.",
              p2: "You can adjust the timing according to your personal schedule. If you experience gastrointestinal discomfort,you can take the medication half an hour after meals.",
              p3: "Please chew or crush the pills before taking them.",
            },
            advices: {
              diet: {
                title: "Diet",
                content:
                  "During the medication period, it is important to pay attention to diet and lifestyle, such as consuming a light and nutritious diet, quitting smoking and limiting alcohol intake, avoiding spicy, fried, and greasy foods, as well as foods high in collagen content. Avoid carbonated drinks, reduce consumption of acidic fruits, and increase intake of vegetables.",
              },
              rest: {
                title: "Rest",
                content:
                  "Rest on time and ensure an adequate amount of sleep. Try to go to bed before 11 p.m. to avoid getting cold, overexertion, or excessive fatigue. Engage in gentle limb massages to enhance blood circulation.",
              },
              emotional: {
                title: "Emotional  State",
                content:
                  "Avoid getting emotionally excited. You can listen to soft music, meditate, drink tea, and engage in light exercise for 20-30 minutes during breaks from work. Taking a walk and chatting with friends are also beneficial.",
              },
            },
          },
        },
      },
    };
  },
  // mounted() {
  // 	this.init(['SP2024032100003326'])
  // },
  methods: {
    async init(orderNos) {
      if (!orderNos.length) return false;
      this.langs.active = "zh_CN";
      // this.langs.active = 'bo_CN'
      this.advices.show = true;
      this.advices.list = [];
      this.onDataMap(orderNos);
    },
    async onDataMap(orderNos) {
      this.advices.loading = true;
      try {
        for (let i = 0; i < orderNos.length; i++) {
          const data = await this.onDataGet(orderNos[i]);
          this.advices.list.push(this.onDrugGroup(data));
        }
      } catch (error) {
        ElMessage.error("订单数据获取失败");
      }
      this.advices.loading = false;
    },
    async onDataGet(orderNo) {
      const {
        data: { data, code },
      } = await this.$axios({
        method: "get",
        url: "/order-service/drugmg/order/itemDetail",
        params: {
          orderNo,
          // 区分是否已支付
          status: 4,
        },
      });
      if (code === 0) {
        return data;
      } else {
        throw false;
      }
    },
    // 药品初始化
    onDrugInit(list) {
      const timesArr = JSON.parse(JSON.stringify(this.times));
      for (let i = 0; i < list.length; i++) {
        const item = list[i];
        timesArr.forEach(row => {
          if (row.value.every(value => item.drugUsageName.includes(value))) {
            row.drugs.push(JSON.parse(JSON.stringify(item)));
          }
        });
      }
      return timesArr;
    },
    // 分组
    onDrugGroup(row) {
      const times = this.onDrugInit(row.prescriptionDetailList);
      return {
        userMemberName: row.userMemberName,
        userMemberSex: row.userMemberSex,
        time: moment(row.orderTime).format("M月D日"),
        groups: times.filter(row => row.drugs.length),
      };
    },
    // 打印
    async onPrint() {
      if (this.$refs.PrintRef && this.$refs.PrintRef.length) {
        this.prints.loading = true;
        try {
          const images = [];
          for (let i = 0; i < this.$refs.PrintRef.length; i++) {
            const canvas = await Html2canvas(this.$refs.PrintRef[i], {
              scale: 4.1,
            });
            images.push(canvas.toDataURL("image/png"));
          }

          PrintJS({
            printable: images,
            type: "image",
            documentTitle: "用药指导",
            imageStyle: "width: 100%",
          });
        } catch (error) {
          ElMessage.error("打印失败");
        }
        this.prints.loading = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/css/base";
$mainColor: #2f4593;

.header {
  .title {
    font-size: 18px;
  }

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 40px;

  .el-radio-group {
    user-select: none;
  }
}

.advices {
  font-family: "Source Han Sans CN", "Source Han Serif SC", "Source Han Sans TC",
    "Source Han Serif TC", "Source Han Sans", "Source Han Serif", "思源黑体", "思源宋体",
    "Noto Sans CJK SC", "Noto Serif CJK SC", "Noto Sans CJK TC", "Noto Serif CJK TC",
    "Noto Sans CJK", "Noto Serif CJK", sans-serif;

  .scrollbar {
    height: 60vh;
  }

  .advice {
    position: relative;
    margin: 0 auto;
    page-break-after: always;
    padding-bottom: 10px;
    box-sizing: border-box;
    word-break: break-word;

    &:not(:first-child) {
      margin-top: 20px;
    }

    .titlebar {
      text-align: center;
      font-weight: bold;
      padding: 12px;
      color: #141414;
      background: $mainColor;

      .title {
        color: #f4d495;
        font-size: 30px;
      }

      .desc {
        color: #fff;
        margin-top: 5px;
        font-size: 20px;

        &.tint {
          color: #f4d495;
          margin-top: -5px;
        }
      }
    }

    .contents {
      display: flex;
      margin-top: 20px;
      padding: 0 10px;

      .card {
        position: relative;
        flex: 1;
        padding-bottom: 10px;

        &:not(:first-child) {
          margin-left: 20px;
          padding-left: 20px;

          &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            border: 1px dashed #d5daea;
          }
        }
      }
    }

    .drugs {
      .table {
        display: table;
        table-layout: fixed;
        border: 1px solid #474443;
        width: 100%;
        border-collapse: collapse;
        box-sizing: border-box;

        .tr {
          display: table-row;

          .td {
            display: table-cell;
            border: 1px solid #536ab6;
            padding: 7px;
            vertical-align: middle;
            font-size: 18px;

            &:first-child {
              width: 38%;
              text-align: center;
            }
          }

          &:nth-child(odd) {
            .td {
              background: #e9efff;
            }
          }

          &.thead {
            .td {
              background: $mainColor;
              text-align: center;
              color: #fff;
              font-weight: bold;
              padding: 15px;
              font-size: 18px;
            }
          }

          .cells {
            font-weight: bold;
            color: #333;

            .cell {
              &:not(:first-child) {
                margin-top: 2px;
              }

              .red {
                color: #f43126;
              }
              .orange {
                color: #ff3800;
                font-size: 16px;
              }
            }
          }

          .time {
            font-weight: bold;
            color: #333;
          }
        }
      }
    }

    .tips {
      $height: 36px;

      .head {
        display: flex;
        align-items: center;
        font-weight: bold;
        color: #333;
        font-size: 16px;
        height: $height;

        .text {
          background: $mainColor;
          color: #fff;
          padding-left: 10px;
          height: 100%;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          line-height: 1;
        }

        &::after {
          content: "";
          display: block;
          width: 0;
          height: 0;
          border: $height solid transparent;
          border-left-color: $mainColor;
          border-bottom-width: 0;
          border-left-width: $height/2;
        }
      }

      .prompts {
        background: #e9efff;
        padding: 10px 8px;
        margin-top: 8px;
        color: $mainColor;
        font-size: 16px;
        border-radius: 4px;
        line-height: 1.5;

        .item {
          @include flex();

          &:not(:first-child) {
            margin-top: 2px;
          }

          .label {
            white-space: nowrap;
          }
        }
      }

      .list {
        padding-top: 15px;
        font-size: 16px;

        .item {
          display: flex;
          align-items: stretch;

          &:not(:first-child) {
            margin-top: 15px;
          }

          .label {
            position: relative;
            flex: none;
            background: #f60;
            width: 80px;
            font-weight: bold;
            color: #ad761a;
            background: #fdf4e4;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 4px;
            text-align: center;
            word-break: normal;

            &::after {
              content: "";
              position: absolute;
              right: -6px;
              top: 50%;
              width: 0;
              height: 0;
              border: 6px solid transparent;
              border-left-color: #fdf4e4;
              border-right-width: 0;
              margin-top: -3px;
            }
          }

          .content {
            flex: 1;
            margin-left: 13px;
            min-height: 60px;
            display: flex;
            align-items: center;
            background: #fdf4e4;
            padding: 10px;
            border-radius: 4px;
            color: #333;
          }
        }
      }
    }

    .concats {
      display: flex;
      justify-content: space-between;
      margin-top: 45px;
      font-size: 12px;

      .info {
        white-space: nowrap;

        .item {
          display: flex;
          align-items: center;
          font-weight: bold;
          color: #333;

          &:not(:first-child) {
            margin-top: 3px;
          }
        }

        .foot {
          margin-top: 15px;

          .el-image {
            height: 30px;
          }
        }
      }

      .qrcodes {
        .img {
          width: 70px;
          height: 70px;
          margin: 0 auto;
        }

        .text {
          text-align: center;
          color: #333;
        }
      }

      .logo {
        font-weight: bold;
        line-height: 1.2;
        color: #24357f;

        .el-image {
          height: 10px;
        }
      }
    }

    &.langs {
      letter-spacing: 1px;

      .trans {
        word-break: normal;
      }

      .drugs {
        .table {
          .trans {
            margin-top: -5px;
          }
        }
      }

      .tips {
        .prompts {
          margin-top: 12px;
          padding: 15px 10px;
          font-size: 14px;

          .item {
            &:not(:first-child) {
              margin-top: var(--margin-top);
            }
          }

          .trans {
            line-height: 1.3;
            margin-top: -5px;
          }
        }

        .list {
          font-size: 14px;

          .label {
            .trans {
              font-size: 12px;
              margin-top: -3px;
            }
          }

          .content {
            padding: 10px;

            .trans {
              line-height: 1.3;
              margin-top: -5px;
            }
          }
        }
      }
    }
  }

  .footer {
    margin-top: 20px;
    text-align: center;
  }
}
</style>

<style lang="scss">
.order-print-advice {
  .el-radio-button__inner {
    border-color: #e3edfe;
  }

  .el-radio-button__orig-radio:checked + .el-radio-button__inner {
    background-color: #e3edfe;
    border-color: #d8e5ff;
    color: #4690f7;
    box-shadow: none;
  }
}
</style>
