<template>
    <div class="print">
        <el-button :type="type" size="medium" class="margin-left-right-10px" @click="onPrescriptionPrint">批量打印处方</el-button>
        <el-dialog title="处方批量打印" :visible.sync="prescription.show" :close-on-click-modal="false" :close-on-press-escape="false" width="700px" top="10vh">
            <div class="prescription-print">
                <div class="prescription-print-title">处方数量：{{ prescription.list.length }}</div>
                <div class="prescription-print-body custom-scrollbar">
                    <div id="prescription-print-batch">
                        <div class="prescription-print-img" v-for="(itm, idx) in prescription.list" :key="idx">
                            <el-image :src="itm" />
                        </div>
                    </div>
                    <!-- <div id="prescription-print-batch">
                    <div class="prescription-print-item" v-for="(item, index) in prescription.list" :key="index">
                    <div class="prescription-print-item-cells">
                        <div class="prescription-print-item-cell info">
                        <div class="cell name">
                            <span class="span">{{item.userName}}</span>
                            <span class="span">&nbsp;{{item.sexStr}}</span>
                            <span class="span">&nbsp;{{item.age}}岁</span>
                        </div>
                        <div class="cell doctor">接诊医生：{{item.doctorName}}</div>
                        <div class="cell assistant">服务医助：{{item.doctorAssistantName}}</div>
                        </div>
                        <div class="prescription-print-item-cell">临床诊断：{{item.diagnose || '无'}}</div>
                        <div class="prescription-print-item-cell">医嘱：{{item.advice || '无'}}</div>
                        <div class="prescription-print-item-cell" v-for="(itm, idx) in item.doctorPrescriptionDetails" :key="idx">
                        <div class="prescription-print-item-cell-itm">{{itm.drugName}}</div>
                        <div class="prescription-print-item-cell-itm">{{itm.drugSpecifications}}</div>
                        <div class="prescription-print-item-cell-itm">{{itm.quantity}}{{itm.dosageUnitName}}</div>
                        <div class="prescription-print-item-cell-itm">{{itm.dosage}}{{itm.dosageUnitName}}</div>
                        <div class="prescription-print-item-cell-itm">{{itm.frequencyName}}</div>
                        <div class="prescription-print-item-cell-itm">{{itm.drugUsageName}}</div>
                        </div>
                    </div>
                    </div>
                </div> -->
                </div>
                <div class="prescription-print-foot">
                    <el-button type="primary" size="medium" @click="onPrescriptionPrintOpen">打印</el-button>
                </div>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import printJS from 'print-js'
import { watermarkImageCreate } from '@/directives/watermark'
export default {
    props: {
        printList: {
            type: Array,
            default: () => []
        },
        type: {
            type: String,
            default: 'success'
        }
    },
    data() {
        return {
            prescription: { // 处方打印
                show: false,
                list: []
            }
        }
    },
    methods: {
        async onPrescriptionPrint() { // 处方批量打印
            if (!this.printList.length) {
                this.$message({
                    message: '请先勾选处方',
                    type: 'warning'
                });
                return false;
            }
            let loading = this.$loading({
                lock: true,
                text: '拼命加载中...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            this.prescription.list = [];
            let nos = []
            this.printList.forEach((itm) => {
                if (itm.prescriptionNos || itm.prescriptionNo) nos = nos.concat((itm.prescriptionNos || itm.prescriptionNo).split(','))
            });
            await this.onPrescriptionPrintGet(nos.join(',')).then((imgs) => {
                this.prescription.list = imgs;
            }).catch(() => { })
            loading.close();
            if (!this.prescription.list.length) {
                this.$message({
                    message: '所选处方未生成处方图片',
                    type: 'error'
                });
            } else {
                this.prescription.show = true;
            }
        },
        onPrescriptionPrintGet(list) { // 处方批量打印-处方图片获取
            return new Promise((resolve, reject) => {
                this.$axios.get(this.$APIURL.PRESCRIPTION_DETAILBYNO_PLUS, {
                    params: {
                        prescriptionNoList: list
                    }
                }).then(async ({ data }) => {
                    if (data.code == 0 && data.data) {
                        const imgs = data.data.filter((itm) => !!itm.url).map((itm) => process.env.VUE_APP_PIC_SHOW + itm.url);
                        const res = [];
                        try {
                            for (let i = 0; i < imgs.length; i++) {
                                const { url } = await watermarkImageCreate(imgs[i]);
                                res.push(url);
                            }
                        } catch (error) {
                            
                        }
                        resolve(res);
                    } else {
                        reject(false);
                    }
                }).catch(() => {
                    reject(false);
                })
            })

        },
        onPrescriptionPrintOpen() { // 处方批量打印-打开
            printJS({
                printable: 'prescription-print-batch',
                type: 'html',
                targetStyles: ['*']
            })
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/base';
.prescription-print {
    &-body {
        max-height: 60vh;
        overflow-y: auto;
        padding-right: 5px;
        color: #000;
    }
    &-item {
        position: relative;
        border: 1px solid #ccc;
        padding: 5px 0 10px;
        border-radius: 5px;
        line-height: 1.8;
        margin-bottom: 15px;
        // page-break-after: always;
        &-cell {
            @include flex();
            margin-top: 10px;
            word-break: break-all;
            padding: 0 10px;
            &.info {
                .cell {
                    flex: 1;
                }
            }
            &-itm {
                white-space: nowrap;
                &:first-child {
                    width: 23%;
                }
                &:nth-child(2) {
                    width: 14%;
                }
                &:nth-child(3) {
                    width: 10%;
                }
                &:nth-child(4) {
                    width: 8%;
                }
                &:nth-child(5) {
                    width: 15%;
                }
                &:nth-child(6) {
                    flex: 1;
                }
            }
        }
    }
    .prescription-print-img {
        page-break-after: always;
        font-size: 0;
        text-align: center;
        .el-image {
            width: 100%;
            vertical-align: top;
        }
    }
    &-foot {
        margin-top: 20px;
        text-align: center;
    }
}
</style>
