import Vue from 'vue'

//导入格式化时间的插件
import moment from 'moment'
//定义全局过滤器
Vue.filter('dataFormate',function (dataStr,patten = "YYYY-MM-DD HH:mm") {
    return dataStr==null?"-":moment(dataStr).format(patten)
})

Vue.filter('sexFormate',function (sex) {
    return sex==1?'男':'女'
})

Vue.filter('nullFormate',function (str) {
    return str==''?'无':str==null?"无":str;
})
//格式化订单状态
Vue.filter('formateOrderStatus',function (status) {
    return status==0?"<span>待支付</span>":status==1?"已支付":status==2?"已取消":status==3?"已关闭":status==4?"待发货":status==5?"待签收":status==6?"已完成":status==7?"已退货":status==8?"已退款": status == 13 ? '待自取': status == 14 ? '待揽收' : "-"
})
//格式化订单类型
Vue.filter('formateOrderType',function (type) {
    return type==0?"非处方订单":type==1?"处方订单":"-"
})
//格式化发票类型
Vue.filter('formateInvoiceType',function (type) {
    return type==2?"纸质放票":type==1?"电子发票":''
})
//药品销售数据下的属性分类
Vue.filter('formateDrugType',function (type) {
    return type==1?"OTC甲类":type==3?"OTC乙类":type==2?"处方药":type==4?"营养保健":'-'
})
Vue.filter('dateFormate',function (str) {
    return str;
})
