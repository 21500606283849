<template>
    <div class="prescription-list">
        <el-dialog
            title="电子处方列表"
            :visible.sync="dialogVisible"
            width="800px"
            @closed="closePrescriptionListDialog">
            <span>
                <el-table
                        :data="prescriptions"
                        border
                        :header-cell-style="headerCellStyle"
                        style="width: 100%" v-watermark>
                    <el-table-column
                            prop="prescriptionNo"
                            label="处方编号"
                            align="center"
                            :show-overflow-tooltip="showOverflow"
                            width="250px"
                    >
                    </el-table-column>
                    <el-table-column
                            label="操作"
                            align="center"
                            :show-overflow-tooltip="showOverflow"
                    >
                        <template slot-scope="scope">
                            <el-button size="small" type="primary" v-show="scope.row.prescriptionNo" @click="lookPrescription(scope.row.waterUrl)" :disabled="!scope.row.waterUrl">查看电子处方</el-button>
                            &nbsp;
                            <el-button  class="el-icon-download" size="small" :disabled="true" v-if="!scope.row.url">处方（png）</el-button>
                            <a title="按住alt键点击按钮" v-show="scope.row.prescriptionNo" target="_blank" :href=picShow+scope.row.url v-else>
                                <el-button  class="el-icon-download" size="small">处方（png）</el-button>
                            </a>
                            &nbsp;
                            <el-button class="el-icon-download" size="small" :disabled="true" v-if="!scope.row.pdfUrl">处方（pdf）</el-button>
                            <a title="按住alt键点击按钮" v-show="scope.row.prescriptionNo" target="_blank" :href=picShow+scope.row.pdfUrl v-else>
                                <el-button class="el-icon-download" size="small">处方（pdf）</el-button>
                            </a>
                        </template>
                    </el-table-column>
                </el-table>
            </span>
        </el-dialog>
    </div>
</template>
<script>
    import { watermarkImageCreate } from '@/directives/watermark'
    export default {
        name: 'prescriptionList',
        data() {
            return {
                dialogVisible: true,
                showOverflow: true,
                picShow: this.$APIURL.PIC_SHOW,
                prescriptions: []
            }
        },
        props: ['prescriptionList'],
        mounted() {
            this.init();
        },
        methods:{
            async init() {
                this.prescriptions = await this.onPrescriptionInit();
            },
            onPrescriptionInit() {
                return new Promise(async (resolve) => {
                    const res = [];
                    const list = this.prescriptionList.filter((row) => row.url);
                    if (list.length) {
                        try {
                            for (let i = 0; i < list.length; i++) {
                                const { url } = await watermarkImageCreate(this.picShow + list[i].url);
                                const row = JSON.parse(JSON.stringify(list[i]));
                                row.waterUrl = url;
                                res.push(row);
                            }
                        } catch (error) {
                            
                        }
                    }
                    resolve(res);
                })
            },
            headerCellStyle({row,column,rowIndex,columnIdex}){
                let style = {background: '#f1f4fa',height:'0.45rem'}
                return style;
            },
            // 关闭对话框
            closePrescriptionListDialog() {
                this.$emit('closePrescriptionListDialog', false)
            },
            // 查看电子处方
            lookPrescription(url){
                let imgList = [url]
                this.$viewerApi({
                    images: imgList,
                    options: {
                        zIndex: 2222
                    }
                })
            }
        },
        watch: {
            prescriptionList: 'init'
        }
    }
</script>