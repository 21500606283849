import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
var store = new Vuex.Store({
    state:{
        //登录信息
        userInfo:{},
        //左边菜单高亮
        leftMenuActiveName:'/index/home',
        messageWebSocket: {
            ysPrescriptionRefresh: false, //药师端电子处方列表是否刷新
            ydOrderListRefresh: false, //药房端商品订单是否刷新
        }
    },
    mutations:{
        setUserInfo(state,userInfo){
            state.userInfo=userInfo;
            localStorage.setItem('userInfo',JSON.stringify(state.userInfo))
        },
        setLeftMenuActiveName(state,leftMenuActiveName){
            state.leftMenuActiveName=leftMenuActiveName
        },
        setMessageWebSocket(state, messageWebSocket){
            state.messageWebSocket = messageWebSocket
        }
    },
    getters:{
        //this.$store.getters.***
    }
})

export default store