<template>
    <div class="remarks">
        <div class="remarks-head">
            <div class="title">备注</div>
            <div class="btn">
                <el-button :type="edit.show ? 'info' : 'primary'" size="mini" @click="!edit.show ? onEditShow() : onEditHide()">{{edit.show ? '取消' : '添加'}}</el-button>
            </div>
        </div>
        <el-collapse-transition>
            <div class="remarks-edit" v-if="edit.show">
                <div class="edit-top">
                    <el-radio-group v-model="edit.visibility">
                        <el-radio :label="0">公开</el-radio>
                        <el-radio :label="1">私有</el-radio>
                    </el-radio-group>
                    <div class="input-box">
                        <el-input v-model="edit.content" placeholder="请输入备注内容（100字以内）" clearable :maxlength="100" show-word-limit @keyup.enter.native="onEditSubmit()"></el-input>
                        <el-button type="primary" size="medium" :loading="!edit.submit" plain @click="onEditSubmit()">确认</el-button>
                    </div>
                </div>
            </div>
        </el-collapse-transition>
        <div class="remarks-body">
            <div class="remarks-list">
                <div class="item" :class="{active: edit.id == item.id}" v-for="(item, index) in table.list" :key="index">
                    <div class="item-top">
                        <div class="name">
                            <span>{{item.refUserName}}{{table.roles[item.refUserNo.replace(/^([a-z]+)?(.*)?/i, '$1')]}}</span>
                            <span class="tag" :class="{private: item.visibility == 1}" v-if="item.refUserNo === edit.refUserNo">{{item.visibility == 1 ? '私有' : '公开'}}</span>
                        </div>
                        <div class="time">{{item.modifyTime || item.createTime}}</div>
                    </div>
                    <div class="item-bottom">
                        <div class="content">{{item.content}}</div>
                        <div class="more">
                            <el-dropdown placement="bottom-end" @command="onDropdown($event, item)" :disabled="item.refUserNo != edit.refUserNo">
                                <template #dropdown>
                                    <el-dropdown-menu>
                                        <el-dropdown-item command="edit">编辑</el-dropdown-item>
                                        <el-dropdown-item command="delete" @click="onDelete(item)">删除</el-dropdown-item>
                                        <el-dropdown-item command="private" :disabled="item.visibility == 1">设为仅自己可见</el-dropdown-item>
                                        <el-dropdown-item command="public" :disabled="item.visibility != 1">设为全部人可见</el-dropdown-item>
                                    </el-dropdown-menu>
                                </template>
                                <div class="more-box">
                                    <span class="el-icon el-icon-more"></span>
                                </div>
                            </el-dropdown>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "remarks",
    components: {

    },
    data() {
        return {
            table: {
                list: [],
                loading: false,
                roles: {
                    YS: '医生',
                    SHYS: '药师',
                    ZL: '医助',
                    YD: '药房'
                }
            },
            edit: {
                show: false,
                id: '',
                visibility: 0,
                content: '',
                submit: true,
                askNo: '',
                refUserNo: ''
            }
        }
    },
    mounted() {

    },
    methods: {
        init(row) {
            this.edit.askNo = row.askNo;
            this.edit.show = false;
            this.edit.refUserNo = this.$store.state.userInfo.refUserNo;
            this.onDataGet();
        },
        onDataGet() {
            this.table.loading = true;
            let send = {
                refNo: this.edit.askNo
            }
            this.$axios.get('/misc-service/remark/listRemarkByRefNo', {
                params: send
            }).then(({ data }) => {
                this.table.list = data.data;
                this.table.loading = false;
            }).catch(() => {
                this.table.loading = false;
            });
        },
        onDropdown(command, row) { // 下拉菜单点击指令
            switch (command) {
                case 'edit': // 编辑
                    this.onEditShow(row);
                    break;
                case 'delete': // 删除
                    this.onDelete(row);
                    break;
                case 'private': // 私有
                case 'public': // 公开
                    this.edit.id = row.id;
                    this.edit.visibility = command == 'private' ? 1 : 0;
                    this.onEditSubmit(true);
                    break;
            }
        },
        onEditShow(row) {
            if (!row) row = {};
            this.edit.visibility = 'visibility' in row ? row.visibility : 0;
            this.edit.content = row.content || '';
            this.edit.id = row.id || '';
            this.edit.show = true;
        },
        onEditHide() {
            this.edit.id = '';
            this.edit.show = false;
        },
        onEditSubmit(isPrivate) {
            if (!this.edit.submit) return false;
            if (!this.edit.content && !isPrivate) {
                this.$message.error('请填写备注')
                return false;
            }
            let send = {
                refNo: this.edit.askNo,
                visibility: this.edit.visibility
            }
            if (!isPrivate) {
                send.content = this.edit.content
            }

            this.edit.submit = false;

            this.$axios[this.edit.id ? 'put' : 'post'](this.edit.id ? '/misc-service/remark/modify/' + this.edit.id : '/misc-service/remark/create', send, {
                headers: {
                    'content-type': 'application/json'
                }
            }).then(({ data }) => {
                if (data.code == 0) {
                    this.$message.success(this.edit.id ? '修改成功' : '添加成功')
                    this.onEditHide();
                    this.onDataGet();
                } else {
                    this.$message.error(data.message)
                }
                this.edit.submit = true;
            }).catch(() => {
                this.$message.error('操作失败')
                this.edit.submit = true;
            });
        },
        onDelete(row) {
            this.$confirm('是否确认删除备注?', '', {
                confirmButtonText: '确认',
                cancelButtonText: '取消',
                type: 'warning',
                closeOnClickModal: false
            }).then(() => {
                this.$axios.delete('/misc-service/remark/delete/' + row.id).then(({ data }) => {
                    if (data.code == 0) {
                        this.$message.success('删除成功');
                        this.onEditHide();
                        this.onDataGet();
                    } else {
                        this.$message.error(data.message);
                    }
                }).catch(({ data }) => {
                    this.$message.error(data.message);
                });
            }).catch(({ data }) => {
                this.$message.error(data.message);
            });
        }
    }
}
</script>

<style scoped lang="scss">
@import "@/assets/css/base";

.remarks {
    padding: 15px;

    &-head {
        @include flex(center, space-between);

        .title {
            // font-weight: bold;
            color: #838383;
        }
    }

    &-edit {
        margin-top: 5px;

        .el-radio {
            margin-right: 10px;
        }

        .input-box {
            @include flex(center, space-between);
            margin-top: 10px;

            .el-button {
                margin-left: 10px;
            }
        }
    }

    &-body {
        margin-top: 15px;

        .item {
            position: relative;
            padding-left: 20px;
            padding-bottom: 15px;

            &::before,
            &::after {
                content: "";
                position: absolute;
                top: 5px;
            }

            &::before {
                left: 0;
                z-index: 2;
                width: 8px;
                height: 8px;
                border-radius: 8px;
                background: #d9d9d9;
            }

            &::after {
                bottom: -5px;
                left: 4px;
                width: 1px;
                background: #d9d9d9;
            }

            &:last-child {
                &::after {
                    display: none;
                }
            }

            &.active {
                &::before {
                    background: var(--color-main);
                }
            }

            &-top {
                @include flex(center, space-between);
                color: #797979;

                .tag {
                    color: #3ec73a;
                    background: #ecffe0;
                    padding: 0 5px;
                    margin-left: 10px;

                    &.private {
                        color: #3388f9;
                        background: #ebf8fb;
                    }
                }
            }

            &-bottom {
                margin-top: 5px;
                @include flex(center, space-between);

                .content {
                    word-break: break-all;
                }

                .more {
                    flex: none;
                    margin-left: 20px;

                    .el-icon {
                        transform: rotate(90deg);
                        font-size: 13px;
                    }

                    &-list {
                        margin-left: -10px;
                        margin-top: -10px;
                        margin-right: -10px;
                        margin-bottom: -10px;

                        .more-item {
                            padding: 10px 15px;
                            cursor: pointer;

                            &:hover {
                                background: #f5f5f5;
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>
