<template>
    <el-table
            :data="esstialInformation"
            border
            :header-cell-style="headerCellStyle"
            style="width: 100%">
        <el-table-column
                prop="orderNo"
                label="订单编号"
                align="center"
                :show-overflow-tooltip="showOverflow"
        >
        </el-table-column>
        <el-table-column
                prop="drugStoreName"
                label="药品供应商"
                align="center"
                :show-overflow-tooltip="showOverflow"
        >
        </el-table-column>
        <el-table-column
                prop="shippingCompName"
                align="center"
                :show-overflow-tooltip="showOverflow"
                label="配送方式">
        </el-table-column>
        <el-table-column
                prop="logisticsNo"
                align="center"
                :show-overflow-tooltip="showOverflow"
                label="运单号">
        </el-table-column>
        <el-table-column
                prop="couponName"
                align="center"
                :show-overflow-tooltip="showOverflow"
                label="优惠活动信息">
        </el-table-column>
    </el-table>
</template>

<script>
    export default {
        name: "esstialInformationTable",
        data(){
            return {
                showOverflow:true
            }
        },
        props:['esstialInformation'],
        props:{
            esstialInformation:{
                default:3
            }
        },
        methods:{
            headerCellStyle({row,column,rowIndex,columnIdex}){
                let style = {background: '#f1f4fa',height:'0.45rem'}
                return style;
            },
        }
    }
</script>

<style scoped>

</style>