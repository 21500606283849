<template>
    <el-col :span="12">
        <el-menu
                :default-active="activeName"
                :router="isRouter"
                class="el-menu-vertical-demo"
                background-color="#545c64"
                text-color="#fff"
                active-text-color="#ffd04b">
            <el-menu-item index="/index/home">
                <i class="el-icon-s-home"></i>
                <span slot="title">首页</span>
            </el-menu-item>
    <el-menu-item v-for="item of menuData" :key="item.title" :index="item.href">
        <template slot="title" >
            <i :class="item.icon"></i>
            <span v-text="item.title"></span>
        </template>
    </el-menu-item>

        </el-menu>
    </el-col>
</template>

<script>
    export default {
        name: "leftMenu",
        data(){
            return {
                uniqueOpened:true,
                isRouter:true,
                menuData:[],
            }
        },
        computed:{
            activeName(){
                return this.$store.state.leftMenuActiveName
            },
            userInfo(){
                return this.$store.state.userInfo;
            }
        },
        created(){
            console.log("++++")
            console.log(this.userInfo.menuTrees.childs[0].childs)
            this.menuData=this.userInfo.menuTrees.childs[0].childs
        },
        watch:{
            "$route.path":function (newVal,oldVla) {
                this.$store.commit('setLeftMenuActiveName',newVal)
                this.activeName=newVal
            }
        }
    }
</script>

<style scoped lang="scss">
    .el-col-12{
        width: 100%;
        height: 100%;
    }
    .el-menu{
        width: 100%;
        height: 100%;
        background: white !important;
        font-size: 0.18rem !important;
        border-right: none;
        &>.el-menu-item:hover{
            &>span{
                color: #2562dc;
            }
        }
        .el-menu-item:nth-child(1){
           margin-top: 0.44rem;
        }
        /*&>.el-menu-item:nth-child(1){
            &>i{
                background: url("/lib/img/leftbar_patient.png") center no-repeat;
                background-size: cover;
            }
        }
        &>.el-menu-item:nth-child(1):hover{
            &>i{
                background: url("/lib/img/leftbar_patient_on.png") center no-repeat;
                background-size: cover;
            }
        }
        &>.el-menu-item:nth-child(2){
            &>i{
                background: url("/lib/img/leftbar_medical.png") center no-repeat;
                background-size: cover;
            }
        }
        &>.el-menu-item:nth-child(2):hover{
            &>i{
                background: url("/lib/img/leftbar_medical_on.png") center no-repeat;
                background-size: cover;
            }
        }
        &>.el-menu-item:nth-child(3){
            &>i{
                background: url("/lib/img/leftbar_recipe.png") center no-repeat;
                background-size: cover;
            }
        }
        &>.el-menu-item:nth-child(3):hover{
            &>i{
                background: url("/lib/img/leftbar_recipe_on.png") center no-repeat;
                background-size: cover;
            }
        }
        &>.el-menu-item:nth-child(4){
            &>i{
                background: url("/lib/img/leftbar_otc.png") center no-repeat;
                background-size: cover;
            }
        }
        &>.el-menu-item:nth-child(4):hover{
            &>i{
                background: url("/lib/img/leftbar_otc_on.png") center no-repeat;
                background-size: cover;
            }
        }
        &>.el-menu-item:nth-child(5){
            &>i{
                background: url("/lib/img/leftbar_data.png") center no-repeat;
                background-size: cover;
            }
        }
        &>.el-menu-item:nth-child(5):hover{
            &>i{
                background: url("/lib/img/leftbar_data_on.png") center no-repeat;
                background-size: cover;
            }
        }
        &>.el-menu-item:nth-child(6){
            &>i{
                background: url("/lib/img/leftbar_store.png") center no-repeat;
                background-size: cover;
            }
        }
        &>.el-menu-item:nth-child(6):hover{
            &>i{
                background: url("/lib/img/leftbar_store_on.png") center no-repeat;
                background-size: cover;
            }
        }*/
    }
    .el-menu-item{
        color: #687788 !important;
        background: white !important;
        font-size: 0.16rem !important;
        margin-left: 0.24rem;
        padding: 0 !important;
        &>i{
            display: inline-block;
           /* width: 0.32rem;
            height: 0.24rem;*/
            font-size: 0.2rem;
        }

    }
    .el-menu-item.is-active{
        color: #2565dc !important;
        font-size: 0.16rem !important;
    }
    /*.el-menu-item:nth-child(1).is-active{
        &>i{
            background: url("/lib/img/leftbar_patient_on.png") center no-repeat;
            background-size: cover;
        }
    }
    .el-menu-item:nth-child(2).is-active{
        &>i{
            background: url("/lib/img/leftbar_medical_on.png") center no-repeat;
            background-size: cover;
        }
    }
    .el-menu-item:nth-child(3).is-active{
        &>i{
            background: url("/lib/img/leftbar_recipe_on.png") center no-repeat;
            background-size: cover;
        }
    }
    .el-menu-item:nth-child(4).is-active{
        &>i{
            background: url("/lib/img/leftbar_otc_on.png") center no-repeat;
            background-size: cover;
        }
    }
    .el-menu-item:nth-child(5).is-active{
        &>i{
            background: url("/lib/img/leftbar_data_on.png") center no-repeat;
            background-size: cover;
        }
    }
    .el-menu-item:nth-child(6).is-active{
        &>i{
            background: url("/lib/img/leftbar_store_on.png") center no-repeat;
            background-size: cover;
        }
    }*/

</style>
