import VueRouter from 'vue-router'
import Vue from 'vue'

//导入路由组件
import login from './componets/login.vue'
import index from './componets/index.vue'
import commodityIndex from './componets/commodityManagement/commodityIndex.vue'
import commodityOrdersIndex from './componets/commodityOrders/commodityOrdersIndex.vue'
import dataStatisticsIndex from './componets/dataStatistics/dataStatisticsIndex.vue'
import home from './componets/home/home.vue'
import myPrescriptionIndex from './componets/myPrescription/myPrescriptionIndex.vue'

//3、创建路由对象
var router = new VueRouter({
    // mode: 'history',
    routes:[
        {path:'/',component:login},
        {path:'/login',component:login},
        {path:'/index',component:index,
            children:[
                {path:'commodityIndex',component:commodityIndex},
                {path:'commodityOrdersIndex',component:commodityOrdersIndex},
                {path:'dataStatisticsIndex',component:dataStatisticsIndex},
                {path:'home',component:home},
                {path:'myPrescriptionIndex',component:myPrescriptionIndex},

            ]},
    ],
    //linkActiveClass:'mui-active' //覆盖默认的路由高亮的类，默认的类叫做router-link-active
})
router.beforeEach((to,from,next) => {
    var userToken = sessionStorage.getItem('userToken');
    if(userToken){
        if(to.matched.length ==0){//如果未匹配到路由
            next({path:'/index'});
            return;
        }
        // 监听用户长时间不操作后自动退出登录
        let currentTime = new Date().getTime(),
            lastTime = new Date().getTime()
        // 设置自动退出时长（30分钟）
        const timeout = 1000 * 60 * 60 * 8;
        window.document.onmousedown = function () {
            lastTime = new Date().getTime();
        }
        window.document.onkeypress = function () {
            lastTime = new Date().getTime();
        }
        clearInterval(Vue.prototype.$loginTimer);
        Vue.prototype.$loginTimer = setInterval(function () {
            currentTime = new Date().getTime();
            if (currentTime - lastTime > timeout) {
                //  清除登录用户信息
                sessionStorage.removeItem("userToken");
                clearInterval(Vue.prototype.$loginTimer);
                Vue.prototype.$alert('您长时间未有操作，已自动退出登录', '提示', {
                    confirmButtonText: '确定',
                    callback: action => next({path:'/index/saasLogin'})
                });
            }
        }, 3000);
        next();
        return;
        console.log(to.matched.length)
    }else{
        //防止无限循环
        if (to.fullPath == '/login') {
            next();
            return
        }
        next({path:'/login'});
    }
})
export default router