
import hexMD5 from './utilMd5.js'
  // md5加密
  function czParms(pams) {
      //组成待验证加密数组
      let eidtionTypeList = [];
      Object.keys(pams).forEach(function(key){
          eidtionTypeList.push({
              key: key,
              val: pams[key],
          })
      });
      console.log("数组",eidtionTypeList)
      eidtionTypeList.sort(sortkey)
      return dataEncrypt(eidtionTypeList)

  }

  // 按参数key按字母从小到大排序
  function sortkey(val1, val2) {
    // 转换
    val1 = val1.key;
    val2 = val2.key;
    //console.log("val1",val1.charCodeAt(0),'val2',val2.charCodeAt(0))
    // 获取较长元素的长度
    var length = val1.length < val2.length ? val1.length : val2.length;
    // 依次比较字母的unicode码，相等时返回0，小于时返回-1，大于时返回1
      var i;
    for (i = 0; i < length; i++) {
      var differ = val1.charCodeAt(i) - val2.charCodeAt(i);
      if (differ == 0) {
        continue;
      } else {
        if (val1.charAt(i) == '_') {
          return -1;
        }
        return differ;
      }
    }
    if (i == length) {
      return val1.length - val2.length;
    }
  }
  var appSecret=process.env.VUE_APP_APPSECRET

  //md5数据加密
  function dataEncrypt(eidtionTypeList) {
    //key排序后的值拼接.
    var _key = "";
    _key += appSecret;
    //对_key的val值拼接(空值跳过)
    for (var i = 0; i < eidtionTypeList.length; i++) {
      if (eidtionTypeList[i].val === "" || eidtionTypeList[i].val === null || eidtionTypeList[i].val===undefined) continue;
      //console.log(eidtionTypeList[i].val)
      _key += eidtionTypeList[i].key + eidtionTypeList[i].val;
    }
    //console.log("8888",_key)
    _key  += appSecret;
    console.log("未decode",_key);
    // console.log("decode",decodeURIComponent(_key));
    // var sign = hexMD5(decodeURIComponent(_key));
    //var sign = md5(decodeURI(_key));
    var sign = hexMD5(decodeURIComponent(encodeSearchKey(_key)));
    console.log("sign----"+sign)
    return sign;
  }

  // 对查询关键字中的特殊字符进行编码
  function encodeSearchKey(key) {
    const encodeArr = [{
      code: '%',
      encode: '%25'
      }, {
      code: '?',
      encode: '%3F'
      }, {
      code: '#',
      encode: '%23'
      }, {
      code: '&',
      encode: '%26'
      }, {
      code: '=',
      encode: '%3D'
    }];
    return key.replace(/[%?#&=]/g, ($, index, str) => {
      for (const k of encodeArr) {
        if (k.code === $) {
        return k.encode;
        }
      }
    });
  }



export default czParms
