import store from '@/common/vuex'

export const watermarkDirective = {
    inserted: (el, binding) => {
        onCreate(el, binding.value || {})
    }
}

// 水印缓存
const watermarkCache = [];

// 节点创建水印背景
const onCreate = async (el, options = {}) => {
    const isElTable = el.classList.contains('el-table');
    if (isElTable) {
        const wrapper = el.querySelector('.el-table__body');
        if (wrapper) el = wrapper;
    }
    try {
        const { watermarkUrl } = await onWatermarkCreate(options);
        el.setAttribute('style', `
            background-image: url(${watermarkUrl});
            background-position:  0 0;
            background-repeat: repeat;
            --el-table-tr-bg-color: transparent;
        `);
        if (!isElTable) {
            const tables = el.querySelectorAll('.el-table');
            if (tables.length) {
                tables.forEach((table) => {
                    table.setAttribute('style', `
                        --el-table-tr-bg-color: transparent;
                        --el-table-bg-color: transparent;
                    `)
                })
            }
        }
        if (!isElTable && options.isMutationObserver) {
            onMutationObserver(el, options)
        }
    } catch (error) {

    }
}

// 水印创建
const onWatermarkCreate = ({ content = '', rotate = -30, color = 'rgba(0, 0, 0, .07)', fontSize = 20, fontFamily = 'SimSun', padding = '0' }) => {
    return new Promise((resolve, reject) => {
        const defaultContent = onContentDefault();
        const find = watermarkCache.find((row) => row.content === content && row.rotate === rotate && row.color === color && row.fontSize === fontSize && row.fontFamily === fontFamily && row.padding === padding && !(!content && row.defaultContent !== defaultContent));
        if (find && find.url) {
            resolve({
                watermarkUrl: find.url
            });
        } else {
            const canvas = document.createElement('canvas');
            let text = content;
            if (!text) text = defaultContent;
            const { watermarkWidth, watermarkHeight } = onWatermarkSizeGet({ content: text, fontSize, fontFamily, padding, rotate });
            const angle = rotate * (Math.PI / 180);
            canvas.width = watermarkWidth;
            canvas.height = watermarkHeight;
            const centerX = canvas.width / 2;
            const centerY = canvas.height / 2;
            const ctx = canvas.getContext('2d');
            if (ctx) {
                ctx.fillStyle = color;
                ctx.font = `${fontSize}px ${fontFamily}`;
                ctx.textAlign = 'center';
                ctx.textBaseline = 'middle';
                ctx.translate(centerX, centerY);
                ctx.rotate(angle);
                ctx.fillText(text, 0, 0);
                const watermarkUrl = canvas.toDataURL();
                watermarkCache.unshift({
                    url: watermarkUrl,
                    content,
                    defaultContent,
                    rotate,
                    color,
                    fontSize,
                    fontFamily,
                    padding
                })
                resolve({
                    watermarkUrl
                });
            } else {
                reject(false);
            }
        }
    })
}

/**
 * 图片合成水印
 * @param img 图片链接
 * @param {*} { content: 水印内容, rotate：水印旋转角度, color：水印颜色，fontSize：水印字体大小，fontFamily：水印字体，padding：水印边距 }
 * @returns {*} { url: 图片地址 }
 */
export const watermarkImageCreate = (img, options = {}) => {
    return new Promise(async (resolve, reject) => {
        try {
            const { watermarkUrl } = await onWatermarkCreate(options);
            const image = await onImageLoad(img);
            const watermarkImg = await onImageLoad(watermarkUrl);
            const canvas = document.createElement('canvas');
            canvas.width = image.width;
            canvas.height = image.height;
            const ctx = canvas.getContext('2d');
            let totalWidth = 0, totalHeight = 0;
            if (ctx) {
                ctx.drawImage(image, 0, 0);
                while (totalHeight < image.height) {
                    totalWidth = 0;
                    while (totalWidth < image.width) {
                        ctx.drawImage(watermarkImg, totalWidth, totalHeight);
                        totalWidth += watermarkImg.width;
                    }
                    totalHeight += watermarkImg.height;
                }
            }
            resolve({
                url: canvas.toDataURL()
            })
        } catch (error) {
            reject(false);
        }
    })
}

// 水印图片获取
export const watermarkImageGet = (options = {}) => {
    return onWatermarkCreate(options);
}

// 水印尺寸获取
const onWatermarkSizeGet = ({ content = '', fontSize, fontFamily, padding, rotate = 0 }) => {
    const div = document.createElement('div');
    div.innerText = content;
    div.setAttribute('style', `
        position: fixed;
        left: -100%;
        top: -100%;
        opacity: 0;
        display: inline-block;
        pointer-events: none;
        font: ${fontSize}px ${fontFamily};
        padding: ${padding};
    `);
    document.body.appendChild(div);
    const offsetWidth = div.offsetWidth;
    const offsetHeight = div.offsetHeight;
    document.body.removeChild(div);
    const angle = rotate * (Math.PI / 180);
    return {
        offsetWidth,
        offsetHeight,
        watermarkWidth: Math.abs(offsetWidth * Math.cos(angle)) + Math.abs(offsetHeight * Math.sin(angle)),
        watermarkHeight: Math.abs(offsetWidth * Math.sin(angle)) + Math.abs(offsetHeight * Math.cos(angle))
    }
}

// 加载图片
const onImageLoad = (url) => {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.setAttribute('crossOrigin', 'Anonymous');
        img.onload = () => {
            resolve(img);
        }
        img.onerror = () => {
            reject(false);
        }
        img.src = url;
    })
}

// 监听元素属性变化
const onMutationObserver = (el, value) => {
    const mutationObserver = new MutationObserver((mutationsList) => {
        for (let mutation of mutationsList) {
            if (mutation.target === el) {
                mutationObserver?.disconnect();
                onCreate(el, value);
            }
        }
    });
    mutationObserver.observe(el, {
        attributes: true
    });
}

// 默认内容
const onContentDefault = () => {
    const name = store.state.userInfo.username || '';
    const date = new Date();
    return `${name} ${date.getFullYear()}${(date.getMonth() + 1).toString().padStart(2, '0')}${date.getDate().toString().padStart(2, '0')}`;
}