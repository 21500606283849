<template>
  <el-dialog custom-class="dialogClass" @closed="closed" :visible.sync="dialogVisible" :modal-append-to-body="false">
    <div id="print_lable_detail">
      <div class="tag-list">
        <div class="tag-item print_lable_item" v-for="item in printLableList" v-bind:key="item.orderNo">
          <div class="user-name">{{ item.userMemberName }}</div>
          <div class="drug-name">{{ item.drugName }}</div>
          <div class="drug-info">
            {{ `每次${item.dosage}${item.dosageUnitName} ${item.frequencyName}` }}
            <span>&nbsp;</span>
            <div>{{ item.drugUsageName }}</div>
          </div>
        </div>
      </div>
    </div>
    <div slot="title" class="dialog-footer">
      <el-button plain @click="closed">取 消</el-button>
      <el-button type="primary" @click="doPrint">打 印</el-button>
    </div>
  </el-dialog>
</template>

<script>

export default {
  name: "printLableDialog",
  data() {
    return {
      dialogVisible: true,
    };
  },
  created() {
    console.log("printLableDialog");
  },
  methods: {
    closed() {
      this.$emit("closePrintLableDialog", false);
    },
    doPrint() {
      this.$emit("doPrintLableDetail", false);
    },
    closeDialog(data) {
      this.showPrescriptionDialog = data;
    },
    returnGoods() {
      this.showReturnGoodsDialog = true;
    },
  },
  prop: ["printLableList"],
  props: {
    printLableList: [],
  },
  components: {
  },
};
</script>

<style scoped lang="scss">
@import "../../../../public/print4030.css";

#print_lable_detail {


.tag-list {
  color: #333;
  font-weight: bold;
  text-align: center;
  max-height: 80vh;
  overflow-y: auto;

  .tag-item {
    &+.tag-item {
      margin-top: 10px;
    }

    .user-name {
      font-size: 16px;
      padding: 10px 0;
      border-bottom: 2px solid #666;
    }

    .drug-name {
      padding: 8px 0;
      font-size: 18px;
    }

    .drug-info {
      font-size: 14px;
    }
  }
}
}

</style>
