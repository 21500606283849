<template>
  <div>

    <el-dialog
      :visible.sync="dialogVisible"
      @closed="closed"
      center
      width="450px">
      <div style="text-align:center;font-size:16px">共计  {{ total }} 张待审核处方进行批量审核</div>
      <div v-if="flag" style="margin-top: 30px;display: flex;justify-content: center;">
        <el-progress type="dashboard" :percentage="percentage" ></el-progress>
        </div>
      <div slot="footer" class="dialog-footer">
        <el-button plain @click="closed">取 消</el-button>
        <el-button type="success" @click="examineClick('6')">审核通过</el-button>
      </div>
    </el-dialog>

    <autographDialog @handleSign="handleSign" @closeDialog="closeDialog" v-if="autographDialogFlag"></autographDialog>
  </div>
</template>

<script>
import moment from "moment";
import autographDialog from './autographDialog.vue'
export default {
  name: "batchPrescriptionAuditDialog",
  data() {
    return {
      examineStatus: 0,
      percentage:0,
      formLabelWidth: "120px",
      dialogFormVisible: false,
      form: {
        name: "",
      },
      dialogVisible: true,
      signDialogVisible: false,
      sendPrescriptionParm: {
        prescription: {
          prescriptionSource: 1,
          askNo: "",
          userNo: "",
          userName: "",
          diagnose: "",
          doctorNo: "",
          doctorName: "",
          sex: "",
          age: "",
          idCard: "",
          deptName: "",
        },
        prescriptionDetail: [],
      },
      closeOnClickModal: false,
      sendData: {
        messageType: "",
        text: "",
        askNo: "",
        image: "",
        payload: {},
      },

      autographDialogFlag: false, //控制签名对话框展示与隐藏
      flag: false,

      total: 0
    };
  },
  props: {
    presList: {
      type: Array,
    },
  },
  computed: {
    pdfUrlChange() {
      return process.env.VUE_APP_PIC_SHOW + this.pdfUrl
    }
  },
  // watch: {
  //   pdfUrl(val, oldVal){
  //     console.log(this.val)
  //     this.pdfUrl = process.env.VUE_APP_APP_HOST + val
  //   }
  // },
  components: {
      autographDialog
  },
  created() {
    this.presList.forEach(item => {
      this.total = this.total + item.prescriptionNos.split(',').length
    })
    this.examineStatus = localStorage.getItem("examineStatus");
    //console.log(this.prescriptionData,"-----")
  },
  mounted() {},
  methods: {
    closed() {
      this.$emit("closeDialog", false);
    },
    examineClick(examineNum) {
      this.$emit('examineClick')
      return
      if (examineNum == 5) {
        this.dialogFormVisible = !this.dialogFormVisible;
      } else if (examineNum == 6) {
        if(this.flag) return
        this.autographDialogFlag = true
      }
    },
    confirmClick() {
      this.autographDialogFlag = true
      this.signDialogVisible = !this.signDialogVisible;
    },
    curmClick: async function(pharmacistAutographUrl) {
      if(this.flag) return
      this.flag = true

      let loading = this.$loading({
          lock: true,
          text: '批量审核处方中……',
          spinner: 'el-icon-loading',
          customClass: 'loading-content',
          background: 'rgba(255, 255, 255, 0.5)'
        });
      this.percentage = 0;
      for (let i = 0; i < this.presList.length; i++) {
        await this.doAuditPres(this.presList[i].prescriptionNo, pharmacistAutographUrl);
        this.percentage = Math.ceil(i * 100 / this.presList.length);
      }
      this.percentage = 100;
      this.$emit("examineClick", false);
      this.flag = false;
      loading.close();
    },
    doAuditPres: async function(prescriptionNo, pharmacistAutographUrl){
      let datar = {
        pharmacistAutographUrl: pharmacistAutographUrl,
        prescriptionNo: prescriptionNo,
        prescriptionStatus: 6,
        remark: this.form.name,
      };
      await this.$axios({
        method: "POST",
        url: this.$APIURL.DRUGINFOS_AUDITPRESCRIPTION,
        data: this.$qs.stringify(datar),
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      })
      .then((res) => {
        if (res.data.code == 0) {
          this.$respone.success("处方编号：" + prescriptionNo + "操作成功，审核通过");
        } else {
          this.$respone.error(res.data.message);
        }
      })
      .catch((err) => {
        _this.$respone.error("发生异常");
      });
    },
    handleSign(img){
      this.autographDialogFlag = false
      this.curmClick(img)
    },
    closeDialog(flag){
        this.autographDialogFlag = flag
    }
  },
};
</script>

<style scoped lang="scss">
::v-deep .content1 {
  & > div {
    color: #333333;
    margin-top: 33px;
    font-size: 14px;
    display: flex;
    align-items: flex-end;
    span {
      display: inline-block;
    }
  }
}

.dialog-div-span {
  text-align: center;
}
</style>
