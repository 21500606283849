<template>
  <el-dialog :title="`${addEditData.id ? '修改药品' : '录入药品'}`" :visible.sync="show" width="65%" @close="onClose" center>
    <div v-watermark>
      <div style="margin-bottom: 18px; font-weight: bold; font-size: 18px">
        药品信息
      </div>
      <el-form :model="addEditData" ref="formRef" label-width="110px">
        <el-form-item label="药品类型" prop="specificationType" :rules="{
          required: true,
          message: '请选择药品类型',
        }">
          <el-radio-group v-model="addEditData.specificationType" @change="onChangeType">
            <el-radio :label="1">新规格</el-radio>
            <el-radio :label="0">旧规格</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-if="addEditData.specificationType && !addEditData.id" label="HIS编码" prop="thirdDrugNo" :rules="{
          required: true,
          message: '请输入HIS编码',
        }">
          <el-input v-model="addEditData.thirdDrugNo" placeholder="请输入HIS编码" clearable maxlength="64" />
        </el-form-item>
        <el-form-item label="药品名称" prop="tradeName" :rules="{
          required: true,
          message: '请输入药品名称',
        }">
          <el-input v-model="addEditData.tradeName" placeholder="请输入药品名称" clearable maxlength="64" />
        </el-form-item>
        <el-form-item label="拼音码" prop="pinyinCode">
          <el-input v-model="addEditData.pinyinCode" placeholder="请输入拼音码" clearable maxlength="64" />
        </el-form-item>
        <el-form-item label="包装规格" prop="drugSpecifications" :rules="{
          required: true,
          message: '请输入包装规格',
        }">
          <el-input v-model="addEditData.drugSpecifications" placeholder="请输入包装规格" clearable maxlength="64" />
        </el-form-item>
        <div style="display: flex">
          <el-form-item label="包装单位" prop="packingUnit" style="flex: 1" :rules="{
            required: true,
            message: '请输入包装单位',
          }">
            <el-input v-model="addEditData.packingUnit" placeholder="请输入包装单位" clearable maxlength="12" />
          </el-form-item>
          <el-form-item label="最小单位" prop="dosageUnit" style="flex: 1" :rules="{
            required: true,
            message: '请选择最小单位',
          }">
            <el-select v-model="addEditData.dosageUnit" placeholder="请选择">
              <el-option v-for="item in unitList" :key="item.key" :label="item.value" :value="item.key" />
            </el-select>
          </el-form-item>
          <el-form-item label="包装转换比例" prop="packingScale" style="flex: 1" v-if="addEditData.specificationType">
            <el-input-number v-model="addEditData.packingScale" :min="1" :precision="0" label="请输入" />
            {{
              addEditData.packingUnit && addEditData.dosageUnit
              ? `${getDosageUnitLable}/${addEditData.packingUnit}`
              : ""
            }}
          </el-form-item>
        </div>
        <div style="display: flex">
          <el-form-item label="批准文号" prop="approvalNumber" style="flex: 1">
            <el-input v-model="addEditData.approvalNumber" placeholder="请输入批准文号" clearable maxlength="128" />
          </el-form-item>
          <el-form-item label="是否以包装规格销售" prop="isPackingSale" style="flex: 1" label-width="160px"
            v-if="addEditData.specificationType">
            <el-radio-group v-model="addEditData.isPackingSale">
              <el-radio :label="1">是</el-radio>
              <el-radio :label="0">否</el-radio>
            </el-radio-group>
          </el-form-item>
        </div>
        <div style="display: flex">
          <el-form-item label="生产厂家" prop="manufacturer" style="flex: 3" :rules="{
            required: true,
            message: '请输入生产厂家',
          }">
            <el-input v-model="addEditData.manufacturer" placeholder="请输入生产厂家" clearable maxlength="64" />
          </el-form-item>
          <el-form-item label="有效期" prop="termOfValidity" style="flex: 2" :rules="{
            required: true,
            message: '请输入有效期',
          }">
            <el-input-number v-model="addEditData.termOfValidity" :min="1" :precision="0" label="请输入" />
            月
          </el-form-item>
        </div>
        <el-form-item label="属性分类" prop="drugType">
          <el-select v-model="addEditData.drugType" placeholder="请选择" disabled>
            <el-option v-for="item in drugTypeList" :key="item.key" :label="item.value" :value="item.key" />
          </el-select>
        </el-form-item>
        <div style="display: flex">
          <el-form-item label="单次用量" prop="dosage" style="flex: 1" :rules="{
            required: true,
            message: '请选择单次用量',
          }">
            <el-input-number v-model="addEditData.dosage" :precision="0" :min="1" label="请输入" />
            {{ `${addEditData.dosageUnit ? getDosageUnitLable : ""}` }}
          </el-form-item>
          <el-form-item label="频次" prop="frequency" style="flex: 1" :rules="{
            required: true,
            message: '请选择频次',
          }">
            <el-select v-model="addEditData.frequency" placeholder="请选择">
              <el-option v-for="item in frequencyList" :key="item.key" :label="item.value" :value="item.key" />
            </el-select>
          </el-form-item>
          <el-form-item label="用法" prop="drugUsage" style="flex: 1" :rules="{
            required: true,
            message: '请选择用法',
          }">
            <el-select v-model="addEditData.drugUsage" placeholder="请选择">
              <el-option v-for="item in usAgeList" :key="item.key" :label="item.value" :value="item.key" />
            </el-select>
          </el-form-item>
        </div>
        <el-form-item label="不良反应" prop="adverseReaction">
          <el-input v-model="addEditData.adverseReaction" placeholder="请输入" clearable maxlength="256" type="textarea" />
        </el-form-item>
        <el-form-item label="禁忌" prop="contraindication">
          <el-input v-model="addEditData.contraindication" placeholder="请输入" clearable maxlength="256" type="textarea" />
        </el-form-item>
        <el-form-item label="贮藏" prop="storage">
          <el-input v-model="addEditData.storage" placeholder="请输入" clearable maxlength="256" type="textarea" />
        </el-form-item>
        <el-form-item label="注意事项" prop="note">
          <el-input v-model="addEditData.note" placeholder="请输入" clearable maxlength="256" type="textarea" />
        </el-form-item>
        <el-form-item label="小程序显示" prop="showApplet">
          <el-radio-group v-model="addEditData.showApplet">
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="药品图片" prop="drugPic">
          <UploadImgs :fileStr.sync="addEditData.drugPic" :limit="1" />
        </el-form-item>
        <el-form-item label="说明书" prop="drugDesc">
          <UploadImgs :fileStr.sync="addEditData.drugDesc" :limit="1" />
        </el-form-item>
        <div style="margin-bottom: 18px; font-weight: bold; font-size: 18px">
          价格信息
        </div>
        <el-form-item label="最小单位售价" prop="price" :rules="{
          required: true,
          message: '请输入最小单位售价',
        }">
          <el-input-number v-model="addEditData.price" :precision="2" :min="0" label="请输入" />
          元{{ addEditData.price ? `/${getDosageUnitLable}` : "" }}
        </el-form-item>
        <el-form-item label="售价" v-if="addEditData.specificationType &&
          addEditData.packingUnit &&
          addEditData.isPackingSale &&
          addEditData.packingScale &&
          addEditData.price
          ">
          <span>{{ (addEditData.packingScale * addEditData.price).toFixed(2) }}</span>元/{{ addEditData.packingUnit }}
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="onClose">取 消</el-button>
      <el-button type="primary" @click="onSave">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import UploadImgs from "@/componets/uploadImgs/index.vue";
export default {
  name: "AddDrugDialog",
  components: { UploadImgs },
  props: {
    showAddDrugDialog: {
      type: Boolean,
      default: false,
    },
    drugInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      addEditData: {},
      unitList: [],
      usAgeList: [],
      frequencyList: [],
      drugTypeList: [
        {
          value: "OTC甲",
          key: 1,
        },
        {
          value: "处方药",
          key: 2,
        },
        {
          value: "OTC乙",
          key: 3,
        },
      ],
      packagingList: [],
    };
  },
  methods: {
    onChangeType() {
      this.addEditData.a = "";
    },
    onClose() {
      this.show = false;
    },
    onSave() {
      this.$refs.formRef.validate(async (valid) => {
        if (!valid) return;
        try {
          const params = {
            ...this.addEditData,
            isPackingSale: !!this.addEditData.isPackingSale,
            drugStoreNo: this.$store.state.userInfo.refUserNo,
          };
          if (params.id) {
            await this.$axios({
              method: "post",
              url: "/drug-service/drugInfos/update",
              data: this.$qs.stringify(params),
            });
            this.$message.success("修改成功");
          } else {
            await this.$axios({
              method: "post",
              url: "/drug-service/drugInfos/addition",
              data: params,
            });
            this.$message.success("录入成功");
          }
          this.onClose();
          this.$emit("updateDrugList");
        } catch (error) {
          console.log(error);
        }
      });
    },
    async onGetDict() {
      try {
        const {
          data: {
            data: { list: usAgeList },
          },
        } = await this.$axios({
          method: "get",
          url: "/misc-service/typeInfo/detail",
          params: {
            typeCode: "USAGE_TYPE",
          },
        });
        this.usAgeList = usAgeList;

        const {
          data: {
            data: { list: frequencyList },
          },
        } = await this.$axios({
          method: "get",
          url: "/misc-service/typeInfo/detail",
          params: {
            typeCode: "FREQUENCY_TYPE",
          },
        });
        this.frequencyList = frequencyList;

        const {
          data: {
            data: { list: unitList },
          },
        } = await this.$axios({
          method: "get",
          url: "/misc-service/typeInfo/detail",
          params: {
            typeCode: "UNIT_TYPE",
          },
        });
        this.unitList = unitList;

        const {
          data: {
            data: { list: packagingList },
          },
        } = await this.$axios({
          method: "get",
          url: "/misc-service/typeInfo/detail",
          params: {
            typeCode: "PACKAGING_UNIT",
          },
        });
        this.packagingList = packagingList;
      } catch (error) {
        this.usAgeList = [];
        this.frequencyList = [];
        this.unitList = [];
        this.packagingList = [];
      }
    },
    getDictOptionsLabel(list, key, value = "key", label = "value") {
      const findList = list;
      if (!["string", "number"].includes(typeof key) || findList.length === 0)
        return "-";
      const obj = findList.find((item) => item[value] === key);
      return obj ? obj[label] : "-";
    },
  },
  computed: {
    show: {
      get() {
        return this.showAddDrugDialog;
      },
      set(value) {
        this.$emit("update:showAddDrugDialog", value);
      },
    },
    getDosageUnitLable() {
      return this.addEditData.dosageUnit
        ? this.getDictOptionsLabel(this.unitList, this.addEditData.dosageUnit)
        : "";
    },
  },
  watch: {
    show(value) {
      if (value) {
        this.$nextTick(() => {
          this.$refs.formRef.clearValidate();
        });
        this.onGetDict();
        this.addEditData = { ...this.drugInfo, drugType: 2 };
      }
    },
  },
};
</script>
