<template>
    <div class="container">
        <div class="search-top" style="margin-bottom: 15px;">
            <div class="item">
                <span>状态：</span>
                <el-select v-model="searchData.status" size="medium" class="status">
                    <el-option value="" label="全部"></el-option>
                    <el-option value='1' label="已上架"></el-option>
                    <el-option value='0' label="已下架"></el-option>
                </el-select>
            </div>
           
            <!-- <span>属性分类：</span>
            <el-select v-model="searchData.drugType" size="medium" class="drugType">
                <el-option v-for="item of drugTypeList" :key="item.value" :value="item.value"
                    :label="item.label"></el-option>
            </el-select> -->
            <div class="item">
                <span>输入搜索：</span>
                <el-input v-model.trim="searchData.keyWord" size="medium" placeholder="编号/商品名/通用名/批准文号"
                    style="width: 2.5rem;"></el-input>
            </div>
            <div class="item">
                <span>药品规格：</span>
                <el-select v-model="basicParam.specificationType" size="medium" class="drugType">
                    <el-option v-for="item of specificationTypeOptions" :key="item.value" :value="item.value"
                        :label="item.label"></el-option>
                </el-select>
            </div>
            <div class="item">
                <el-button @click="getSearchData" size="medium" type="primary"
                style="margin-left: 0.2rem">筛选</el-button><el-link style="margin-left: 20px" :underline="false"
                @click="clearSearchData">清空筛选条件</el-link>
                <el-button type="primary" style="margin-left: 20px;"
                @click="newAddDrugState = { info: {}, show: true }">录入药品</el-button>
            </div>  
        </div>
        <div style="margin-top: 0rem;float: right;color: red"><span v-if="resData.shortage">当前有{{ resData.shortage
        }}个商品缺货，</span><span v-if="resData.insufficient">{{ resData.insufficient }}个商品库存不足，</span><span
                v-if="resData.shortage || resData.insufficient">请尽快补充库存！</span></div>
        <div>
            <el-table v-loading="loading" element-loading-text="拼命加载中" :cell-style="cellStyle" :data="tableData"
                :header-cell-style="headerCellStyle" border style="width: 100%;font-size: 0.14rem" v-watermark>
                <el-table-column prop="drugStoreData.drugStoreNo" label="药房商品编码" align="center"
                    :show-overflow-tooltip="showOverflow">
                </el-table-column>
                <el-table-column prop="drugNo" align="center" :show-overflow-tooltip="showOverflow" label="商品编码">
                </el-table-column>
                <el-table-column prop="genericName" align="center" :show-overflow-tooltip="showOverflow" label="通用名">
                </el-table-column>
                <el-table-column prop="tradeName" align="center" :show-overflow-tooltip="showOverflow" label="商品名">
                </el-table-column>
                <el-table-column prop="drugSpecifications" align="center" :show-overflow-tooltip="showOverflow" label="规格">
                </el-table-column>
                <el-table-column prop="drugStoreData.factoryPrice" align="center" :show-overflow-tooltip="showOverflow"
                    label="供应商指导价" width="120">
                </el-table-column>
                <el-table-column prop="drugStoreData.price" align="center" :show-overflow-tooltip="showOverflow"
                    label="平台活动价" min-width="140">
                    <template slot-scope="scope">
                        <div @mouseenter="scope.row.showChangePrice = true" @mouseleave="scope.row.showChangePrice = false">
                            {{ scope.row.drugStoreData.price }}
                            <i title="修改平台活动价" style="color: #007aff;cursor: pointer;" class="el-icon-edit-outline"
                                v-if="scope.row.showChangePrice" @click="onSetPrice(scope.row, 'price')"></i>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="drugStoreData.linePrice" align="center" :show-overflow-tooltip="showOverflow"
                    label="平台原价" min-width="140">
                    <template slot-scope="scope">
                        <div @mouseenter="scope.row.showChangeLinePrice = true"
                            @mouseleave="scope.row.showChangeLinePrice = false">
                            {{ scope.row.drugStoreData.linePrice }}
                            <i title="修改平台原价" style="color: #007aff;cursor: pointer;" class="el-icon-edit-outline"
                                v-if="scope.row.showChangeLinePrice" @click="onSetPrice(scope.row, 'linePrice')"></i>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="drugStoreData.stock" align="center" :show-overflow-tooltip="showOverflow"
                    label="库存数量">
                    <template slot-scope="scope">
                        <span style="color: red" v-if="scope.row.drugStoreData.stock < 5">{{ scope.row.drugStoreData.stock
                        }}</span>
                        <span v-else>{{ scope.row.drugStoreData.stock }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="approvalNumber" align="center" :show-overflow-tooltip="showOverflow" label="批准文号">
                </el-table-column>
                <el-table-column prop="manufacturer" align="center" :show-overflow-tooltip="showOverflow" label="生产厂家">
                </el-table-column>
                <!-- <el-table-column prop="drugType" align="center" :show-overflow-tooltip="showOverflow" label="属性分类">
                    <template slot-scope="scope">
                        {{ scope.row.drugType | formateDrugType }}
                    </template>
                </el-table-column> -->
                <el-table-column prop="drugStoreData.shelfTime" align="center" :show-overflow-tooltip="showOverflow"
                    label="上架时间">
                    <template slot-scope="scope">
                        {{ scope.row.drugStoreData.shelfTime | dataFormate }}
                    </template>
                </el-table-column>
                <el-table-column prop="drugStoreData.status" align="center" :show-overflow-tooltip="showOverflow"
                    label="状态">
                    <template slot-scope="scope">
                        <span v-if="scope.row.drugStoreData.status == 1" class="have-in-hand">已上架</span>
                        <span v-if="scope.row.drugStoreData.status == 0" class="failed">已下架</span>
                    </template>
                </el-table-column>

                <el-table-column label="操作" align="center" width="180" fixed="right">
                    <template slot-scope="scope">
                        <el-button type="text" @click="onChangeStatus(scope.row)">
                            {{ scope.row.drugStoreData.status == 0 ? '上架' : '下架' }}
                        </el-button>
                        <el-button type="text" @click="onEdit(scope.row)">
                            编辑
                        </el-button>
                        <el-button type="text" @click="lookDetails(scope.$index, scope.row)">
                            查看
                        </el-button>
                        <el-button type="text" style="color: red;" @click="onRemove(scope.row)"
                            v-if="scope.row.drugStoreData.status == 0">
                            删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="pagination" v-if="total > 0">
                <el-pagination @prev-click="prevClick" @next-click="nextClick" @current-change="currentChange"
                    @size-change="sizeChange" :current-page="searchData.pageNum" :page-sizes="[10, 20, 30, 40]"
                    :page-size="searchData.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total">
                </el-pagination>
            </div>

        </div>
        <commodityDetailsDialog v-if="showDialog" v-on:closeDialog="closeDialog" :commodityDetails="commodityDetails">
        </commodityDetailsDialog>
        <NewAddDrugDialog :showAddDrugDialog.sync="newAddDrugState.show" :drugInfo="newAddDrugState.info"
            @updateDrugList="getCommodityList()" />
        <ChangePriceDialog :showPriceDialog.sync="priceState.show" :drugInfo="priceState.info"
            @updateDrugList="getCommodityList()" />
    </div>
</template>

<script>
import moment from 'moment'
import commodityDetailsDialog from './dialog/commodityDetailsDialog.vue'
import NewAddDrugDialog from './dialog/newAddDrugDialog.vue'
import ChangePriceDialog from './dialog/changePriceDialog.vue'
export default {
    name: "commodityIndex",
    data() {
        return {
            drugTypeList: [
                { value: "", label: "全部" },
                { value: 1, label: "OTC甲类" },
                { value: 3, label: "OTC乙类" },
                { value: 2, label: "处方药" },
                { value: 4, label: "营养保健" },
            ],
            commodityDetails: {},
            showDialog: false,
            loading: true,
            total: 0,
            showOverflow: true,
            tableData: [],
            isClickYes: false,
            searchParam: {},
            basicParam: {
                pageNum: 1,
                pageSize: 10,
                storeNo: '',
                specificationType: 1
            },
            searchData: {
                status: '',
                keyWord: '',
                drugType: ''
            },
            resData: {},
            newAddDrugState: {
                show: false,
                info: {}
            },
            priceState: {
                show: false,
                info: {
                    price: 0,
                    linePrice: 0
                }
            },
            specificationTypeOptions: [
                {
                    value: 1,
                    label: '新规格'
                },
                {
                    value: 0,
                    label: '旧规格'
                },
                {
                    value: '',
                    label: '全部'
                }
            ]
        }
    },
    components: {
        commodityDetailsDialog,
        NewAddDrugDialog,
        ChangePriceDialog
    },
    methods: {
        async onEdit(row) {
            const {data:{data}} = await this.$axios.get(this.$APIURL.DRUGINFOS_SELECTBYNO, { params: { drugNo: row.drugNo, drugStoreNo: row.drugStoreData.drugStoreNo } })
            this.newAddDrugState.info = {
                ...data,
                price: data?.drugStoreData?.price,
                isPackingSale: data.isPackingSale ? 1 : 0,
                drugStoreData: ''
            }
            delete this.newAddDrugState.info.drugStoreData
            this.newAddDrugState.show = true
        },
        async onRemove(row) {
            try {
                await this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                })
                await this.$axios({
                    method: 'delete',
                    url: '/drug-service/drugInfos/delete/drug',
                    params: {
                        id: row.id
                    }
                })
                this.$message.success("删除成功")
                this.getCommodityList();
            } catch (error) {
                console.log(error)
            }
        },
        async onChangeStatus(row) {
            const { data } = await this.$axios({
                method: 'get',
                url: '/drug-service/drugInfos/upperLowerRacks',
                params: {
                    id: row.id,
                    status: row.drugStoreData.status,
                    userName: this.$store.state.userInfo.username
                }
            })
            if (data.code == 0) {
                this.$message({
                    message: `${row.drugStoreData.status == 0 ? '上架成功' : '下架成功'}`,
                    type: 'success'
                });
                this.getCommodityList();
            } else {
                this.$message.error('操作失败');
            }
        },
        onSetPrice(row, type) {
            this.priceState = {
                show: true,
                info: {
                    priceType: type,
                    id: row.id,
                    yprice: type === 'price' ? row.drugStoreData.price : row.drugStoreData.linePrice,
                    price: type === 'price' ? row.drugStoreData.price : row.drugStoreData.linePrice
                }
            }
        },
        getCommodityList() {
            this.loading = true;
            let _this = this;
            this.searchParam = {}
            this.searchParam = Object.assign({}, this.basicParam)
            if (_this.isClickYes) {
                this.searchParam = Object.assign(this.searchParam, this.searchData)
            }
            this.$axios.post(this.$APIURL.DRUGINFOS_ALL, this.searchParam).then(res => {
                if (res.data.code == 0) {
                    this.resData = res.data.data
                    var data = res.data
                    _this.tableData = data.data.drugStoreData.list.map(item => ({
                        ...item,
                        showChangePrice: false,
                        showChangeLinePrice: false
                    }))
                    _this.total = data.data.drugStoreData.total
                }
            }).catch(err => {
                this.$respone.error(this.$respone.catchMsg)
            }).finally(() => {
                _this.loading = false;
            })
        },
        prevClick(currentPage) {
            this.basicParam.pageNum = currentPage;
            this.getCommodityList()
        },
        nextClick(currentPage) {
            this.basicParam.pageNum = currentPage;
            this.getCommodityList()
        },
        currentChange(currentPage) {
            this.basicParam.pageNum = currentPage;
            this.getCommodityList()
        },
        sizeChange(size) {
            this.basicParam.pageSize = size;
            this.getCommodityList()
        },
        cellStyle() {
            let style = { height: '0.4rem' }
            return style;
        },
        headerCellStyle() {
            let style = { background: '#f1f4fa', height: '0.6rem' }
            return style;
        },
        closeDialog(data) {
            this.showDialog = data;
        },
        getSearchData() {
            this.isClickYes = true
            this.basicParam.pageNum = 1
            this.getCommodityList()
        },
        lookDetails(index, row) {
            var _this = this
            this.$axios.get(this.$APIURL.DRUGINFOS_SELECTBYNO, { params: { drugNo: row.drugNo, drugStoreNo: row.drugStoreData.drugStoreNo } }).then(res => {
                if (res.data.code == 0) {
                    if (res.data.data != null) {
                        var data = res.data
                        if (data.data.drugPic != null && data.data.drugPic != '') {
                            data.data.drugPic = data.data.drugPic.split(',')
                        }
                        _this.commodityDetails = data.data
                        _this.showDialog = true
                    } else {
                        this.$respone.error(res.data.message)
                    }
                } else {
                    this.$respone.error('查看失败！')
                }
            }).catch(() => {
                this.$respone.error(this.$respone.catchMsg)
            })
        },
        clearSearchData() {
            this.isClickYes = false
            this.basicParam.pageNum = 1
            this.searchData.status = this.searchData.keyWord = this.searchData.drugType = ''
            this.basicParam.specificationType = 1;
            this.getCommodityList()
        }
    },
    created() {
        this.basicParam.storeNo = this.$store.state.userInfo.refUserNo
        this.getCommodityList()
    }
}
</script>

<style scoped lang="scss">
:v-deep .el-pagination button {
    background: none;
}

:v-deep .el-pager li {
    background: none;
}

:v-deep .el-input__inner {
    font-size: 0.14rem;
}

.search-top {
    font-size: 0.14rem;
    display: flex;
    flex-wrap: wrap;
    $margin: 15px;
    margin-left: -$margin;
    margin-top: -$margin;
    .item {
        margin-left: $margin;
        margin-top: $margin;
    }
    .el-input {
        width: 2rem;
    }
}

.container {
    width: 100%;
    height: 100%;
    display: block;
    padding: 0.3rem;
    font-size: 0.14rem;
    overflow-y: auto;
    background: #FBFBFB;
}

.pagination {
    margin-top: 0.2rem;
    text-align: center;
}

:v-deep .drugType {
    .el-input {
        width: 150px;
    }
}

:v-deep .status {
    .el-input {
        width: 150px;
    }
}
</style>
