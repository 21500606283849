import axios from './axiosConfig.js'
import qs from 'qs'
import respone from './responMsg.js'
import store from './vuex.js'
import { Notification } from 'element-ui'
import msgAudio from '../assets/audio/examine.mp3'
let audio = new Audio(msgAudio);
let audioTimer = 0;
let audioLoading = true;
setTimeout(() => {
    audioLoading = false;
}, 1000)
const onAudioPlay = () => {
    return new Promise((resolve, reject) => {
        if (audioLoading) {
            reject(false)
        } else {
            if (audioTimer) clearTimeout(audioTimer);
            audioTimer = setTimeout(() => {
                audio.play();
                resolve(true);
            }, 500)
        }
    })
}

var common = {
    autoSize:function(){
        let designSize = 1920; // 设计图尺寸
        let html = document.documentElement;
        let wW = html.clientWidth;// 窗口宽度
        let rem = wW * 100 / designSize;
        document.documentElement.style.fontSize = rem + 'px';


        window.onresize = function () {
            let designSize = 1920; // 设计图尺寸
            let html = document.documentElement;
            let wW = html.clientWidth;// 窗口宽度
            let rem = wW * 100 / designSize;
            document.documentElement.style.fontSize = rem + 'px';
        }
    },
    //订单状态
    orderStatus:[
        {value:"",label:"全部"},
        //{value:0,label:"待支付"},
        //{value:1,label:"已支付"},
        //{value:2,label:"已取消"},
        //{value:3,label:"已关闭"},
        {value:4,label:"待发货"},
        {value:13,label:"待自取"},
        {value:5,label:"待签收"},
        {value:6,label:"已完成"},
        {value:7,label:"已退货"},
        {value:8,label:"已退款"},
        {value:10,label:"已签收"},
        {value:12,label:"面单已打印"},
        {value:14,label:"待揽收"},
    ],
    //订单类型
    orderType:[
        {value:"",label:"全部"},
        {value:0,label:"非处方订单"},
        {value:1,label:"处方订单"},
    ],
    //药品销售数据下的属性分类
    drugType:[
        {value:"",label:"全部"},
        {value:1,label:"OTC甲类"},
        {value:3,label:"OTC乙类"},
        {value:2,label:"处方药"},
    ],
    //请求返回的数据
    responeData:'',
    //url:请求路径。params:参数。success:请求成功的方法。successHaveData:请求成功有数据的方法。successNullData:请求成功无数据的方法。codeNotZero:请求成功code不为0的方法。error:错误的方法
    httpPost:function (url,params,success,successCodeIsZero,successHaveData,successNullData,codeNotZero,error) {
        axios({
            method:'post',
            url:url,
            data:qs.stringify(params)
        }).then(res => {
            if(success){
                success()
            }
            if(res.data.code==0){
                if(successCodeIsZero){
                    successCodeIsZero()
                }
                if(res.data!=null){
                    if(successHaveData){
                        successHaveData()
                    }
                }else {
                    if(successNullData){
                        successNullData
                    }
                }
            }else{
                respone.error(respone.requestFail)
                if(codeNotZero){
                    codeNotZero()
                }
            }
        }).catch(err => {
            if(error){
                error()
            }
            respone.error(respone.catchMsg)
        })
    },
    //url:请求路径。params:参数。success:请求成功的方法。successHaveData:请求成功有数据的方法。successNullData:请求成功无数据的方法。codeNotZero:请求成功code不为0的方法。error:错误的方法
    httpGet:function (url,params,success,successCodeIsZero,successHaveData,successNullData,codeNotZero,error) {
        axios({
            method:'get',
            url:url,
            params:params
        }).then(res => {
            if(success){
                success()
            }
            if(res.data.code==0){
                if(successCodeIsZero){
                    successCodeIsZero
                }
                if(res.data!=null){
                    common.responeData=res.data
                    if(successHaveData){
                        successHaveData()
                    }
                }else {
                    respone.warning(respone.nullMsg)
                    if(successNullData){
                        successNullData()
                    }
                }
            }else{
                respone.error(respone.requestFail)
                if(codeNotZero){
                    codeNotZero()
                }
            }
        }).catch(err => {
            respone.error(respone.catchMsg)
            if(error){
                error()
            }

        })
    },
    //是否有权限
    hasPerm(perm){
        return JSON.parse(sessionStorage.getItem("perms")).includes(perm)
    },
    // 处理接收的websocket消息
    handleWebSocketMessage(res){
        // console.log(res)
        // console.log(store.state.messageWebSocket)
        let messageWebSocket = store.state.messageWebSocket
        res.text = res.text ? JSON.parse(res.text) : ''
        if(res.messageType == 5){
            // 药师端
            if (res.text.operationType == 0){
                onAudioPlay().then(() => {
                    messageWebSocket.ysPrescriptionRefresh = true
                    Notification.warning({
                        title: '您有新的订单待审核，请尽快处理！',
                        message: '',
                        showClose: true,
                        duration: 3000,
                        position: 'bottom-right'
                    })
                });
            }
        }else if(res.messageType == 6){
            // 药房端
            // if(res.text.operationType == 0){
            //     messageWebSocket.ydOrderListRefresh = true
            // }
        }
        if(res.text.operationType != -1){
            store.commit('setMessageWebSocket', messageWebSocket)
        }
    }
}
export default common
