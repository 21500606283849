<template>
    <el-dialog
            custom-class="dialogClass1"
            title="订单退货"
            :visible.sync="dialogVisible"
            @closed="closed"
            :modal-append-to-body='false'

    >
        <span class="dialog-span">
            <el-form :model="returnGoodsForm" :rules="rules" ref="returnGoodsForm" class="demo-ruleForm">
                <el-form-item label="退货理由" prop="reason">
                    <el-input v-model="returnGoodsForm.reason" type="textarea" maxlength="200" :rows="5" show-word-limit placeholder="请填写退货理由，200字内"></el-input>
                </el-form-item>
            </el-form>
        </span>
        <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false" size="small">取 消</el-button>
            <el-button type="primary" @click="save" size="small">确 定</el-button>
          </span>
    </el-dialog>
</template>

<script>

    export default {
        name: "returnGoodsDialog",
        data(){
            return {
                dialogVisible:true,
                returnGoodsForm:{
                    orderNo:'',
                    reason:''
                },
                rules:{
                    reason:[
                        { required: true, message: '请填写退货理由', trigger: 'change' },
                    ]
                }
            }
        },
        methods:{
            closed(){
                this.$emit('closeReturnGoodsDialog',false)
            },
            save(){
                this.$refs['returnGoodsForm'].validate(valid => {
                    if(valid){
                        this.$axios.get(this.$APIURL.RETURN_ORDER_GOODS,{params:this.returnGoodsForm}).then(res => {
                        //this.$axios.post(this.$APIURL.RETURN_ORDER_GOODS,this.$qs.stringify(this.returnGoodsForm)).then(res => {
                            if(res.data.code==0){
                                this.$respone.success(res.data.message)
                                this.dialogVisible = false
                                this.$emit('closeReturnGoodsDialog',false,'reflush')
                            }else{
                                this.$respone.error(res.data.message)
                            }
                        }).catch(err => {
                            this.$respone.error(this.$respone.catchMsg)
                        })
                    }else{
                        return false
                    }
                })

            }
        },
        components:{

        },
        props:['orderInfo'],
        props: {
            orderInfo:{
                default: 1
            }
        },
        created() {
            this.returnGoodsForm.orderNo=this.orderInfo.orderNo
        }
    }
</script>

<style scoped lang="scss">
    .dialog-span{
        &>div:nth-child(1){
            margin: 0;
        }
        &>div{
            margin-top: 0.2rem;
            &>div{
                margin-top: 0.1rem;
            }
        }

    }

    :v-deep  .dialogClass1{
        margin-top: 20vh !important;
        width: 500px!important;
        min-width: 500px!important;
    }
    .el-dialog__wrapper{
        top: 0;
    }

</style>