<template>
    <div class="container">
        <router-view></router-view>
    </div>
</template>

<script>

    export default {
        name: "App",
        created(){
            //在页面刷新时将vuex里的信息保存到sessionStorage里
            window.addEventListener("beforeunload",()=>{
                sessionStorage.setItem("store",JSON.stringify(this.$store.state))
            })
            if(this.$route.path == '/login') return
            //在页面加载时读取sessionStorage里的状态信息
            if (sessionStorage.getItem("store") ) {
                this.$store.replaceState(Object.assign({}, this.$store.state,JSON.parse(sessionStorage.getItem("store"))))
                // 如果不存在用户编号，跳转到登录页面
                // if(!this.$store.state.doctorLoginInfo.refUserNo){
                //     this.$message.error('登录失效，即将跳转到登录页面...')
                //     setTimeout(() => {
                //         this.$router.push('/login')
                //     },1500)
                // }
            }else {
                this.$message.error('登录失效，即将跳转到登录页面...')
                setTimeout(() => {
                    this.$router.push('/login')
                },1500)
            }

        },
        watch: {
            "$store.state.userInfo"(newval, olval){
                if(this.$store.state.userInfo.refUserNo){
                    // let url = 'ws://1.1.1.61:8036/drug-service/drugSocket/'+this.$store.state.userInfo.refUserNo
                    let url = process.env.VUE_APP_WS_HOST+'drug-service/drugSocket/'+this.$store.state.userInfo.refUserNo
                    //心跳消息
                    let data = {
                        "sender": this.$store.state.userInfo.username, 
                        "senderNo": this.$store.state.userInfo.refUserNo, 
                        "receiver": this.$store.state.userInfo.username,
                        "receiverNo": this.$store.state.userInfo.refUserNo,
                        "text":{"operationType":-1},
                        "messageType":5
                    }
                    data.text = JSON.stringify(data.text)
                    if(this.$store.state.userInfo.userType == 2){
                        //   药房
                        data.messageType = 6
                        // data.senderNo = this.$store.state.userInfo.refUserNo + '-' + this.$store.state.userInfo.username
                        // data.receiverNo = this.$store.state.userInfo.refUserNo + '-' + this.$store.state.userInfo.username
                        // url = process.env.VUE_APP_WS_HOST+'drug-service/drugSocket/'+this.$store.state.userInfo.refUserNo + '-' + this.$store.state.userInfo.username
                    }
                    this.$initWebSocket(url, data)
                    this.$onMessageWebsocket(this.$common.handleWebSocketMessage)
                }
            }
        },
        mounted(){
            this.$common.autoSize()
        },
        data(){
            return {
                isRouter:true,
            }
        },
        components:{

        }
    }
</script>

<style lang="scss">
@import "@/assets/css/theme";

</style>

<style scoped lang="scss">
@import "@/assets/css/theme";
.container{
    width: 100%;
    height: 100%;
}

</style>
