<template>
<div class="dialog">
    <el-dialog
        title="签名"
        :visible.sync="dialogVisible"
        width="700px"
        :close-on-click-modal="false"
        @closed="closeDialog">
        <div class="canvas">
            <vue-esign ref="esign" :width="700" :height="300" :isCrop="isCrop" :lineWidth="lineWidth" :lineColor="lineColor" :bgColor.sync="bgColor" />
            <!-- <img :src="resultImg" alt=""> -->
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button type="danger" @click="handleReset">清空画板</el-button>
            <el-button type="primary" @click="handleGenerate">确 定</el-button>
        </span>
    </el-dialog>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                dialogVisible: true,
                lineWidth: 8,
                lineColor: '#000000',
                bgColor: '',
                resultImg: '',
                isCrop: false,

                flag: false
            }
        },
        methods: {
            // 清空画板
            handleReset () {
                this.$refs.esign.reset()
            },
            // 生成图片
            handleGenerate () {
                if(this.flag) return
                this.flag = true
                this.$refs.esign.generate().then(res => {
                // 转成文件
                var tofile = this.base64ToFile(res, '药师签名.png')
                const formData = new FormData()
                formData.append('file', tofile)
                formData.append('module', 'DRUG')
                formData.append('category', 'jpg')
                formData.append('options', 'sign')
                formData.append('async', false)
                this.$axios.post(this.$APIURL.UPLOAD_FILE, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }).then(res => {
                    var res = res.data
                    if(res.code == 0){
                        this.flag = false
                        this.$emit('handleSign', res.data.relativeUrl)
                    }else {
                        this.flag = false
                        this.$respone.warning('上传签名失败')
                    }
                })
                // this.resultImg = res
                // console.log(res)
                }).catch(err => {
                    this.flag = false
                    this.$respone.warning("请签字再做确定操作")
                // alert(err) // 画布没有签字时会执行这里 'Not Signned'
                })
            },
            // 关闭弹框
            closeDialog() {
                this.$emit('closeDialog',false)
            },

            // base64转成文件
            base64ToFile(urlData, fileName) {
                let arr = urlData.split(',');
                let mime = arr[0].match(/:(.*?);/)[1];
                let bytes = atob(arr[1]); // 解码base64
                let n = bytes.length
                let ia = new Uint8Array(n);
                while (n--) {
                    ia[n] = bytes.charCodeAt(n);
                }
                return new File([ia], fileName, { type: mime });
            },

        }
    }
</script>

<style lang="scss" scoped>
    .dialog ::v-deep  .el-dialog__wrapper {
        z-index: 100001!important;
    }
    .canvas{
        border: 1px solid #eaeaea;
    }
    .dialog-footer {
        display: flex;
        justify-content: center;
    }
</style>
