<template>
    <el-table
            :data="consigneeInfo"
            border
            :header-cell-style="headerCellStyle"
            style="width: 100%">

        <el-table-column
                prop="receiverName"
                label="收货人姓名"
                align="center"
                :show-overflow-tooltip="showOverflow"
        >
            <template slot-scope="scope">
                <span>{{scope.row.receiverName | nullFormate}}</span>
            </template>
        </el-table-column>
        <el-table-column
                prop="receiverPhone"
                label="收货人电话号"
                align="center"
                :show-overflow-tooltip="showOverflow"
        >
            <template slot-scope="scope">
                <span>{{scope.row.receiverPhone | nullFormate}}</span>
            </template>
        </el-table-column>
        <el-table-column
                prop="receiverAddress"
                align="center"
                :show-overflow-tooltip="showOverflow"
                label="收货人地址	">
            <template slot-scope="scope">
                <span>{{deliverType == 1 ? scope.row.receiverAddress : '用户自取'}}</span>
            </template>
        </el-table-column>

    </el-table>
</template>

<script>
    export default {
        name: "consigneeTable",
        data(){
            return {
                showOverflow:true
            }
        },
        props:['consigneeInfo'],
        props: {
            consigneeInfo:{
                default:3
            },
            deliverType: {
                default: 1
            }
        },
        methods:{
            headerCellStyle({row,column,rowIndex,columnIdex}){
                let style = {background: '#f1f4fa',height:'0.45rem'}
                return style;
            },
        }
    }
</script>

<style scoped>

</style>