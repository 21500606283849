<template>
	<!-- 区域选择 -->
	<div class="region-select">
		<slot></slot>
		<template v-if="!$slots.default">
			<el-cascader style="width: 100%" filterable v-model="parameter.active" :options="parameter.list" :props="Object.assign({}, { label: 'name', value: 'code' }, params)" :teleported="false" @change="onRegionChange" :clearable="clearable" />
		</template>
	</div>
</template>

<script>
import regionJson from './index'
export default {
	name: 'region',
	components:{
		
	},
	props: {
		value: {
			type: null,
			default: () => []
		},
		params: {
			type: Object,
			default: () => ({})
		},
		clearable: {
			type: Boolean,
			default: false
		}
	},
	data(){
		return {
			parameter: {
				list: [],
				active: []
			}
		}
	},
	mounted() {
		this.init();
	},
	methods: {
		onRegionChange(value) { // 改变
			if(!value) value = [];
			let res = this.onRegionNameGet(this.parameter.list, value, 0);
			if (typeof (this.value) == 'string') {
				this.$emit('input', res.join(''))
			} else {
				this.$emit('input', JSON.parse(JSON.stringify(value)))
				this.$emit('change', value.map((item, index) => ({
					code: item,
					name: res[index]
				})))
			}
		},
		onRegionNameGet(list, selected, index) { // 区域名称获取
			let res = [];
			if (selected && selected.length) {
				for (let i = 0; i < list.length; i++) {
					if (list[i].code == selected[index]) {
						res.push(list[i].name);
						if (list[i].children) {
							let children = this.onRegionNameGet(list[i].children, selected, index + 1)
							if (children.length) {
								res = res.concat(children);
							}
						}
						break;
					}
				}
			}
			return res;
		},
		onRegionCodeGet(selected) { // 通过name值获取对应编号
			return selected.length ? this.onRegionCodeGetEach(this.parameter.list, selected) : [];
		},
		onRegionCodeGetEach(list, selected, index) { // 通过name值获取对应编号-递归遍历
			if (!index) index = 0;
			let res = [];
			for (let i = 0; i < list.length; i++) {
				if (list[i].name == selected[index]) {
					res.push(list[i].code);
					if (list[i].children && list[i].children.length) {
						let children = this.onRegionCodeGetEach(list[i].children, selected, index + 1);
						if (children.length) {
							res = res.concat(children);
						}
					}
					break;
				}
			}
			return res;
		},
		onDataInit() { //数据格式化
			let list = this.onDataEach([regionJson.province_list, regionJson.city_list, regionJson.county_list], 0);
			this.parameter.list = list;
		},
		onDataEach(all, index, code) { // 区域数据格式化
			let res = [];
			for (let k in all[index]) {
				if (!(code && k.toString().indexOf(code.toString()) != 0)) {
					let obj = {
						name: all[index][k],
						code: k
					}
					if (all[index + 1]) {
						let children = this.onDataEach(all, index + 1, k.slice(0, code ? 4 : 3));
						if (children.length) {
							obj.children = children;
						}
					}
					res.push(obj);
				}
			}
			return res;
		},
		init() {
			this.onDataInit();
			if (this.value) {
				this.parameter.active = JSON.parse(JSON.stringify(this.value))
			}
		}
	},
	watch: {
		value(val) {
			this.parameter.active = val ? JSON.parse(JSON.stringify(val)) : []
		}
	}
}

</script>

<style lang="scss" scoped>
</style>
