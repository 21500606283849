import Vue from 'vue'
import element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(element);

var respone = {
    success:function (msg) {
        Vue.prototype.$message({
            message: msg,
            type: 'success'
        });
    },
    error:function (msg) {
        Vue.prototype.$message({
            message: msg,
            type: 'error'
        });
    },
    warning:function (msg) {
        Vue.prototype.$message({
            message: msg,
            type: 'warning'
        });
    },
    //网络异常提示语
    catchMsg:"网络异常，请稍后重试！",
    nullMsg:'暂无数据',
    requestFail:'请求失败'
}

export default respone;