<template>
  <el-dialog title="修改价格" :visible.sync="show" width="500px" @close="onClose" center>
    <div v-watermark>
      <el-form :model="addEditData" ref="formRef" label-width="110px">
        <el-form-item :label="addEditData.priceType === 'price' ? '平台活动价' : '平台原价'" prop="price" :rules="{
          required: true,
          message: '请输入',
        }">
          <el-input-number v-model="addEditData.price" :precision="2" :min="0" label="请输入" />
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="onClose">取 消</el-button>
      <el-button type="primary" @click="onSave">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: "changePriceDialog",
  props: {
    showPriceDialog: {
      type: Boolean,
      default: false,
    },
    drugInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      addEditData: {},
    };
  },
  methods: {
    onClose() {
      this.show = false;
    },
    onSave() {
      this.$refs.formRef.validate(async (valid) => {
        if (!valid) return;
        try {
          const userName = this.$store.state.userInfo.username
          const { id, price, yprice, priceType } = this.addEditData
          await this.$axios({
            method: "post",
            url: "/drug-service/drugInfos/update/price",
            data: this.$qs.stringify({
              userName,
              id,
              price: price,
              yprice,
              priceType
            }),
          });
          this.$message.success("修改成功");
          this.onClose();
          this.$emit("updateDrugList");
        } catch (error) {
          console.log(error);
        }
      });
    },
  },
  computed: {
    show: {
      get() {
        return this.showPriceDialog;
      },
      set(value) {
        this.$emit("update:showPriceDialog", value);
      },
    },
  },
  watch: {
    show(value) {
      if (value) {
        this.$nextTick(() => {
          this.$refs.formRef.clearValidate();
        });
        this.addEditData = { ...this.drugInfo, };
      }
    },
  },
};
</script>
