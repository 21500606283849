<template>
    <el-table
            :data="operationalInformation"
            border
            :header-cell-style="headerCellStyle"
            style="width: 100%">
        <el-table-column
                prop="creator"
                label="操作人"
                align="center"
                :show-overflow-tooltip="showOverflow"
        >
        </el-table-column>
        <el-table-column
                prop="createTime"
                label="操作时间"
                align="center"
                :show-overflow-tooltip="showOverflow"
        >
            <template slot-scope="scope">
                <span>{{scope.row.createTime | dataFormate}}</span>
            </template>
        </el-table-column>
        <!--<el-table-column
                prop="status"
                align="center"
                :show-overflow-tooltip="showOverflow"
                label="订单状态">
            <template slot-scope="scope">
                <span>{{scope.row.status | formateOrderStatus}}</span>
            </template>
        </el-table-column>-->
        <el-table-column
                prop="remark"
                align="center"
                :show-overflow-tooltip="showOverflow"
                label="操作内容">
        </el-table-column>

    </el-table>
</template>

<script>
    export default {
        name: "operationalInformationTable",
        data(){
            return {
                showOverflow:true
            }
        },
        props:['operationalInformation'],
        props:{
            operationalInformation:{
                default:3
            }
        },
        methods:{
            headerCellStyle({row,column,rowIndex,columnIdex}){
                let style = {background: '#f1f4fa',height:'0.45rem'}
                return style;
            },
        }
    }
</script>

<style scoped>

</style>