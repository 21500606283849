<template>
    <el-dialog
            custom-class="dialogClass"
            title="商品详情"
            :visible.sync="dialogVisible"
            @closed="closed"
            :modal-append-to-body='false'

    >
        <div v-watermark>
            <el-row>
                <el-col :span="3">通用名：</el-col>
                <el-col :span="8">{{commodityDetails.genericName | nullFormate}}</el-col>
                <el-col :span="3">商品名：</el-col>
                <el-col :span="5">{{commodityDetails.tradeName | nullFormate}}</el-col>
            </el-row>
            <el-row>
                <el-col :span="3">拼音码：</el-col>
                <el-col :span="8">{{commodityDetails.pinyinCode | nullFormate}}</el-col>
                <el-col :span="3">品牌：</el-col>
                <el-col :span="5">{{commodityDetails.brand | nullFormate}}</el-col>
            </el-row>
            <el-row>
                <el-col :span="3">规格：</el-col>
                <el-col :span="8">{{commodityDetails.drugSpecifications | nullFormate}}</el-col>
                <el-col :span="3">批准文号：</el-col>
                <el-col :span="5">{{commodityDetails.approvalNumber | nullFormate}}</el-col>
            </el-row>
            <el-row>
                <el-col :span="3">剂型：</el-col>
                <el-col :span="8">{{commodityDetails.dosageForm | nullFormate}}</el-col>
                <el-col :span="3">生产厂家：</el-col>
                <el-col :span="6">{{commodityDetails.manufacturer | nullFormate}}</el-col>
            </el-row>
            <el-row>
                <el-col :span="3">包装单位：</el-col>
                <el-col :span="8">{{commodityDetails.packingUnit |nullFormate }}</el-col>
                <el-col :span="3">属性分类：</el-col>
                <el-col :span="5">{{commodityDetails.drugType==1?"OTC":commodityDetails.drugType==2?"处方药":"未知"}}</el-col>
            </el-row>
            <el-row>
                <el-col :span="3">科室：</el-col>
                <el-col :span="8">{{commodityDetails.dept | nullFormate}}</el-col>
                <el-col :span="3">商品分类：</el-col>
                <el-col :span="5">{{commodityDetails.classifiedManagement | nullFormate}}</el-col>
            </el-row>
            <el-row>
                <el-col :span="3">病种：</el-col>
                <el-col :span="8">{{commodityDetails.diseaseSpecies | nullFormate}}</el-col>
                <el-col :span="3">人群：</el-col>
                <el-col :span="5">{{commodityDetails.crowd | nullFormate}}</el-col>
            </el-row>
            <el-row>
                <el-col :span="3">功效：</el-col>
                <el-col :span="20">{{commodityDetails.indication | nullFormate}}</el-col>
            </el-row>
            <el-row>
                <el-col :span="3">用法用量：</el-col>
                <el-col :span="20">{{commodityDetails.usageDosage | nullFormate}}</el-col>
            </el-row>
            <el-row>
                <el-col :span="3">成分：</el-col>
                <el-col :span="20">{{commodityDetails.composition | nullFormate}}</el-col>
            </el-row>
            <el-row>
                <el-col :span="3">性状：</el-col>
                <el-col :span="20">{{commodityDetails.description | nullFormate}}</el-col>
            </el-row>
            <el-row>
                <el-col :span="3">贮藏：</el-col>
                <el-col :span="20">{{commodityDetails.storage | nullFormate}}</el-col>
            </el-row>
            <el-row>
                <el-col :span="3">有效期：</el-col>
                <el-col :span="20">{{commodityDetails.termOfValidity | nullFormate}}</el-col>
            </el-row>
            <el-row>
                <el-col :span="3">不良反应：</el-col>
                <el-col :span="20">{{commodityDetails.adverseReaction | nullFormate}}</el-col>
            </el-row>
            <el-row>
                <el-col :span="3">禁忌：</el-col>
                <el-col :span="20">{{commodityDetails.contraindication | nullFormate}}</el-col>
            </el-row>
            <el-row>
                <el-col :span="3">注意事项：</el-col>
                <el-col :span="20">{{commodityDetails.note | nullFormate}}</el-col>
            </el-row>
            <el-row>
                <el-col :span="3">商品图片：</el-col>
                <el-col :span="20" v-if="commodityDetails.drugPic!='' && commodityDetails.drugPic!=null && commodityDetails.drugPic.length!=0">
                    <!--<img v-for="item of commodityDetails.drugPic" :src="item" width="88" preview="1" style="cursor: pointer"/>-->
                    <viewer :images="commodityDetails.drugPic">
                        <img v-for="src in commodityDetails.drugPic" :src="src" :key="src" style="width: 88px;cursor: pointer">
                    </viewer>
                </el-col>
            </el-row>

        </div>

    </el-dialog>
</template>

<script>
    export default {
        name: "commodityDetailsDialog",
        data(){
            return {
                dialogVisible:true
            }
        },
        methods:{
            closed(){
                this.$emit('closeDialog',false)
            }
        },
        prop:['commodityDetails'],
        props: {
            commodityDetails:{
                default:3
            }
        }
    }
</script>

<style scoped lang="scss">

    :v-deep  .dialogClass{
        margin-top: 3vh !important;
        width: 800px;
        min-width: 800px;
    }
    .el-dialog__wrapper{
        top: 0;
    }
.el-row{
    margin-bottom: 0.2rem;
    .el-col:nth-child(odd){
        text-align: right;
    }
}
</style>
