//webSocket
import czParms from './utilSign.js'
var appid = process.env.VUE_APP_APPID

let websock = {}; //建立的连接
let lockReconnect = false; //是否真正建立连接
let timeout = 10 * 1000; //心跳间隔
let timeoutObj = null; //心跳心跳倒计时
let serverTimeoutObj = null; //心跳倒计时
let timeoutnum = null; //断开重连倒计时
let wsUrl = ''; //请求地址
let messageCallback = null; //接收信息回调函数
//发送心跳的信息
let data = {}

// 初始化websocket
function initWebSocket(url, starTData) {
    wsUrl = url
    data = starTData
    let sign = czParms(Object.assign(data,{appId: appid}))
    let wsurl = `${wsUrl}?appId=${appid}&sign=${sign}`
    if("WebSocket" in window){
        // 建立连接
        websock = new WebSocket(wsurl);
    } else if("MozWebSocket" in window) {
        // 建立连接
        websock = new MozWebSocket(wsurl);
    }else {
        console.log('当前您的浏览器不支持websocket')
        return
    }
     // 连接成功
     websock.onopen = websocketonopen;
     // 连接错误
     websock.onerror = websocketonerror;
     // 连接关闭
     websock.onclose = websocketclose;
     // 接收信息
     websock.onmessage = websocketonmessage;
}

// 重新连接
function reconnect() {
    if(lockReconnect){
        return;
    }
    lockReconnect = true;
    // 没连接上会一直重连，设置延迟避免请求过多
    timeoutnum && clearTimeout(timeoutnum);
    timeoutnum = setTimeout(function() {
        // 新连接
        initWebSocket(wsUrl, data);
        lockReconnect = false;
    }, 5000);
}

//重置心跳
function reset() {
    //清除时间
    clearTimeout(timeoutObj);
    clearTimeout(serverTimeoutObj);
    start();
}

//开启心跳
function start() {
    timeoutObj && clearTimeout(timeoutObj);
    serverTimeoutObj && clearTimeout(serverTimeoutObj);
    timeoutObj = setTimeout(() => {
      //这里发送一个心跳，后端收到后，返回一个心跳消息，
      if (websock.readyState == 1) {
        //如果连接正常
        websock.send(JSON.stringify(data));
      } else {
        //否则重连
        reconnect();
      }
      serverTimeoutObj = setTimeout(() => {
        //超时关闭
        websock.close();
        // 重新连接
        // initWebSocket(wsUrl, data);
      }, timeout);
    }, timeout);
}

//连接成功事件
function websocketonopen() {
    //开启心跳
    start();
}

//连接失败事件
function websocketonerror() {
    //错误
    //重连
    lockReconnect = false;
    reconnect();
}

//连接关闭事件
function websocketclose(e) {
    //关闭
    console.log("connection closed");
    console.log(e)
    //提示关闭
    //不正常关闭重连
    // e.code === 1000  表示正常关闭。 无论为何目的而创建, 该链接都已成功完成任务。
    // e.code !== 1000  表示非正常关闭。
    if(e && e.code != 1000) {
        reconnect();
    }
}

function websocketsend(msg) {
    //向服务器发送信息
    //数据发送
    if (websock.readyState == 1) {
      websock.send(JSON.stringify(msg));
    } else {
      websock.close();
      initWebSocket(url, data);
    }
}

// 接收服务器返回的数据
function websocketonmessage(e) {
     // 收到服务器信息，心跳重置
     reset();
     if(messageCallback instanceof Function){
        messageCallback(JSON.parse(e.data))
     }
}
// 自定义发送消息
function sendWebsocket(agentData) {
    websocketsend(agentData)
}

// 自定义接受消息
function onMessageWebsocket(callback){
    messageCallback = callback
}

// 自定义关闭websocket
function closeWebsocket() {
    if (websock) {
        websock.close() // 关闭websocket
        websock.onclose() // 关闭websocket
    }
}

export {
    initWebSocket,
    sendWebsocket,
    onMessageWebsocket,
    closeWebsocket
}