<template>
  <el-dialog custom-class="dialogClass" @closed="closed" :visible.sync="dialogVisible" :modal-append-to-body="false">
    <div id="print_order_detail">
      <div class="print_order_item" v-for="orderInfo in printOrderList" v-bind:key="orderInfo.orderNo"
        :style="{ backgroundImage: createWatermark(orderInfo.orderNo) }">
        <div id="order_no_barcode" style="display: flex; justify-content: space-between;">
          <barcode style="width: 80%; overflow: hidden;" :value="orderInfo.orderNo" :width="3" height="55" margin="0"
            format="CODE128" text-align="right" text-position="bottom" fontSize="16" display-value="false">条码渲染失败
          </barcode>
          <div style="text-align: right; font-weight: bold;">{{ orderInfo.specificationType === 1 ? '新' : '旧' }}</div>
        </div>
        <div id="order_no_info" style="display: flex; align-items: center; justify-content: space-between;">
          <div id="order_no" style="white-space: nowrap;">订单编号：{{ orderInfo.orderNo }}</div>
          <div class="right" style="display: flex; align-items: center; font-size: 14px; white-space: nowrap;">
            <div class="order_table_label">医生：{{ orderInfo.doctorName }}</div>
            <div class="order_table_label" style="margin-left: 20px;">医助：{{ orderInfo.doctorAssistantName }}</div>
          </div>

          <!-- <div id="order_no_barcode">
            <barcode
              :value="orderInfo.orderNo"
              width="2"
              height="55"
              margin="0"
              format="CODE128"
              fontSize="16"
              display-value="false"
              >条码渲染失败</barcode
            >
          </div> -->
        </div>
        <div>
          <table class="order_table">
            <tr>
              <th>实付金额（元）</th>
              <!-- <th>处方数量（张）</th> -->
              <th>支付时间</th>
              <th>药师</th>
            </tr>
            <tr>
              <td style="width: 33.33333333%">{{ orderInfo.payAmount }}</td>
              <!-- <td>{{ orderInfo.prescriptionNo.split(",").length }}</td> -->
              <td style="width: 33.33333333%">{{ orderInfo.payTime }}</td>
              <td style="width: 33.33333333%">{{ orderInfo.pharmaceutist }}</td>
            </tr>
          </table>
        </div>
        <div style="margin-top:20px;">
          <div class="order_table_label">收货人及就诊人信息</div>
          <table class="order_table">
            <tr>
              <th>收货人姓名</th>
              <th>手机号</th>
              <th>收货地址</th>
            </tr>
            <tr>
              <td>{{ orderInfo.receiverName }}</td>
              <td>{{ orderInfo.receiverPhone }}</td>
              <td>{{ orderInfo.deliverType == 1 ? orderInfo.receiverAddress : '用户自取' }}</td>
            </tr>
          </table>

          <table class="order_table">
            <tr>
              <th>就诊人姓名</th>
              <th>性别</th>
              <th>年龄</th>
            </tr>
            <tr>
              <td>{{ orderInfo.userMemberName }}</td>
              <td>{{ orderInfo.userMemberSex === 0 ? '女' : orderInfo.userMemberSex === 1 ? '男' : '未知' }}</td>
              <td>{{ orderInfo.userMemberAge }}</td>
            </tr>
          </table>
        </div>
        <div style="margin-top:20px;">
          <div class="order_table_label">
            处方编号：{{ orderInfo.prescriptionNo }}
          </div>
          <table class="order_table">
            <tr>
              <th>商品编号</th>
              <th>商品名称</th>
              <th>规格</th>
              <th>用法</th>
              <th>数量</th>
              <th>单价</th>
            </tr>
            <tr v-for="item in orderInfo.prescriptionDetailList" v-bind:key="item.id">
              <td>{{ item.drugNo }}</td>
              <td>{{ item.drugName }}</td>
              <td>{{ item.drugSpecifications }}</td>
              <td width="22%" style="line-height: 1; padding: 5px 0;">
                <p>
                  <span :class="{ wave: drug.list[item.drugNo] && drug.list[item.drugNo].dosage != item.dosage }">每次{{
                    item.dosage }}{{ item.dosageUnitName }}</span>
                  <span>&nbsp;</span>
                  <span :class="{ wave: drug.list[item.drugNo] && drug.list[item.drugNo].frequency != item.frequency }">{{
                    item.frequencyName }}</span>
                </p>
                <p style="margin-top: 5px;"
                  :class="{ wave: drug.list[item.drugNo] && drug.list[item.drugNo].drugUsage != item.drugUsage }">{{
                    item.drugUsageName }}</p>
              </td>
              <td>
                <span :class="{ double: drug.list[item.drugNo] && drug.list[item.drugNo].quantity != item.quantity }">
                  {{ `${item.quantity}${item.dosageUnitName}(${askDrugPackCount(item).name})` }}
                </span>
              </td>
              <td>￥{{ item.price }}</td>
            </tr>
          </table>
        </div>
        <div class="remark-box" v-if="orderInfo.remarkList && orderInfo.remarkList.length > 0">
          <div class="remark-title">备注：</div>
          <div class="content-list">
            <el-timeline>
              <el-timeline-item v-for="(item, index) in orderInfo.remarkList" :key="index">
                <div class="content-info">
                  <span style="min-width: 150px;display: inline-block;">{{ item.refUserName }}{{ item.userTypeText ?
                    `(${item.userTypeText})` : '' }}</span>
                  <span style="margin-left: 50px;display: inline-block;">{{ item.createTime }}</span>
                </div>
                <div class="content-remark">{{ item.content }}</div>
              </el-timeline-item>
            </el-timeline>
          </div>
        </div>
      </div>
    </div>
    <div slot="title" class="dialog-footer">
      <el-button plain @click="closed">取 消</el-button>
      <el-button type="primary" @click="doPrint">打 印</el-button>
    </div>
  </el-dialog>
</template>

<script>
import VueBarcode from "vue-barcode";
import { askDrugPackCount } from '@/utils/utils'
export default {
  name: "printOrderDialog",
  data() {
    return {
      dialogVisible: true,
      testInfo: {
        orderNo: "SP12345567899",
        payAmount: 100,
        presCount: 2,
        payTime: "2022-09-09 09:09:09",
        receiverName: "赵四",
        receiverPhone: "13423454231",
        receiverAddress: "广东省广州市天河区柯木塱兆智产业园E栋502-501-099",
        userMemberName: "宋打包",
        sex: "男",
        age: 46,
        pres: [
          {
            presNo: "12345567899",
            items: [
              {
                code: "YP00022",
                name: "然桑那培",
                spec: "1g*50粒",
                count: "30",
              },
              {
                code: "YP00022",
                name: "然桑那培",
                spec: "1g*50粒",
                count: "30",
              },
              {
                code: "YP00022",
                name: "然桑那培",
                spec: "1g*50粒",
                count: "30",
              },
            ],
          },
          {
            presNo: "123455678991111",
            items: [
              {
                code: "YP00022",
                name: "然桑那培",
                spec: "1g*50粒",
                count: "30",
              },
              {
                code: "YP00022",
                name: "然桑那培",
                spec: "1g*50粒",
                count: "30",
              },
              {
                code: "YP00022",
                name: "然桑那培",
                spec: "1g*50粒",
                count: "30",
              },
            ],
          },
        ],
      },
      drug: {
        list: []
      }
    }
  },
  created() {
    console.log("printOrderDialog");
    this.onDrugAllGet();
  },
  methods: {
    askDrugPackCount,
    closed() {
      this.$emit("closePrintOrderDialog", false);
    },
    doPrint() {
      this.$emit("doPrintOrderDetail", false);
      this.onOrderPrinted()
    },
    async onOrderPrinted() {
      const list = this.printOrderList.map((row) => row.orderNo)
      if (list.length) {
        await this.onOrderPrintedSend(list)
        this.$emit("reflushList");
      }
    },
    async onOrderPrintedSend(orderNos) {
      return this.$axios.post('/order-service/drugmg/order/markOrderPrint', orderNos)
    },
    closeReturnGoodsDialog(data, data1) {
      var _this = this;
      this.showReturnGoodsDialog = data;
      if (data1) {
        console.log("reflush123");
        _this.$emit("reflushList");
      }
    },
    closeDialog(data) {
      this.showPrescriptionDialog = data;
    },
    returnGoods() {
      this.showReturnGoodsDialog = true;
    },
    createWatermark(text) {
      var canvas = document.createElement("canvas");
      canvas.width = 220;
      canvas.height = 120;
      var cans = canvas.getContext("2d");
      cans.rotate(-0.5);
      cans.font = "16px Arial";
      cans.fillStyle = "rgba(0, 0, 0, 0.1)";
      cans.fillText(text, -30, canvas.height);
      return `url(${canvas.toDataURL("image/png")})`;
    },
    onDrugAllGet() { //获取所有药品
      return new Promise((resolve) => {
        this.$axios.get('/drug-service/drugInfos/allDrugs', {
          params: {}
        }).then(({ data }) => {
          if (data.code == 0) {
            let res = {};
            (data.data || []).forEach((item) => {
              res[item.drugNo] = item;
            })
            this.drug.list = res;
            resolve(true);
          }
        })
      })
    },
  },
  prop: ["printOrderList"],
  props: {
    printOrderList: {},
  },
  components: {
    barcode: VueBarcode,
  },
};
</script>

<style scoped lang="scss">
.remark-box {
  display: flex;
  margin-top: 20px;

  .remark-title {
    font-size: 1em;
    font-weight: bold;
    width: 70px;
  }

  .content-list {
    padding-top: 7px;
    flex: 1;

    .content-info {}

    .content-remark {
      font-size: 1em;
      font-weight: bold;
      line-height: 1.5em;
      margin-top: 5px;
    }

    ::v-deep .el-timeline {
      .el-timeline-item {
        padding-bottom: 8px;
      }

      .el-timeline-item__node {
        width: 8px;
        height: 8px;
      }

      .el-timeline-item__wrapper {
        top: -5px;
      }

      .el-timeline-item__tail {
        left: 2.5px;
        border-width: 1px;
      }
    }
  }
}

.dialog-span {
  &>div:nth-child(1) {
    margin: 0;
  }

  &>div {
    margin-top: 0.2rem;

    &>div {
      margin-top: 0.1rem;
    }
  }
}

::v-deep .dialogClass {
  margin-top: 3vh !important;
  width: 800px;
  min-width: 800px;
  // .dialog-span{

  // }
  .el-dialog__body {
    padding: 10px 0px;
  }
}

@import "../../../../public/print.css";
</style>
