<template>
  <div>
    <el-dialog
      title="处方预览"
      custom-class="dialogClass"
      :visible.sync="dialogVisible"
      :close-on-click-modal="closeOnClickModal"
      :modal-append-to-body="false"
      @closed="closed"
    >
      <span class="dialog-div-span">
        <div style="border-top: 1px solid #cccccc"></div>
        <!-- <div class="dialog_P">
          <div v-if="prescriptionData.status == 6" style="color:green;text-align: center;font-weight: bold;">审核通过</div>
          <div v-else-if="prescriptionData.status == 5" style="color:red;text-align: center;font-weight: bold;">审核不通过</div>
        </div> -->
        <div
          v-if="
            prescriptionData.status != undefined &&
            prescriptionData.status != null &&
            prescriptionData.status != ''
          "
          style="
            height: 40px;
            border-top: 1px solid #cccccc;
            background: #f7f7f7;
            line-height: 40px;
            padding-left: 29px;
            font-size: 16px;
          "
        >
          <span v-show="prescriptionData.status == 1" style="color: #e54c4d">处方状态：待下单</span>
          <span v-show="prescriptionData.status == 2" style="color: red">处方状态：待审核</span>
          <span v-show="prescriptionData.status == 3" style="color: #eb6100">处方状态：已失效</span>
          <span v-show="prescriptionData.status == 4" style="color: #eb6100">处方状态：已作废</span>
          <span v-show="prescriptionData.status == 5" style="color: #eb6100">
            处方状态：审核不通过
          </span>
          <span v-show="prescriptionData.status == 6" style="color: #339a37">
            处方状态：审核通过
          </span>
          <span v-show="prescriptionData.status == 7" style="color: #e54c4d">处方状态：待支付</span>
        </div>
        <div
          style="
            text-align: center;
            font-size: 24px;
            color: #333333;
            display: flex;
            align-items: center;
            justify-content: center;
          "
        >
          润生医疗互联网医院
        </div>
        <div
          style="
            text-align: center;
            font-size: 24px;
            color: #333333;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
          "
        >
          互联网医院处方笺
          <span
            style="
              border: 1px solid #cccccc;
              width: 45px;
              height: 30px;
              font-size: 14px;
              display: inline-block;
              line-height: 30px;
              margin-left: 20px;
              position: absolute;
              right: 90px;
            "
          >
            普通
          </span>
        </div>
        <div style="padding: 0 94px 0 92px" class="content1">
          <div
            v-show="
              prescriptionData.prescriptionNo != '' &&
              prescriptionData.prescriptionNo != null &&
              prescriptionData.prescriptionNo != undefined
            "
            style="float: right"
          >
            <span style="color: #333333; font-size: 14px">处方编号：</span>
            <span style="color: #ff0000; font-size: 20px">
              {{ prescriptionData.prescriptionNo }}
            </span>
          </div>
          <div style="clear: both">
            <span>费用&nbsp;：</span>
            <span
              style="
                width: 100px;
                padding: 3px;
                border-bottom: 1px solid #cccccc;
                text-align: center;
              "
            >
              自费
            </span>
            <span style="margin-left: 40px">科室&nbsp;：</span>
            <span
              style="
                width: 100px;
                padding: 3px;
                border-bottom: 1px solid #cccccc;
                text-align: center;
              "
            >
              {{ prescriptionData.deptName }}
            </span>
          </div>
          <div>
            <span>姓名&nbsp;：</span>
            <span
              style="
                width: 100px;
                padding: 3px;
                border-bottom: 1px solid #cccccc;
                text-align: center;
              "
            >
              {{ prescriptionData.userName }}
            </span>
            <span style="margin-left: 40px">性别&nbsp;：</span>
            <span
              style="
                width: 100px;
                padding: 3px;
                border-bottom: 1px solid #cccccc;
                text-align: center;
              "
            >
              {{ prescriptionData.sex | sexFormate }}
            </span>
            <span style="margin-left: 40px">年龄&nbsp;：</span>
            <span
              style="
                width: 80px;
                padding: 3px;
                border-bottom: 1px solid #cccccc;
                text-align: center;
              "
            >
              {{ prescriptionData.age }}
            </span>
            <span
              style="
                width: 100px;
                padding: 3px;
                border-bottom: 1px solid #cccccc;
                text-align: center;
                margin-left: 10px;
              "
            >
              {{ prescriptionData.birthday | dataFormate("YYYY-MM-DD") }}
            </span>
          </div>
          <div>
            <span>临床诊断&nbsp;：</span>
            <span
              style="
                width: 200px;
                padding: 3px;
                border-bottom: 1px solid #cccccc;
                text-align: center;
              "
            >
              {{ prescriptionData.diagnose }}
            </span>
            <span style="margin-left: 40px">开具日期&nbsp;：</span>
            <span
              style="
                width: 80px;
                padding: 3px;
                border-bottom: 1px solid #cccccc;
                text-align: center;
              "
            >
              {{ year }}
            </span>
            <span>年</span>
            <span
              style="
                width: 50px;
                padding: 3px;
                border-bottom: 1px solid #cccccc;
                text-align: center;
              "
            >
              {{ month }}
            </span>
            <span>月</span>
            <span
              style="
                width: 50px;
                padding: 3px;
                border-bottom: 1px solid #cccccc;
                text-align: center;
              "
            >
              {{ day }}
            </span>
            <span>日</span>
          </div>
          <div style="">
            <!-- <span>住址/电话&nbsp;：</span>
            <span
              style="width: 530px; border-bottom: 1px solid #cccccc; padding: 3px"
            >{{prescriptionData.ipAddress}}<span v-show="prescriptionData.ipAddress">/</span>{{prescriptionData.mobile}}</span> -->
            <span>电话&nbsp;：</span>
            <span style="width: 530px; border-bottom: 1px solid #cccccc; padding: 3px">
              {{ prescriptionData.mobile }}
            </span>
          </div>
          <div style="display: block">
            <p style="font-size: 30px">Rp</p>
            <div v-for="(item, index) of prescriptionData.preDetail" :key="index">
              <p
                style="
                  margin-left: 35px;
                  margin-top: 14px;
                  display: flex;
                  justify-content: space-between;
                "
              >
                <span>{{ ++index }}、{{ item.drugName }}({{ item.drugSpecifications }})</span>
                <span>{{ item.quantity }}{{ item.unit }}</span>
              </p>

              <p
                style="margin-left: 35px; margin-top: 14px; display: flex; justify-content: center"
              >
                <span style="color: #258bfd; font-size: 12px; text-align: center">
                  用法用量：{{ item.drugUsageName }}
                  <!--{{item.drugUsage===0?"饭前口服":item.drugUsage==1?"饭后口服":item.drugUsage==2?"餐中口服":item.drugUsage==3?"空腹口服":item.drugUsage==4?"外用":"需要时"}}-->
                  , 每次{{ item.dosage }}{{ item.dosageUnitName }}
                  <!--{{item.dosageUnit}}-->
                  &nbsp;,&nbsp;
                  <!--{{item.frequency}}-->
                  {{ item.frequencyName }}&nbsp;
                  <span v-if="item.remark">({{ item.remark }})</span>
                  <!--{{item.frequency===0?"每天1次":item.frequency==1?"每天2次":item.frequency==2?"每天3次":item.frequency==3?"每天4次":item.frequency==4?"每2小时1次":item.frequency==5?"每4小时1次":item.frequency==6?"每6小时1次":item.frequency==7?"每8小时1次":item.frequency==8?"隔日一次":item.frequency==9?"每周1次":item.frequency==10?"每周2次":item.frequency==11?"睡前服用":item.frequency==12?"必要时服用":item.frequency==13?"紧急时使用":"立即使用"}}&nbsp;&nbsp;&nbsp;-->
                </span>
              </p>
            </div>
            <div style="height: 2px; border-top: 3px dashed #999999"></div>
            <div style="text-align: center; font-size: 12px; margin-top: 12px">（以下空白）</div>
          </div>
          <div style="margin-top: 100px">
            <p style="height: 2px; border-top: 2px solid #666666; width: 100%"></p>
          </div>
          <div>
            <span>医师&nbsp;：</span>
            <span
              style="
                width: 100px;
                padding: 3px;
                border-bottom: 1px solid #cccccc;
                text-align: center;
              "
            >
              {{ prescriptionData.doctorName }}
            </span>
            <span style="margin-left: 39px">药品金额&nbsp;：</span>
            <span
              style="
                width: 100px;
                padding: 3px;
                border-bottom: 1px solid #cccccc;
                text-align: center;
              "
            >
              ￥{{ prescriptionData.totalFee }}
            </span>
          </div>
          <div style="white-space: nowrap">
            <span>审核药师&nbsp;：</span>
            <span
              style="
                width: 100px;
                padding: 3px;
                border-bottom: 1px solid #cccccc;
                text-align: center;
              "
            >
              {{ prescriptionData.pharmaceutist }}
            </span>
            <span style="margin-left: 20px">调配、复核药师&nbsp;：</span>
            <span
              style="
                width: 100px;
                padding: 3px;
                border-bottom: 1px solid #cccccc;
                text-align: center;
              "
            ></span>
            <span style="margin-left: 20px">核对、发药药师&nbsp;：</span>
            <span
              style="
                width: 100px;
                padding: 3px;
                border-bottom: 1px solid #cccccc;
                text-align: center;
              "
            ></span>
          </div>
          <div style="margin-top: 15px; font-size: 12px">注：处方3日内有效</div>
          <div style="margin-top: 19px">
            <p style="height: 2px; border-top: 2px solid #666666; width: 100%"></p>
          </div>
          <div
            v-show="prescriptionData.status == 2 || prescriptionData.status == 1"
            style="display: flex; justify-content: center; align-items: center; font-size: 15px"
          >
            <!-- <button
              style="
                background: red;
                border: none;
                border-radius: 10px;
                padding: 4px 10px;
                margin-right: 10px;
                color: #ffffff;
              "
              @click="examineClick('5')"
            >
              审核不通过
            </button> -->
            <button
              style="
                background: green;
                border: none;
                border-radius: 10px;
                padding: 4px 10px;
                color: #ffffff;
              "
              @click="examineClick('6')"
            >
              审核通过
            </button>
            <!-- 审核不通过 -->
            <el-dialog
              title="不通过原因"
              :visible.sync="dialogFormVisible"
              :modal="false"
              :close-on-click-modal="false"
            >
              <el-form :model="form">
                <el-form-item :label-width="formLabelWidth">
                  <el-input
                    style="width: 80%"
                    type="textarea"
                    placeholder="请输入内容"
                    v-model="form.name"
                    autocomplete="off"
                  ></el-input>
                </el-form-item>
              </el-form>
              <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="primaryClick">确 定</el-button>
              </div>
            </el-dialog>
            <!-- 审核通过 -->
            <el-dialog
              title="确认审核通过"
              :visible.sync="dialogVisibles"
              width="30%"
              :modal="false"
              :close-on-click-modal="false"
            >
              <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisibles = false">取 消</el-button>
                <el-button type="primary" @click="confirmClick">确 定</el-button>
              </span>
            </el-dialog>
          </div>
          <div
            v-show="prescriptionData.status == 6"
            style="display: flex; justify-content: center; align-items: center; font-size: 15px"
          >
            <a :href="pdfUrlChange" target="_blank">
              <button
                style="
                  background: green;
                  border: none;
                  border-radius: 10px;
                  padding: 4px 10px;
                  margin-right: 10px;
                  color: #ffffff;
                "
              >
                查看电子处方
              </button>
            </a>
          </div>
        </div>
        <!--
                                  <img style="width: 123px;position: absolute;display: block;left: 583px;bottom: 123px" src="../../../../../../lib/img/compay_official_seal.png"/>
                  -->
        <div style="text-align: center; padding-top: 20px; padding-bottom: 20px">
          <el-button
            size="small"
            type="primary"
            @click="sendPrescription"
            v-show="
              prescriptionData.prescriptionNo == '' ||
              prescriptionData.prescriptionNo == undefined ||
              prescriptionData.prescriptionNo == null
            "
          >
            发送处方
          </el-button>
        </div>
      </span>
    </el-dialog>

    <autographDialog
      @handleSign="handleSign"
      @closeDialog="closeDialog"
      v-if="autographDialogFlag"
    ></autographDialog>
  </div>
</template>

<script>
import moment from "moment";
import autographDialog from "./autographDialog.vue";
export default {
  name: "prescriptionDialog",
  data() {
    return {
      examineStatus: 0,
      formLabelWidth: "120px",
      dialogFormVisible: false,
      form: {
        name: "",
      },
      dialogVisible: true,
      dialogVisibles: false,
      year: "",
      month: "",
      day: "",
      sendPrescriptionParm: {
        prescription: {
          prescriptionSource: 1,
          askNo: "",
          userNo: "",
          userName: "",
          diagnose: "",
          doctorNo: "",
          doctorName: "",
          sex: "",
          age: "",
          idCard: "",
          deptName: "",
        },
        prescriptionDetail: [],
      },
      closeOnClickModal: false,
      sendData: {
        messageType: "",
        text: "",
        askNo: "",
        image: "",
        payload: {},
      },

      autographDialogFlag: false, //控制签名对话框展示与隐藏
      flag: false,
    };
  },
  props: ["prescriptionData"],
  props: {
    prescriptionData: {
      default: 3,
    },
    prescriptionNo: {
      type: String,
      default: "",
    },
    pdfUrl: {
      type: String,
      default: "",
    },
  },
  computed: {
    pdfUrlChange() {
      return process.env.VUE_APP_PIC_SHOW + this.pdfUrl;
    },
  },
  // watch: {
  //   pdfUrl(val, oldVal){
  //     console.log(this.val)
  //     this.pdfUrl = process.env.VUE_APP_APP_HOST + val
  //   }
  // },
  components: {
    autographDialog,
  },
  created() {
    this.examineStatus = localStorage.getItem("examineStatus");
    //console.log(this.prescriptionData,"-----")
    this.year = moment(new Date(this.prescriptionData.createTime)).format("YYYY");
    this.month = moment(new Date(this.prescriptionData.createTime)).format("MM");
    this.day = moment(new Date(this.prescriptionData.createTime)).format("DD");
    //console.log(this.year,this.month,this.day,"+++++++++")
  },
  mounted() {},
  methods: {
    sendPrescription() {
      var _this = this;
      this.sendPrescriptionParm.prescription.askNo = this.prescriptionData.askNo;
      this.sendPrescriptionParm.prescription.userNo = this.prescriptionData.userNo;
      this.sendPrescriptionParm.prescription.userName = this.prescriptionData.userName;
      this.sendPrescriptionParm.prescription.diagnose = this.prescriptionData.diagnose;
      this.sendPrescriptionParm.prescription.doctorNo = this.prescriptionData.doctorNo;
      this.sendPrescriptionParm.prescription.doctorName = this.prescriptionData.doctorName;
      this.sendPrescriptionParm.prescription.deptName = this.prescriptionData.deptName;
      this.sendPrescriptionParm.prescription.sex = this.prescriptionData.sex;
      this.sendPrescriptionParm.prescription.age = this.prescriptionData.age;
      this.sendPrescriptionParm.prescriptionDetail = this.prescriptionData.preDetail;

      this.$axios({
        method: "POST",
        url: _this.$APIURL.sendPrescription,
        data: JSON.stringify(this.sendPrescriptionParm),
        headers: { "Content-Type": "application/json" },
      })
        .then(res => {
          if (res.data.code == 0) {
            _this.sendData.messageType = 3;
            _this.sendData.askNo = this.prescriptionData.askNo;
            var drug = [];
            this.prescriptionData.preDetail.forEach(item => {
              drug.push(item.drugName + " * " + item.quantity + item.unit);
            });
            _this.sendData.payload = {
              diagnose: this.prescriptionData.diagnose,
              drug: drug,
              prescriptionNo: res.data.data,
            };
            _this.sendMsg(
              _this.prescriptionData.doctorNo,
              _this.prescriptionData.userNo,
              _this.sendData
            );
          } else {
            _this.$respone.error(res.data.message);
          }
        })
        .catch(err => {
          _this.$respone.error("发生异常");
        });
    },
    closed() {
      this.$emit("closeDialog", false);
    },
    sendMsg(From_Account, To_Account, data) {
      var _this = this;
      /*console.log("传过来的数据",data,From_Account,To_Account)
                return;*/
      var selSess = new webim.Session(
        webim.SESSION_TYPE.C2C,
        To_Account,
        To_Account,
        "",
        Math.round(new Date().getTime() / 1000)
      );
      //var msg = new webim.Msg(selSess, isSend, seq, random, msgTime, loginInfo.identifier, subType, loginInfo.identifierNick);
      var msg = new webim.Msg(selSess, true, -1, -1, -1, From_Account, 0, From_Account);
      var custom_obj = new webim.Msg.Elem.Custom(JSON.stringify(data), "1", "123");
      msg.addCustom(custom_obj);
      webim.sendMsg(
        msg,
        function (resp) {
          console.log("发送成功", resp);
          var askDetailList = _this.$store.state.askDetailList;
          var payload = JSON.stringify(data.payload);
          askDetailList.push({
            sendTime: moment().format("HH:mm"),
            fromAccount: From_Account,
            toAccount: To_Account,
            messageType: 3,
            text: "",
            payload: payload,
            image: [],
            direction: 1,
          });
          _this.dialogVisible = false;
          _this.$store.commit("setShowContentOrLogin", {
            showLoginToTc: false,
            showPersonCenter: false,
            showDrugstorePrescription: false,
            showWelcome: false,
            showLeft: true,
            showRight: true,
            showPersonInfoRight: true,
            showCenterContainer: true,
            showCenter: true,
            showPersonInfoRightContent: true,
            doctorWork: true,
          });
          _this.$store.commit("showPrescription", false);
          _this.$store.commit("setAskDetailList", askDetailList);
          console.log("发送成功后的数组", askDetailList);
        },
        function (err) {
          _this.$respone.error("处方发送失败");
        }
      );
    },
    // 处方审核
    examineClick(examineNum) {
      if (examineNum == 5) {
        this.dialogFormVisible = !this.dialogFormVisible;
      } else if (examineNum == 6) {
        // this.dialogVisibles = !this.dialogVisibles;
        if (this.flag) return;
        this.autographDialogFlag = true;
      }
    },
    // 审核不通过
    primaryClick() {
      this.dialogFormVisible = !this.dialogFormVisible;
      let datar = {
        pharmacistAutographUrl: null,
        prescriptionNo: this.prescriptionNo,
        prescriptionStatus: 5,
        remark: this.form.name,
      };
      this.$axios({
        method: "POST",
        url: this.$APIURL.DRUGINFOS_AUDITPRESCRIPTION,
        data: this.$qs.stringify(datar),
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      })
        .then(res => {
          if (res.data.code === 0) {
            this.$respone.success("操作成功，审核不通过");
            this.$emit("examineClick", false);
          } else {
            this.$respone.success(res.data.message);
          }
        })
        .catch(err => {
          _this.$respone.error("发生异常");
        });
    },
    // 确定弹框弹出签名
    confirmClick() {
      this.autographDialogFlag = true;
      this.dialogVisibles = !this.dialogVisibles;
    },
    curmClick(pharmacistAutographUrl) {
      if (this.flag) return;
      this.flag = true;
      let datar = {
        pharmacistAutographUrl: pharmacistAutographUrl,
        prescriptionNo: this.prescriptionNo,
        prescriptionStatus: 6,
        remark: this.form.name,
      };
      this.$axios({
        method: "POST",
        url: this.$APIURL.DRUGINFOS_AUDITPRESCRIPTION,
        data: this.$qs.stringify(datar),
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      })
        .then(res => {
          if (res.data.code == 0) {
            this.$respone.success("操作成功，审核通过");
            this.$emit("examineClick", false);
            this.flag = false;
          } else {
            this.$respone.error(res.data.message);
            this.flag = false;
          }
        })
        .catch(err => {
          _this.$respone.error("发生异常");
          this.flag = false;
        });
    },

    // 生成签名图片
    handleSign(img) {
      this.autographDialogFlag = false;
      this.curmClick(img);
    },
    // 关闭画布对话框
    closeDialog(flag) {
      this.autographDialogFlag = flag;
    },
  },
};
</script>

<style scoped lang="scss">
:v-deep .content1 {
  & > div {
    color: #333333;
    margin-top: 33px;
    font-size: 14px;
    display: flex;
    align-items: flex-end;
    span {
      display: inline-block;
    }
  }
}

.dialog-div-span {
  position: relative;
  width: 100%;
  div {
    margin-top: 20px;
  }
  .dialog_P {
    position: absolute;
    top: 20px;
    left: 50px;
    width: 90px;
    transform: rotate(-45deg);
  }
}

:v-deep .dialogClass {
  width: 800px;
  border-radius: 10px;
  margin-top: 3vh !important;
  .el-dialog__body {
    padding: 0;
  }
}
</style>
