<template>
  <div id="view_order_detail">
    <el-dialog
      custom-class="dialogClass"
      title="查看订单详情"
      :visible.sync="dialogVisible"
      @closed="closed"
      :modal-append-to-body="false"
      width="900px"
    >
      <div class="dialog-span" v-watermark>
        <div
          style="
            height: 40px;
            border-top: 1px solid #cccccc;
            background: #f7f7f7;
            line-height: 40px;
            padding-left: 20px;
            font-size: 16px;
          "
        >
          <span v-if="orderInfo.allInfomation.status == 10" class="have-in-handone">
            当前订单状态：已签收（{{ orderInfo.allInfomation.deliverType == 2 ? "自取" : "物流" }}）
          </span>
          <span
            v-else
            :style="{
              color:
                orderInfo.allInfomation.status == 4 || orderInfo.allInfomation.status == 5
                  ? '#e54c4d'
                  : '#eb6100',
            }"
          >
            当前订单状态：{{ orderInfo.allInfomation.statusStr }}
          </span>
        </div>
        <div style="padding: 0px 20px 20px 20px">
          <div class="essential-information">
            <h3 style="margin: 10px 0">基本信息</h3>
            <div>
              <esstialInformationTable
                :esstialInformation="orderInfo.essentialInformation"
              ></esstialInformationTable>
            </div>
          </div>
          <div class="invoice-information">
            <h3 style="margin: 10px 0">发票信息</h3>
            <div>
              <invoiceTable :invoiceInfo="orderInfo.invoiceInformation"></invoiceTable>
            </div>
          </div>
          <div class="consignee">
            <div
              style="
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin: 10px 0;
              "
            >
              <h3>收货人信息</h3>
              <div
                class="right"
                v-if="orderInfo.allInfomation.status == 4 || orderInfo.allInfomation.status == 13"
              >
                <el-switch
                  v-model="address.deliverType"
                  active-text="自取"
                  inactive-text="物流"
                  :active-value="2"
                  :inactive-value="1"
                  style="margin-right: 20px"
                  :disabled="address.deliverTypeDisabled"
                  @change="onAddressDeliverTypeChange"
                ></el-switch>
                <el-button
                  type="primary"
                  size="small"
                  @click="onAddressShow"
                  :disabled="address.deliverType == 2"
                >
                  修改地址
                </el-button>
              </div>
            </div>
            <div>
              <consigneeTable
                :consigneeInfo="orderInfo.consignee"
                :deliverType="orderInfo.allInfomation.deliverType"
              ></consigneeTable>
            </div>
          </div>
          <div style="margin: 10px 0">
            <h3 class="item-title" style="margin: 10px 0">商品信息</h3>
            <div class="item-content">
              <el-table
                :data="[
                  {
                    name: '问诊服务',
                    num: 1,
                    askServiceFee: Number(orderInfo.allInfomation.askServiceFee).toFixed(2),
                  },
                ]"
                border
                :header-cell-style="{ background: '#f5f7fa' }"
              >
                <el-table-column label="项目" prop="name" min-width="150" align="center" />
                <el-table-column label="数量" prop="num" width="120" align="center" />
                <el-table-column
                  label="单价（元）"
                  prop="askServiceFee"
                  width="120"
                  align="center"
                />
                <el-table-column
                  label="总价（元）"
                  prop="askServiceFee"
                  width="120"
                  align="center"
                />
              </el-table>
            </div>
          </div>
          <div class="operational-information">
            <div
              style="
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin: 10px 0;
              "
            >
              <h3>处方药品</h3>
              <div>
                <el-button
                  size="small"
                  type="primary"
                  @click="lookPrescription"
                  v-show="orderInfo.allInfomation.prescriptionNo"
                >
                  查看电子处方
                </el-button>
                <el-button
                  size="small"
                  type="primary"
                  @click="lookOldPrescription(orderInfo.allInfomation.prescriptionNo)"
                >
                  查看原处方
                </el-button>
                <!-- &nbsp; -->
                <!-- <a title="按住alt键点击按钮" v-show="orderInfo.allInfomation.prescriptionNo" target="_blank" :href=this.$APIURL.PIC_SHOW+orderInfo.allInfomation.pngUrl>
                            <el-button  class="el-icon-download" size="small"   >处方（png）</el-button>
                        </a>&nbsp;
                        <a title="按住alt键点击按钮" v-show="orderInfo.allInfomation.prescriptionNo"  :href=this.$APIURL.PIC_SHOW+orderInfo.allInfomation.pdfUrl>
                                <el-button class="el-icon-download" size="small" >处方（pdf）</el-button>
                        </a> -->

                <el-button
                  size="small"
                  type="primary"
                  @click="returnGoods"
                  v-show="
                    orderInfo.allInfomation.status == 5 ||
                    orderInfo.allInfomation.status == 4 ||
                    orderInfo.allInfomation.status == 14
                  "
                >
                  退货
                </el-button>
              </div>
            </div>

            <div>
              <commodityInformationTable
                :commodityInformation="orderInfo.allInfomation"
              ></commodityInformationTable>
            </div>
          </div>
          <div class="operational-information">
            <h3>操作信息</h3>
            <div>
              <operationalInformationTable
                :operationalInformation="orderInfo.allInfomation.courseList"
              ></operationalInformationTable>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
    <returnGoodsDialog
      v-if="showReturnGoodsDialog"
      :orderInfo="orderInfo.allInfomation"
      v-on:closeReturnGoodsDialog="closeReturnGoodsDialog"
    ></returnGoodsDialog>
    <prescriptionDialog
      v-if="showPrescriptionDialog"
      :prescriptionData="prescriptionData"
      v-on:closeDialog="closeDialog"
    ></prescriptionDialog>
    <prescriptionListDialog
      v-if="showPrescriptionListDialog"
      :prescriptionList="prescriptionList"
      v-on:closePrescriptionListDialog="closePrescriptionListDialog"
    ></prescriptionListDialog>
    <initPrescriptionListDialog
      v-if="showInitPrescriptionListDialog"
      :initPrescriptionList="initPrescriptionList"
      v-on:closeInitPrescriptionListDialog="closeInitPrescriptionListDialog"
    ></initPrescriptionListDialog>

    <el-dialog
      :visible.sync="address.show"
      title="修改地址"
      width="600px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="address.submit"
    >
      <div class="address-dialog">
        <el-form :model="address.form" :rules="address.rules" ref="addressFormRef">
          <!-- <el-form-item label="收货方式：" prop="deliverType" style="margin-bottom: 5px !important;">
                        <el-radio-group v-model="address.form.deliverType" @change="onAddressTypeChange">
                            <el-radio :label="1">物流</el-radio>
                            <el-radio :label="2">自取</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <template v-if="address.form.deliverType == 2">
                        <el-form-item label="收货地址：">
                            <span>用户自取</span>
                        </el-form-item>
                    </template>
                    <template v-else>

                    </template> -->
          <div class="head">
            <el-form-item label="收货人姓名：" prop="receiverName">
              <el-input v-model="address.form.receiverName" />
            </el-form-item>
            <el-form-item label="收货人电话号码：" prop="receiverPhone">
              <el-input v-model="address.form.receiverPhone" maxlength="14" />
            </el-form-item>
          </div>
          <div class="foot">
            <el-form-item label="收货人所在区域：" prop="regionName">
              <region-select v-model="address.region" ref="regionRef" @change="onAddressChange" />
            </el-form-item>
            <el-form-item label="收货人详细地址：" prop="receiverAddress">
              <el-input v-model="address.form.receiverAddress" />
            </el-form-item>
          </div>

          <div class="btn">
            <el-button
              type="primary"
              size="medium"
              @click="onAddressSubmit"
              :loading="!address.submit"
            >
              保存
            </el-button>
            <el-button size="meidium" @click="address.show = false" :disabled="!address.submit">
              取消
            </el-button>
          </div>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import esstialInformationTable from "./table/esstialInformationTable.vue";
import consigneeTable from "./table/consigneeTable.vue";
import operationalInformationTable from "./table/operationalInformationTable.vue";
import commodityInformationTable from "./table/commodityInformationTable.vue";
import returnGoodsDialog from "./table/dialog/returnGoodsDialog.vue";
import invoiceTable from "./table/invoiceTable.vue";
import prescriptionDialog from "./table/dialog/prescriptionDialog.vue";
import prescriptionListDialog from "./table/dialog/prescriptionListDialog.vue";
import initPrescriptionListDialog from "./table/dialog/initPrescriptionListDialog.vue";
import regionSelect from "../../region/select.vue";
export default {
  name: "lookOrderDialog",
  data() {
    return {
      dialogVisible: true,
      showOverflow: true,
      showReturnGoodsDialog: false,
      showPrescriptionDialog: false,
      prescriptionData: "",
      showPrescriptionListDialog: false, //是否展示处方列表对话框
      prescriptionList: "",
      initPrescriptionList: "", //原处方列表
      showInitPrescriptionListDialog: false, //是否展示处方列表对话框
      address: {
        show: false,
        form: {
          receiverName: "",
          receiverPhone: "",
          receiverAddress: "",
          regionName: [],
          deliverType: 1,
        },
        rules: {
          receiverName: {
            required: true,
            message: "请填写收货人姓名",
          },
          receiverPhone: [
            {
              required: true,
              message: "请填写收货人电话号码",
            },
            {
              validator: this.addressPhoneValid,
              trigger: "blur",
            },
          ],
          regionName: {
            required: true,
            message: "请选择收货所在区域",
          },
          receiverAddress: {
            required: true,
            message: "请填写收货人详细地址",
          },
        },
        region: [],
        submit: true,
        deliverType: 1,
        deliverTypeDisabled: false,
      },
    };
  },
  mounted() {
    // console.log("++++")
    this.address.deliverType = this.orderInfo.allInfomation.deliverType;
  },
  methods: {
    onAddressShow() {
      let row = this.orderInfo.allInfomation;
      this.address.form.receiverName = row.receiverName;
      this.address.form.receiverPhone = row.receiverPhone;
      this.address.form.receiverAddress = row.address;
      this.address.show = true;
      this.address.form.regionName = [row.province, row.city, row.area];
      this.address.form.deliverType = row.deliverType;
      this.address.region = [];
      setTimeout(() => {
        if (this.$refs.addressFormRef) this.$refs.addressFormRef.clearValidate();
        if (this.$refs.regionRef)
          this.address.region = this.$refs.regionRef.onRegionCodeGet(this.address.form.regionName);
      }, 300);
    },
    onAddressTypeChange(val) {
      if (val == 1) {
        if (this.$refs.regionRef && !this.address.region.length)
          this.address.region = this.$refs.regionRef.onRegionCodeGet(this.address.form.regionName);
      }
    },
    onAddressChange(arr) {
      this.address.form.regionName = arr.map(item => item.name);
    },
    onAddressSubmit() {
      if (!this.address.submit) return false;
      this.$refs.addressFormRef.validate((valid, error) => {
        if (valid) {
          let send = {};
          if (this.address.form.deliverType == 2) {
            send = {
              orderNo: this.orderInfo.allInfomation.orderNo,
              deliverType: this.address.form.deliverType,
            };
          } else {
            send = {
              name: this.address.form.receiverName,
              phone: this.address.form.receiverPhone,
              address: this.address.form.receiverAddress,
              province: this.address.form.regionName[0],
              city: this.address.form.regionName[1],
              area: this.address.form.regionName[2],
              street: this.address.form.regionName[3] || "",
              id: this.orderInfo.allInfomation.addressId,
              deliverType: this.address.form.deliverType,
            };
          }
          this.address.submit = false;
          this.$axios[this.address.form.deliverType == 2 ? "post" : "put"](
            this.address.form.deliverType == 2
              ? "/order-service/drugmg/order/changeOrderDeliverType"
              : "/user-service/doctormg/askinfo/modifyAddress/" +
                  this.orderInfo.allInfomation.askNo,
            send,
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          )
            .then(({ data }) => {
              if (data.code == 0) {
                this.$message.success("修改成功");
                setTimeout(() => {
                  this.address.submit = true;
                  this.address.show = false;
                  this.$emit("init", this.orderInfo.allInfomation);
                }, 1000);
              } else {
                this.$message.error(data.message);
                this.address.submit = true;
              }
            })
            .catch(() => {
              this.address.submit = true;
            });
        } else {
          this.$message.error(Object.values(error)[0][0].message);
        }
      });
    },
    onAddressDeliverTypeChange(val) {
      let send = {
        orderNo: this.orderInfo.allInfomation.orderNo,
        deliverType: this.address.deliverType,
      };
      this.address.deliverTypeDisabled = true;
      this.$axios
        .post("/order-service/drugmg/order/changeOrderDeliverType", send, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(({ data }) => {
          if (data.code == 0) {
            this.$message.success("修改成功");
            setTimeout(() => {
              this.address.submit = true;
              this.address.show = false;
              this.$emit("init", this.orderInfo.allInfomation);
            }, 1000);
            setTimeout(() => {
              this.address.deliverTypeDisabled = false;
            }, 2000);
          } else {
            setTimeout(() => {
              this.address.deliverTypeDisabled = false;
            }, 2000);
            this.address.deliverType = val == 1 ? 2 : 1;
            this.$message.error(data.message);
            this.address.submit = true;
          }
        })
        .catch(err => {
          setTimeout(() => {
            this.address.deliverTypeDisabled = false;
          }, 2000);
          this.$message.error(err.message);
          this.address.deliverType = val == 1 ? 2 : 1;
          this.address.submit = true;
        });
    },
    addressPhoneValid(rule, value, callback) {
      if (value) {
        if (value.charAt() == 1) {
          if (/^1\d{10}$/.test(value)) {
            callback();
          } else {
            callback(new Error("手机号码格式不正确"));
          }
        } else if (
          [
            /^(\(\d{3,4}\)|\d{3,4}-|\s)?\d{7,14}$/,
            /^1\d{10}$/,
            /^([69]\d{7})$/,
            /^6([68]\d{5})$/,
            /^(09\d{8})$/,
          ].some(item => item.test(value))
        ) {
          callback();
        } else {
          callback(new Error("电话号码格式不正确"));
        }
      } else {
        callback();
      }
    },
    closed() {
      this.$emit("closeLookOrderDialog", false);
    },
    closeReturnGoodsDialog(data, data1) {
      var _this = this;
      this.showReturnGoodsDialog = data;
      if (data1) {
        console.log("reflush123");
        _this.$emit("reflushList");
      }
    },
    closeDialog(data) {
      this.showPrescriptionDialog = data;
    },
    returnGoods() {
      this.showReturnGoodsDialog = true;
    },
    lookPrescription() {
      this.$axios
        .get(this.$APIURL.getDoctorPrescriptionByOrderNo, {
          params: { orderNo: this.orderInfo.allInfomation.orderNo, pageNo: 1, pageSize: 50 },
        })
        .then(res => {
          if (res.data.code == 0) {
            if (
              res.data.data &&
              res.data.data.list instanceof Array &&
              res.data.data.list.length > 0
            ) {
              this.prescriptionList = res.data.data.list;
              this.showPrescriptionListDialog = true;
            } else {
              this.$message.warning("没有处方哦");
            }
          } else {
            this.$message.error(res.data.message);
          }
        })
        .catch(err => {
          console.log(err);
        });
      // this.$axios.get(this.$APIURL.PRESCRIPTION_DETAILBYNO,{params:{prescriptionNo:this.orderInfo.allInfomation.prescriptionNo}}).then(res => {
      //     if(res.data.code==0){
      //         this.prescriptionData=res.data.data
      //         this.showPrescriptionDialog=true
      //     }else{
      //         this.$respone.error("查看失败")
      //     }
      // }).catch(err => {
      //     this.$respone.error(this.$respone.catchMsg)
      // })
    },
    // 关闭电子处方列表对话框
    closePrescriptionListDialog(data) {
      this.showPrescriptionListDialog = data;
    },
    // 查看原处方
    lookOldPrescription(prescriptionNo) {
      // let img = process.env.VUE_APP_PIC_SHOW + initPrescriptionImg
      // this.$viewerApi({
      //     images: [img],
      // })
      let data = this.$qs.stringify({
        pageNum: 1,
        pageSize: 50,
        prescriptionNo: prescriptionNo,
      });
      this.$axios
        .post(this.$APIURL.INITO_PRESCRIPTIONS, data)
        .then(res => {
          if (res.data.code == 0) {
            if (
              res.data.data &&
              res.data.data.list instanceof Array &&
              res.data.data.list.length > 0
            ) {
              this.initPrescriptionList = res.data.data.list;
              this.showInitPrescriptionListDialog = true;
            } else {
              this.$message.warning("没有处方哦");
            }
          } else {
            this.$message.error(res.data.message);
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    // 关闭电子处方列表对话框
    closeInitPrescriptionListDialog(data) {
      this.showInitPrescriptionListDialog = data;
    },
  },
  prop: ["orderInfo"],
  props: {
    orderInfo: {
      default: 3,
    },
  },
  components: {
    esstialInformationTable,
    consigneeTable,
    operationalInformationTable,
    commodityInformationTable,
    returnGoodsDialog,
    invoiceTable,
    prescriptionDialog,
    prescriptionListDialog,
    initPrescriptionListDialog,
    regionSelect,
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/css/base";

.dialog-span {
  & > div:nth-child(1) {
    margin: 0;
  }

  & > div {
    margin-top: 0.2rem;

    & > div {
      margin-top: 0.1rem;
    }
  }
}

:v-deep .dialogClass {
  margin-top: 3vh !important;
  width: 800px;
  min-width: 800px;
  // .dialog-span{

  // }
  .el-dialog__body {
    padding: 10px 0px;
  }
}

.el-dialog__wrapper {
  top: 0;
}

.address-dialog {
  .head,
  .foot {
    @include flex();

    .el-form-item {
      width: 50%;
      margin-left: 15px;

      &:first-child {
        margin-left: 0;
      }
    }
  }

  .btn {
    text-align: center;
  }
}
</style>
