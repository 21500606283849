<template>
  <div class="container">
    <div class="login-box">
      <div class="login-left">
        <div></div>
        <div>药房端</div>
      </div>
      <div class="login-right">
        <div>
          <div class="title">登录</div>
          <el-form :model="loginParam" :rules="loginRules" ref="loginForm" class="demo-ruleForm">
            <el-form-item label="" prop="username" class="login-input">
              <el-input placeholder="登录名" v-model="loginParam.username">
                <i slot="prefix" class="el-input__icon el-icon-s-custom"></i>
              </el-input>
            </el-form-item>
            <el-form-item label="" prop="password" class="login-input">
              <el-input placeholder="密码" v-model="loginParam.password" show-password>
                <i slot="prefix" class="el-input__icon el-icon-lock"></i>
              </el-input>
            </el-form-item>
            <el-form-item label="" prop="verifyCode" class="login-input">
              <el-input style="width: 50%" placeholder="验证码" v-model="loginParam.verifyCode">
                <i slot="prefix" class="el-input__icon el-icon-key"></i>
              </el-input>
              <a href="#" @click="reloadImg">
                <span>
                  <img
                    ref="codeImg"
                    :src="srcImg"
                    style="
                      display: inline-block;
                      margin-left: 10px;
                      margin-top: 3px;
                      vertical-align: top;
                    "
                  />
                </span>
              </a>
            </el-form-item>
          </el-form>
          <!--<div class="login-input remerber-me">
                        <el-checkbox v-model="rememberMe">记住我</el-checkbox>
                    </div>-->
          <div class="login-input">
            <a href="#" class="login-btn" @click="login" @keyup.enter="login">登录</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
let Base64 = require("js-base64").Base64;
export default {
  name: "login",
  data() {
    return {
      srcImg: "",
      loginParam: {
        username: "",
        password: "",
        verifyCode: "",
        verifyKey: "",
        /*rememberMe:false*/
      },
      loginRules: {
        username: [{ required: true, message: "登录名不能为空", trigger: "blur" }],
        password: [{ required: true, message: "密码不能为空", trigger: "blur" }],
        verifyCode: [
          { required: true, message: "验证码不能为空", trigger: "blur" },
          { min: 4, max: 4, message: "请输入4位验证码", trigger: "change" },
        ],
      },
    };
  },
  methods: {
    login() {
      var _this = this;
      this.$refs["loginForm"].validate(valid => {
        if (valid) {
          let param = Object.assign({}, _this.loginParam);
          param.password = Base64.encode(Base64.encode(param.password));
          _this
            .$axios({
              method: "post",
              url: _this.$APIURL.LOGIN,
              params: param,
            })
            .then(res => {
              if (res.data.code == 0) {
                _this.reloadImg();
                sessionStorage.setItem("userToken", res.data.data.userSessionStr);
                _this.$store.commit("setUserInfo", res.data.data);
                _this.$router.push("/index/home");
                // _this.$router.push("/index/myPrescriptionIndex")
              } else {
                _this.$respone.error(res.data.message);
                _this.reloadImg();
              }
            })
            .catch(err => {
              _this.$respone.error("发生异常！");
              _this.reloadImg();
            });
        } else {
          return false;
        }
      });
    },
    reloadImg() {
      this.loginParam.verifyKey = new Date().getTime();
      this.srcImg =
        process.env.VUE_APP_APP_HOST +
        "drug-service/admin/captcha/image?d=" +
        Math.random() +
        "&key=" +
        this.loginParam.verifyKey;
    },
  },
  mounted() {
    this.reloadImg();
    this.$common.autoSize();
    var _this = this;
    document.onkeydown = function (e) {
      var key = window.event.keyCode;
      if (key == 13) {
        _this.login();
      }
    };
  },
};
</script>

<style scoped lang="scss">
.container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
    -60deg,
    rgb(26, 139, 232) 0%,
    rgb(32, 119, 226) 20%,
    rgb(37, 98, 220) 50%,
    rgb(34, 123, 233) 80%,
    rgb(30, 148, 246) 100%
  );
  overflow: hidden;
}
.login-box {
  width: 9.8rem;
  min-width: 686px;
  height: 6rem;
  min-height: 423px;
  border: 1px solid grey;
  display: flex;
  flex-direction: row;
  background: white;
  border-radius: 5px;
  .title {
    font-size: 0.48rem;
    font-weight: bold;
    margin-top: 0.92rem;
    margin-bottom: 30px;
    color: #333333;
  }
  & > div {
    border: none;
    width: 50%;
    height: 100%;
  }
  .login-left {
    background: url("../../lib/img/login_doc.png") no-repeat center;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    & > div:nth-child(1) {
      // width: 2.66rem;
      // height: 1.7rem;
      width: 2.53rem;
      height: 2.69rem;
      background: url("../../lib/img/logo.png") no-repeat center;
      background-size: cover;
      border-radius:50%;
    }
    & > div:nth-child(2) {
      font-size: 0.26rem;
      color: white;
      margin-top: 0.3rem;
    }
  }
  .login-right {
    display: flex;
    justify-content: center;
    align-items: center;
    & > div {
      width: 80%;
      height: 100%;
    }
    & > div > div {
      width: 100%;
    }
  }
  :v-deep .login-input {
    margin-top: 30px;
    input {
      height: 0.6rem !important;
      min-height: 40px;
    }
    .el-input__icon {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 18px !important;
    }
  }
  .remerber-me {
    display: flex;
    justify-content: flex-end;
  }
  .login-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 0.6rem;
    background: #2568df;
    text-align: center;
    color: white;
    border-radius: 4px;
    min-height: 40px;
    font-size: 0.24rem;
    box-shadow: 0px 10px 8px 0px rgba(37, 104, 233, 0.5);
  }
  .login-btn:hover {
    transition: all 0.5s ease;
    background: rgba(37, 104, 233, 0.8);
    box-shadow: none;
  }
  .login-btn:active {
    background: rgba(37, 104, 233, 01);
  }
}
</style>
